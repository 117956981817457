import styled from "styled-components";

export const Container = styled.div`
  padding: 1rem;
  background-color: #ffffff;
  border: 1.5px solid #1890ff;
  border-style: dashed;
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  h3 {
    margin: 0;
  }
  .icon {
    color: #1890ff;
    font-size: 1.2rem;
  }
`;
export const Body = styled.div`
  display: flex;
  margin-top: 1rem;
  img {
    width: 15rem;
    height: 10rem;
    position: relative;
    bottom: 40px;
    left: 1rem;
  }
`;
export const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  h3, h5{
      margin: 0;
  }
  .footerIcon {
    padding: 1rem;
    background-color: #f5f5f5;
    font-size: 2rem;
  }
`;

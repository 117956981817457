import styled from "styled-components";

export const Container = styled.div``;
export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  h3 {
    margin: 0;
  }
`;
export const ModalBody = styled.div`
  .heading {
    margin-bottom: 1rem;
  }
`;
export const ModalFooter = styled.div`
  text-align: left;
`;

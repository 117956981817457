import { useEffect, useState } from "react";
import {
  RemindersDueTodayItems,
} from "../../Components";
import { distributions } from "../../API/Distribution";

function PendingAck() {
  const [pendingReminders, setPendingReminders] = useState({});
  const [reminderItemsLoading, setReminderItemsLoading] = useState(false);
  async function getRemindersData() {
    setReminderItemsLoading(true);
    const res = await distributions.getPendingAck();
    if (res && !res?.statusCode) {
      setPendingReminders(res.pendingReminders);
    }
    setReminderItemsLoading(false);
  }

  useEffect(() => {
    getRemindersData();
  }, []);

  return (
    <div
      className="subContainer"
      style={{
        overflowY: "scroll",
        height: "100vh",
        paddingBottom: "5rem",
      }}
    >
      <h2>Reminders Due</h2>
      <RemindersDueTodayItems
        items={pendingReminders}
        loading={reminderItemsLoading}
      />
    </div>
  );
}

export default PendingAck;

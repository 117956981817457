import * as styles from "./ProfileDetailCardStyles";
import { Avatar, Form } from "antd";
import { useEffect, useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import { loadState } from "../../sessionstorage";

function ProfileDetailCard() {
  const [userCredentials, setUserCredentials] = useState<any>({});
  const [roles, setRoles] = useState([]);
  const { email, name, phoneNumber } = userCredentials;

  useEffect(() => {
    const data = loadState().session;
    const selectedWorkSpace = data.selectedTenant?.id;
    const roles =
      data?.userData?.refinedRoleMatrices?.find(
        (each: any) => each.tenant.id === selectedWorkSpace
      )?.roles || [];
    setRoles(roles);
    setUserCredentials(data?.userData);
  }, []);

  return (
    <styles.Container>
      <styles.Header>
        <h3>Personal Details</h3>
      </styles.Header>
      <styles.Body>
        <Form className="bodyForm">
          <styles.Left>
            <Avatar size={130} icon={<UserOutlined />} />
            <h4>{name}</h4>
          </styles.Left>
          <styles.Right>
            <Form.Item name="email" className="formItem">
              <h4>Email</h4>
              {email}
            </Form.Item>
            <Form.Item name="phoneNumber" className="formItem">
              <h4>Phone Number</h4>
              {phoneNumber}
            </Form.Item>

            <Form.Item name="role" className="formItem">
              <h4>Role</h4>
              {roles?.map((data: any) => (
                <p key={data?.id}>{data?.name}</p>
              ))}
            </Form.Item>
          </styles.Right>
        </Form>
      </styles.Body>
    </styles.Container>
  );
}

export default ProfileDetailCard;

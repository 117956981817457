export const modeTemplateOptions = [
  {
    value: "EMAIL",
    title: "Email",
  },
  {
    value: "SYSTEM",
    title: "System",
  },
  {
    value: "DOCUMENT",
    title: "Document",
  },
];

export const typeTemplateOptionsData: any = {
  EMAIL: [
    {
      value: "SITE_NOTIFICATION",
      title: "Site Notification",
    },
    {
      value: "EC/IRB_NOTIFICATION",
      title: "EC/IRB Notification",
    },
    {
      value: "PARTNER_NOTIFICATION",
      title: "Partner Notification",
    },
    {
      value: "RA_NOTIFICATION",
      title: "RA Notification",
    },
    {
      value: "SAFETY_DOCUMENT_REVIEW",
      title: "Safety Document Review",
    },
  ],
  SYSTEM: [
    {
      value: "SAFETY_DOCUMENT_NOTIFICATION_SITE",
      title: "Safety Document Notification-Site",
    },
    {
      value: "SAFETY_DOCUMENT_NOTIFICATION_PARTNER",
      title: "Safety Document Notification-Partner",
    },
    {
      value: "SAFETY_DOCUMENT_NOTIFICATION_RA",
      title: "Safety Document Notification-RA",
    },
    {
      value: "SAFETY_DOCUMENT_NOTIFICATION_EC/IRB",
      title: "Safety Document Notification-EC/IRB",
    },
  ],
  DOCUMENT: [
    {
      value: "CSR Narrative",
      title: "CSR Narrative",
    },
    {
      value: "Safety Narrative",
      title: "SAE Narrative",
    },
    {
      value: "PSUR",
      title: "Periodic Safety Update Report",
    },
    {
      value: "DSUR",
      title: "Development Safety Update Report",
    }
  ]
};

export const triggerTemplateOptions = [

];

export const mapping = [
  {
    heading: "Patient",
    mapping: [
      { title: 'Patient ID', data: '{{patient_id}}' },
      { title: 'Age', data: '{{age}}' },
      { title: 'Age Group', data: '{{age_group}}' },
      { title: 'Race', data: '{{race}}' },
      { title: 'Sex', data: '{{sex}}' }
    ],
  },
  {
    heading: "Event",
    mapping: [
      { title: 'Onset Date', data: '{{onset_date}}' },
      { title: 'Cessation Date', data: '{{cessation_date}}' },
      { title: 'Seriousness Criteria', data: '{{seriousness_criteria}}' },
      { title: 'Event Reported Term', data: '{{event_reported_term}}' },
      { title: 'Event MedDRA LLT', data: '{{event_meddra_llt}}' },
      { title: 'Event MedDRA PT', data: '{{event_meddra_pt}}' },
      { title: 'Event MedDRA SOC', data: '{{event_meddra_soc}}' },
      { title: 'Event Description', data: '{{event_description}}' },
      { title: 'Treatment', data: '{{treatment}}' },
      { title: 'Outcome', data: '{{outcome}}' },
      { title: 'Labeled', data: '{{labeled}}' },
      { title: 'Reporter Causality', data: '{{reporter_causality}}' },
      { title: 'Company Causality', data: '{{company_causality}}' }
    ]
  },
  {
    heading: "Product",
    mapping: [
      { title: 'Product Flag', data: '{{product_flag}}' },
      { title: 'Tradename', data: '{{tradename}}' },
      { title: 'Active Ingredient', data: '{{active_ingredient}}' },
      { title: 'Indication', data: '{{indication}}' },
      { title: 'Start Date', data: '{{start_date}}' },
      { title: 'Stop Date', data: '{{stop_date}}' },
      { title: 'Dosage', data: '{{dosage}}' },
      { title: 'Route of administration', data: '{{route_of_administration}}' },
      { title: 'Action Taken', data: '{{action_taken}}' },
      { title: 'WHO-DD code', data: '{{who-dd_code}}' },
      { title: 'WHO-ATC code', data: '{{who-atc_code}}' }
    ],
  },
  {
    heading: "Reporter",
    mapping: [
      { title: 'Title', data: '{{title}}' },
      { title: 'Name', data: '{{name}}' },
      { title: 'Site Name', data: '{{site_name}}' },
      { title: 'Site Number', data: '{{site_number}}' },
      { title: 'Address for Correspondence', data: '{{address_for_correspondence}}' },
      { title: 'Phone', data: '{{phone}}' },
      { title: 'Email ID', data: '{{email_id}}' },
      { title: 'Preferred Mode of Correspondence', data: '{{preferred_mode_of_correspondence}}' },
      { title: 'Backup Mode of Correspondence', data: '{{backup_mode_of_correspondence}}' },
      { title: 'Role', data: '{{role}}' },
      { title: 'Language', data: '{{language}}' }
    ],
  },
  {
    heading: "Medical History",
    mapping: [
      { title: 'Start Date', data: '{{start_date}}' },
      { title: 'Stop Date', data: '{{stop_date}}' },
      { title: 'Treatment', data: '{{treatment}}' }
    ],
  },
  {
    heading: "Concomitant Medications",
    mapping: [
      { title: 'Start Date', data: '{{start_date}}' },
      { title: 'Stop Date', data: '{{stop_date}}' },
      { title: 'Disease Term', data: '{{disease_term}}' },
      { title: 'Disease LLT', data: '{{disease_llt}}' }
    ],
  },
  {
    heading: "Case Information",
    mapping: [
      { title: 'Day 0', data: '{{day_0}}' },
      { title: 'Latest Receipt Date', data: '{{latest_receipt_date}}' },
      { title: 'Country of Occurrence', data: '{{country_of_occurrence}}' }
    ],
  },
  {
    heading: "Marketing Authorization Holder",
    mapping: [
      { title: 'Approval Type', data: '{{approval_type}}' },
      { title: 'Authorization Country', data: '{{authorization_country}}' },
      { title: 'Authorization Country', data: '{{authorization_country}}' }
    ],
  },
  {
    heading: "Study",
    mapping: [
      { title: 'Study No', data: '{{study_no}}' },
      { title: 'Study Name', data: '{{study_name}}' },
      { title: 'Site Number', data: '{{site_number}}' },
      { title: 'Center Number', data: '{{center_number}}' },
      { title: 'Study Registration Number', data: '{{study_registration_number}}' },
      { title: 'Registration Country', data: '{{registration_country}}' }
    ],
  },
  {
    heading: "Attachments",
    mapping: [
      { title: 'Document Name', data: '{{document_name}}' }
    ],
  },
  {
    heading: "Report",
    mapping: [
      { title: 'PBRER', data: '{{pbrer}}' },
      { title: 'DSUR', data: '{{dsur}}' }
    ],
  },
]

// {
//   value: "NOTIFICATION_TEMPLATE",
//   title: "Notification Template",
// },
// {
//   value: "IRBEC_NOTIFICATION_TEMPLATE",
//   title: "EC/IRB Notification Template",
// },
// {
//   value: "IRBEC_CONFIRMATION_REMINDER_TEMPLATE",
//   title: "EC/IRB Confirmation Reminder Template",
// },
// {
//   value: "ACK_REMINDER_TEMPLATE",
//   title: "Acknowledge Reminder Template",
// },
// {
//   value: "IRBEC_INFORM_NOTIFICATION_TEMPALTE",
//   title: "EC/IRB Inform Notification Template",
// },
// {
//   value: "SPONSOR_PENDING_NOTIFICATION_TEMPLATE",
//   title: "Sponsor Pending Notification Template",
// },
// {
//   value: "SPONSOR_DUEDATE_NOTIFICATION_TEMPLATE",
//   title: "Sponsor Due Date Notification Template",
// }
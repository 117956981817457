import { Tag } from "antd";
import { convertDateInReadableFormat } from "../../Helper/Methods";

export const loginAuditTableColumns = [
 
  {
    title: "Email Id",
    dataIndex: "owner",
    key: "email",
    width: 200,
    render: (data: any) => {
      return data?.email || "-";
    },
  },
  {
    title: "Name",
    dataIndex: "entityName",
    key: "entityName",
    width: 220,
    render: (data: any) => {
      return data || "-";
    },
  },
  {
    title: "Timestamp",
    dataIndex: "updatedAt",
    key: "timestamp",
    width: 220,
    render: (data: any) => {
      return convertDateInReadableFormat(data, true);
    },
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    width: 100,
    render: (data: any) => {
      const tagColor = data == 9 ? "red" : "green";
      const label = data == 9 ? "Logout" : data == 5 ? "Login" : "-";
      return (
        <>
          <Tag color={tagColor} className="percentTag">
            {label}
          </Tag>
        </>
      );
    },
  },
];

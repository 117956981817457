import { Link } from "react-router-dom";
import { auth } from "../../API/Auth";
import { convertDateInReadableFormat } from "../../Helper/Methods";

export const safetyDocumentColumns = [
  {
    title: "Safety Document ID",
    dataIndex: "safetyDatabaseReportNumber",
    key: "safetyDatabaseReportNumber",
    render: (id: string, record: any) =>
      auth.getUsersPrivileges().includes("3") ||
      auth.getUsersPrivileges().includes("4") ? (
        id || "-"
      ) : (
        <Link to={"/reports/view/" + record.id}>{id || "-"}</Link>
      ),
  },
  {
    title: "Day 0",
    dataIndex: "lrd",
    key: "lrd",
    width: 200,
    render: (lrd: string) => {
      return lrd ? convertDateInReadableFormat(lrd) : "-";
    },
  },

  {
    title: "Product",
    dataIndex: "product",
    key: "product",
    width: 220,
    render: (product: string) => {
      return product || "-";
    },
  },
  {
    title: "Study Number",
    dataIndex: "studyID",
    key: "studyID",

    width: 160,
    render: (studyID: string) => {
      return studyID || "-";
    },
  },
  {
    title: "Safety Document Type",
    dataIndex: "type",
    key: "type",
    width: 210,
    render: (type: number, row: any) => {
      if (row.type === 0 || row.type === 100) {
        return row.typeValue;
      } else {
        return `${type}-day SUSAR`;
      }
    },
  },

  {
    title: "Site Number",
    dataIndex: "sourceSiteID",
    key: "sourceSiteID",
    render: (id: string, record: any) => id || "-",
  },
  {
    title: "Site Name",
    dataIndex: "sourceSiteName",
    key: "sourceSiteName",
    render: (id: string, record: any) => id || "-",
  },
  {
    title: "USDE ID",
    dataIndex: "id",
    key: "id",
  },
];

import styled from "styled-components";

export const Container = styled.div`
background-color: #f5f5f5;
  height: 100vh;
  position: fixed;
  width: 100vw;
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export function getApiUrl(): string {

  const { hostname, protocol } = window.location;

  if (process.env.REACT_APP_API_BASE_URL) {
    return process.env.REACT_APP_API_BASE_URL;
  }

  return hostname === 'localhost' ? 'http://localhost:4000/api/' : `${protocol}//${hostname}/api/`;
}

import { auth } from "../../API/Auth";
import { saveState } from "../../sessionstorage";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";

export async function workspaceChange(id: any, sessionData: any) {
  const userData = sessionData.userData;
  const userId = userData.id;
  const chosenTenantId = id;
  const workspace = userData?.tenants?.find(
    (each: Record<string, any>) => each?.id === chosenTenantId
  );
  if (!workspace) return;

  const resp = await auth.loginWithTenant({
    user: userId,
    tenantId: chosenTenantId,
  });

  if (!resp || !resp.accessToken) {
    ReNotification({
      description: "There is no role attached to this user",
      type: "error",
    });
    return {};
  }

  await saveState({
    selectedTenantId: chosenTenantId,
    selectedTenant: workspace,
    userAccessToken: resp?.accessToken || "",
    userPermissions: resp?.userPermissions || [],
  });

  const permArr = resp.userPermissions.map((each: any) => each?.type);

  return {
    endPoint:
      permArr.includes("5") || permArr.includes("6")
        ? "/notifications"
        : "/home",
  };
}

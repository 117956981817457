import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";
import { loginAuditTableColumns } from "./Constant";
import { IUniView } from "../../unity-ui-common/Components/ReTable/Interface";
import moment from "moment";
import { loadState } from "../../sessionstorage";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import { API_URL } from "../../API/Config";
import { Uniview } from "../../API/Uniview";

function LoginTable(props: {
  loading: any;
  pageSize: number;
  page: number;
  setPage: Function;
  setPageSize: Function;
  fetchDataLoginAudit: boolean;
  setLoading: Function;
  form: any;
  allUsers: any;
  reload: boolean;
}) {
  const sessionData = loadState()?.session;

  const day0Val = props.form.getFieldValue("day0");
  const ownerVal = props.form.getFieldValue("owner");
  const UniViewConfig: IUniView = {
    uniView: "audit-logs",
    viewName: "loginAudit",
    apiUrl: API_URL,
    option: {
      orderBy: {
        key: "createdAt",
        order: "ASC",
      },
      limit: 10,
    },
    filter: {
      owner: { "=": ownerVal },
      type: { "=": "5,9" },
      startDate: { "=": moment(day0Val?.[0]._d).format("DD-MM-YYYY") },
      endDate: { "=": moment(day0Val?.[1]._d).format("DD-MM-YYYY") },
    },
  };

  const generatePDF = async () => {
    const ownerId = props.form.getFieldValue("owner");
    const email = props.allUsers?.find(
      (user: any) => user?.id === ownerId
    )?.email;
    const selectionCriteria = {
      "Start Date": moment(day0Val?.[0]._d).format("DD-MM-YYYY"),
      "End Date": moment(day0Val?.[1]._d).format("DD-MM-YYYY"),
      "User ID": email || ownerId || "--",
      Tenant: sessionData?.selectedTenant?.name || "NA",
    };
    const univiewService = new Uniview("audit-logs", "loginAudit");
    const extra = {
      selectionCriteria,
      reportName: "Login Audit Data",
    };

    try {
      const res = await univiewService.generatePdf(
        {
          owner: { "=": ownerVal },
          type: { "=": "5,9" },

          startDate: { "=": moment(day0Val?.[0]._d).format("DD-MM-YYYY") },
          endDate: { "=": moment(day0Val?.[1]._d).format("DD-MM-YYYY") },
        },
        {},
        "Login Audit Report",
        "data",
        extra,
        "table"
      );
      if (res.statusCode === 200) {
        ReNotification({
          header: "Downloaded Successfully.",
          description: "File Download Successful! ",
          type: "success",
        });
      }
    } catch (e) {
      console.log(e);
      ReNotification({
        header: "File Download Failed",
        description: "Please Try Again Later.",
        type: "error",
      });
    }
  };

  return (
    <div>
      <ReTable
        name="Login Audit"
        title="Login Audit"
        columns={loginAuditTableColumns}
        loading={props.loading?.table}
        uniView={UniViewConfig}
        preventInitialLoad={!props.fetchDataLoginAudit}
        refresh={props.reload}
        exportOption={{
          pdf: {
            onClick: generatePDF,
            loading: props.loading?.pdf,
          },
        }}
      />
    </div>
  );
}

export default LoginTable;

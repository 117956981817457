import { createAsyncThunk } from "@reduxjs/toolkit";

const GetImportAuditLogs = createAsyncThunk(
    "audit-logs/GetImportAuditLogs",
    async (payload?: any) => {
        try {
            // const data = await auditlogs.getAuditLogs(payload)
            // return data;
        } catch (err) {
            console.log("error");
        }
    }
);

export { GetImportAuditLogs };
import styled from "styled-components";
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100vh;
  overflow: scroll;
  .tab {
    background-color: #ffffff;
    padding: 0.5rem 1rem;
    margin-bottom: 4rem;
  }
`;

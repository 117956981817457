import * as XLSX from 'xlsx';
import { convertSimpleToCamelCase } from "../Methods/Methods";
let csv = require("csvtojson");

export class DataParser {
    async CSVDataToJson(csvData: any) {
        let lines = csvData.split("\n");
        let csvHeaders: string[] = [];
        lines[0].split(",").map((hd: string) => {
            csvHeaders.push(convertSimpleToCamelCase(hd))
        })
        let allCases = await csv({
            noheader: false,
            headers: csvHeaders
        }).fromString(csvData).then((csvJson: any) => {
            return csvJson
        })
        return allCases
    }

    async XLMSToJson(file: any) {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                    resolve(jsonData);
                };
                reader.onerror = (error) => {
                    reject(error);
                };
                reader.readAsArrayBuffer(file);
            } catch (error) {
                console.error('Error reading the file:', error);
                reject(error);
            }
        });
    }
}

import moment from "moment";
import { makeApiRequest } from "../Methods/Methods";

export class UniviewService {
  public uniView: string;
  public viewName: string;
  public API_URL: string;

  constructor(uniView: string, viewName: string, API_URL: string) {
    this.uniView = uniView;
    this.viewName = viewName;
    this.API_URL = API_URL;
  }

  async fetchData(filter: any, option: any, endPoint?: string, extra?: any) {
    const sessionData = JSON.parse(
      sessionStorage.getItem("appState") || "{}"
    )?.session;
    const payload = {
      filter,
      option,
      viewName: this.viewName,
      extra,
    };
    let accessToken = sessionData.userAccessToken;
    if (!accessToken) {
      accessToken = sessionStorage.getItem("userAccessToken");
    }
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const url = `${this.API_URL}${this.uniView}/${endPoint ? endPoint : "data"
   
      }`;
      
    return await makeApiRequest(url, "POST", headers, payload);
  }

  async getFilter(filter?: any, groupBy?: string[]) {
    const sessionData = JSON.parse(
      sessionStorage.getItem("appState") || "{}"
    )?.session;
    let accessToken = sessionData.userAccessToken;
    if (!accessToken) {
      accessToken = sessionStorage.getItem("userAccessToken");
    }
    const payload = {
      tableName: this.uniView,
      viewName: this.viewName,
      filter,
      groupBy,
    };
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const url = `${this.API_URL}${this.uniView}/getFilters`;
    return await makeApiRequest(url, "POST", headers, payload);
  }

  async generatePdf(filter: any, option: any, fileName: string, endPoint?: string, extra?: any, pdfType?: "block" | "table") {
    const sessionData = JSON.parse(
      sessionStorage.getItem("appState") || "{}"
    )?.session;
    let accessToken = sessionData.userAccessToken;
    if (!accessToken) {
      accessToken = sessionStorage.getItem("userAccessToken");
    }
    const payload = {
      filter,
      option,
      viewName: this.viewName,
      pdfType: pdfType ? pdfType : "block",
      extra
    };
    const url = `${this.API_URL}${this.uniView}/generatePDF/${endPoint ? endPoint : "data"
      }`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const res = fetch(url, {
      headers: headers,
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
    })
      .then((response: any) => {
        return response.blob().then((blob: any) => ({
          filename: `${fileName} ${moment().format(
            "DD/MMM/YYYY hh-mm-ss A"
          )}.pdf`,
          blob,
        }));
      })
      .then(({ filename, blob }) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        return {
          statusCode: 200,
        };
      })
      .catch((error) => {
        console.error(error);
        return {
          statusCode: 500,
        };
      });
    return res;
  }

  async generateCSV(
    data: any,
    option: any,
    filter: any,
    fileName: string,
    searchByColumn?: string,
    exportCSVSelectedColumn?: string[],
    endPoint?: string,
    extra?: any
  ) {
    const sessionData = JSON.parse(
      sessionStorage.getItem("appState") || "{}"
    )?.session;
    const payload = {
      filter,
      option,
      viewName: this.viewName,
      selectedRecord: data,
      searchByColumn: searchByColumn,
      selectedColumn: exportCSVSelectedColumn,
      extra,
    };
    const url = `${this.API_URL}${this.uniView}/generateCSV/${endPoint ? endPoint : "data"
      }`;
    let accessToken = sessionData.userAccessToken;
    if (!accessToken) {
      accessToken = sessionStorage.getItem("userAccessToken");
    }
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    const res = fetch(url, {
      headers: headers,
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
    })
      .then((response: any) => {
        return response.json();
      })
      .then((data: any) => {
        const url =
          "data:text/csv;charset=utf-8," + encodeURIComponent(data?.fileData);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${payload.viewName} Table Export - ${moment().format(
          "DD/MMM/YYYY hh-mm-ss"
        )}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        return true;
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
    return res;
  }
}

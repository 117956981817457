import * as styles from "./ViewSafetyDocumentStyles";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApplicableRules from "../../Components/ApplicableRules/ApplicableRules";
import ApplicableReporting from "../../Components/ApplicableReporting/ApplicableReporting";
import ReTab from "../../unity-ui-common/Components/ReTab";
import SafetyDocumentActivity from "../../Components/SafetyDocumentActivity/SafetyDocumentActivity";
import {
  ContactCompliance,
  SafetyDocumentDetailInformation,
  SiteCompliance,
  SafetyDocument,
} from "../../Components";
import {
  FileTextOutlined,
  ContactsOutlined,
  CheckCircleOutlined,
  CodeSandboxOutlined,
  FileOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { file } from "../../API/File";
import { report } from "../../API/Report";

function ViewSafetyDocument() {
  const { reportId } = useParams();
  
  const [reportDetails, setReportDetails] = useState<any>({});
  const [allVersions, setAllVersions] = useState([]);
  const [selectedReport, setSelectedReport] = useState<any>({});
  const [activeTabId, setActiveTabId] = useState<string>("1");
  const [flag, setflag] = useState<boolean>(false);

  const fetchReportData = async (id: string) => {
    let res = await report.findOne(id);
    if (res?.statusCode !== 500) {
      setReportDetails(res);
    }
  };

  const fetchAllVersions = async (reportDetails: any) => {
    if (reportId) {
      let queryObj: any = {
        org: reportDetails.orgID,
        serialNumber: reportDetails.serialNumber,
      };
      let res = await report.findVersions(queryObj);
      if (res) {
        res = await Promise.all(
          res.map(async (item: any) => {
            const fileUrls = await file.getSignedUrls(item.attachments);
            return {
              ...item,
              attachedFilesDetails: fileUrls,
            };
          })
        );

        setAllVersions(res);
        // this code is for changing the value of severity when its empty string
        const report: any = [];
        res.forEach((item: any) => {
          if (item.id === reportId) {
            if (!Array.isArray(item?.meta?.severity)) {
              report.push({
                ...item,
                meta: {
                  ...item?.meta,
                  severity: [],
                },
              });
            } else {
              report.push(item);
            }
          }
        });
        setSelectedReport(report[0]);
      }
    }
  };

  const TabItems = [
    {
      title: (
        <span>
          <CheckCircleOutlined />
          Site Compliance
        </span>
      ),
      key: "1",
      children: (
        <SiteCompliance
          safetyDocumentData={selectedReport}
          safetyDocumentID={reportId}
        />
      ),
    },
    {
      title: (
        <span>
          <ContactsOutlined />
          Contact Compliance
        </span>
      ),
      key: "2",
      children: (
        <ContactCompliance
          safetyDocumentData={selectedReport}
          safetyDocumentID={reportId}
        />
      ),
    },
    {
      title: (
        <span>
          <FileTextOutlined />
          Safety Document
        </span>
      ),
      key: "3",
      children: (
        <SafetyDocument
          filesData={selectedReport?.attachedFilesDetails}
          reportDetail={reportDetails}
          setFlag={setflag}
          flag={flag}
        />
      ),
    },
    {
      title: (
        <span>
          <CodeSandboxOutlined />
          Applicable Rules
        </span>
      ),
      key: "4",
      children: (
        <ApplicableRules data={reportDetails} setFlag={setflag} flag={flag} />
      ),
    },
    {
      title: (
        <span>
          <FileOutlined />
          Applicable Reporting
        </span>
      ),
      key: "5",
      children: (
        <ApplicableReporting
          reportId={reportDetails?.id}
          applicableRules={reportDetails?.matchingRules}
          flag={flag}
        />
      ),
    },
    {
      title: (
        <span>
          <ClockCircleOutlined />
          Activity
        </span>
      ),
      key: "6",
      children: (
        <SafetyDocumentActivity
          flag={activeTabId}
          safetyDocumentID={reportDetails?.id}
        />
      ),
    },
  ];

  useEffect(() => {
    reportId && fetchReportData(reportId);
  }, [flag]);

  useEffect(() => {
    if (reportDetails && Object.keys(reportDetails).length)
      fetchAllVersions(reportDetails);
  }, [reportDetails]);

  return (
    <styles.Container className="subContainer">
    <SafetyDocumentDetailInformation
      setReportDetails={setReportDetails}
      reportDetials={selectedReport}
      reportId={selectedReport?.id}
      allVersions={allVersions}
      selectedReport={selectedReport}
      setSelectedReport={setSelectedReport}
    />
    <ReTab
      activeKey={activeTabId}
      onChange={(id: string) => {
        setActiveTabId(id);
      }}
      defaultOpenTab={activeTabId}
      items={TabItems}
    />
  </styles.Container>
  );
}

export default ViewSafetyDocument;

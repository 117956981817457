import { Drawer, Switch, Form } from "antd";
import { useEffect, useState } from "react";
import { tenant } from "../../API/UserManagement/Tenant";
import ReusableForm from "../../unity-ui-common/Components/ReForm";
import ReInput from "../../unity-ui-common/Components/ReFormFields/ReInput";
import ReSelect from "../../unity-ui-common/Components/ReFormFields/ReSelect";
import * as Styles from "./SponserCreationFormStyles";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import { ruleEngine } from "../../API/RuleEngine";
import "../Management/ManageUsers/styles.css";

export const SponserCreationForm = (props: {
  drawerType: string;
  visibility: boolean;
  setSponsorDrawerType: Function;
  setSponsorDrawerVisibility: Function;
  currEditingSponor: any;
  setCurrEditingSponor: Function;
}) => {
  const [form] = Form.useForm();
  const {
    visibility,
    setSponsorDrawerVisibility,
    setSponsorDrawerType,
    currEditingSponor,
    drawerType,
    setCurrEditingSponor,
  } = props;
  const [allCountries, setAllCountries] = useState([]);

  const onFinish = async (values: any) => {
    values["timeZone"] = "IST";
    values.status = values?.status === true ? 1 : 0;
    values["configs"] = {};
    if (drawerType === "create") {
      const res = await tenant.create(values);
      if (res.statusCode > 300) {
        ReNotification({
          description: res?.message || "Error while creating sponsor",
          type: "error",
        });
      } else {
        ReNotification({
          description: "Sponsor/MAH Created Successfully",
          type: "success",
        });
        onClose();
      }
    } else {
      let res = await tenant.update(currEditingSponor.id, values);
      res = await res?.json();
      if (res?.statusCode > 300) {
        ReNotification({
          description: res?.message || "Error while updating sponsor",
          type: "error",
        });
        return;
      }

      ReNotification({
        description: "Sponsor Updated Successfully",
        type: "success",
      });
      onClose();
    }
  };

  const onClose = () => {
    setSponsorDrawerVisibility(false);
    setSponsorDrawerType("create");
    setCurrEditingSponor({});
  };

  const getAllCountries = async () => {
    const res = await ruleEngine.getCountries();
    setAllCountries(
      res.map((data: any) => ({
        name: data.name,
        value: data.name,
      }))
    );
  };

  useEffect(() => {
    form.setFieldsValue({
      name: currEditingSponor?.name
        ? currEditingSponor?.name === "-"
          ? ""
          : currEditingSponor?.name
        : "",
      email: currEditingSponor?.email
        ? currEditingSponor?.email === "-"
          ? ""
          : currEditingSponor?.email
        : "",
      phoneNumber: currEditingSponor?.phoneNumber
        ? currEditingSponor?.phoneNumber === "-"
          ? ""
          : currEditingSponor?.phoneNumber
        : "",
      abbreviation: currEditingSponor?.abbreviation
        ? currEditingSponor?.abbreviation === "-"
          ? ""
          : currEditingSponor?.abbreviation
        : "",
      timeZone: "IST",
      status: currEditingSponor?.status === 1 ? true : false,
      address1: currEditingSponor?.address1
        ? currEditingSponor?.address1 === "-"
          ? ""
          : currEditingSponor?.address1
        : "",
      address2: currEditingSponor?.address2
        ? currEditingSponor?.address2 === "-"
          ? ""
          : currEditingSponor?.address2
        : "",
      city: currEditingSponor?.city
        ? currEditingSponor?.city === "-"
          ? ""
          : currEditingSponor?.city
        : "",
      state: currEditingSponor?.state
        ? currEditingSponor?.state === "-"
          ? ""
          : currEditingSponor?.state
        : "",
      zipCode: currEditingSponor?.zipCode
        ? currEditingSponor?.zipCode == "-"
          ? ""
          : currEditingSponor?.zipCode
        : "",
      country: currEditingSponor?.country
        ? currEditingSponor?.country === "-"
          ? ""
          : currEditingSponor?.country
        : "",
    });
  }, [visibility]);

  useEffect(() => {
    getAllCountries();
  }, []);

  return (
    <Styles.Container>
      <Drawer
        placement="right"
        onClose={onClose}
        open={visibility}
        width={"750"}
        title={drawerType === "edit" ? "Edit" : "Create"}
      >
        <ReusableForm
          formInstance={form}
          onSubmit={onFinish}
          formStyles={{}}
          fieldsStyles={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            columnGap: "1rem",
          }}
          submitBtn
          className="form"
          submitBtnText={drawerType === "edit" ? "Update" : "Create"}
        >
          <ReInput
            label="Sponsor/MAH Name"
            name="name"
            required
            type="simple"
          />
          <ReInput label="Email ID" name="email" type="simple" />
          <ReInput
            label="Sponsor/MAH Unit"
            name="abbreviation"
            required
            type="simple"
          />
          <ReInput label="Phone Number" name="phoneNumber" type="simple" />
          <ReSelect
            label="Country"
            name="country"
            required
            items={allCountries?.map((cou: any) => {
              return {
                value: cou.name,
                title: cou.name,
              };
            })}
            searchable
          />
          <ReInput label="Address 1" name="address1" type="simple" />
          <ReInput label="Address 2" name="address2" type="simple" />
          <ReInput label="City" name="city" type="simple" />
          <ReInput label="State" name="state" type="simple" />
          <ReInput label="Zip Code" name="zipCode" type="number" />
          <Form.Item name="status" label="Status" valuePropName="checked">
            <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
          </Form.Item>
        </ReusableForm>
      </Drawer>
    </Styles.Container>
  );
};

import { Link } from "react-router-dom";
import * as styles from "./ApplicableRulesStyles";
import { useState } from "react";
import { truncate } from "../../Helper/Methods";
import RulebuilderQueryDes from "../RulebuilderQueryDes/RulebuilderQueryDes";
import { PlusOutlined } from "@ant-design/icons";
import RuleBuilderForm from "../RuleBuilderForm/RuleBuilderForm";
import { ruleEngine } from "../../API/RuleEngine";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import ReModal from "../../unity-ui-common/Components/ReModal";
import ReDrawer from "../../unity-ui-common/Components/ReDrawer";
import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";
import { auth } from "../../API/Auth";
import { IComponentProps } from "../../Interfaces/Component/ApplicableRules.interface";

function ApplicableRules(props: IComponentProps) {
  const { data, setFlag, flag } = props;

  const [desModalVisibility, setDesModalVisibility] = useState<boolean>(false);
  const [selectedRule, setSelectedRule] = useState<any>({});
  const [drawerVisibility, setDrawerVisibility] = useState<any>(false);

  const columns = [
    {
      title: "Rule Title",
      dataIndex: "name",
      width: 250,
      render: (name: string, record: any) => {
        return auth.isPermissionExists(["11"]) ? (
          <Link to={`/rule-builder/${record.id}?type=view`}>{name}</Link>
        ) : (
          name
        );
      },
    },
    {
      title: "Rule Description",
      dataIndex: "contactRuleDesc",
      render: (contactRuleDesc: string, record: any) => {
        const text = truncate(contactRuleDesc, 160);
        return (
          <Link
            to=""
            onClick={() => {
              setDesModalVisibility(true);
              setSelectedRule(record);
            }}
          >
            {text}
          </Link>
        );
      },
    },
  ];

  const handleFormSubmit = async (values: any) => {
    let payload: any = {
      contactRuleDesc: values.contactCriteriaDes,
      contactRule: values.contactCriteriaQuery,
      name: values.name,
      dueDays: values.dueDays,
      reportId: data.id,
    };

    const res = await ruleEngine.createContactRule(payload);
    if (res?.status) {
      ReNotification({
        description: res?.message,
        duration: 1000,
        placement: "bottomLeft",
        type: "success",
      });
      setDrawerVisibility(false);
      setFlag(!flag);
    } else {
      ReNotification({
        description: res?.message,
        duration: 1000,
        placement: "bottomLeft",
        type: "error",
      });
    }
  };

  return (
    <styles.Container>
      <ReModal
        title="Description"
        visibility={desModalVisibility}
        onOkay={() => {}}
        onCancel={() => {
          setDesModalVisibility(false);
        }}
        footer={false}
      >
        <RulebuilderQueryDes ruleData={selectedRule} />
      </ReModal>
      <ReDrawer
        visibility={drawerVisibility}
        width={800}
        closable
        onCancel={() => {
          setDrawerVisibility(false);
        }}
      >
        <RuleBuilderForm type="contactBuilder" onSubmit={handleFormSubmit} />
      </ReDrawer>
      <ReTable
        name="Applicable Rules"
        columns={columns}
        data={data?.matchingRules}
        headerButtons={[
          {
            title: "Contact Rule",
            icon: <PlusOutlined />,
            disabled: data?.matchingRules?.length,
            onClick: () => {
              setDrawerVisibility(!drawerVisibility);
            },
            visibility: auth.isPermissionExists(["0"]),
          },
        ]}
      />
    </styles.Container>
  );
}

export default ApplicableRules;

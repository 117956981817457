import { useEffect, useState } from "react";
import * as styles from "./HomeStyles";
import { ReportIndicator } from "../../Components";
import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";
import { IUniView } from "../../unity-ui-common/Components/ReTable/Interface";
import { columns } from "./Constants";
import { API_URL } from "../../API/Config";
import { dashboard } from "../../API/Dashboard";

function Home() {
  const [studyCount, setstudyCount] = useState(0);
  const [prodcount, setProdcount] = useState(0);
  const [sitescount, setSitesCount] = useState(0);
  const [otherCount, setOtherCount] = useState(0);
  const [countriescount, setCountriesCount] = useState(0);
  const [pendingAcksCount, SetPendingAcksCount] = useState<number>(0);
  const [tilesLoading, setTilesLoading] = useState<boolean>(false);
  const [sevenDaysSusar, setSevenDaysSusar] = useState<number>();
  const [fifteenDaysSusar, setFifteenDaysSusar] = useState<number>();

  const fetchStatsData = async () => {
    setTilesLoading(true);
    const dashboardDataResponse = await dashboard.getCROStats();
    if (!dashboardDataResponse) {
      setTilesLoading(false);

      return;
    }
    setSevenDaysSusar(dashboardDataResponse.sevenDaySusarCount);
    setFifteenDaysSusar(dashboardDataResponse.fifteenDaySusarCount);
    setstudyCount(dashboardDataResponse.studycount);
    setProdcount(dashboardDataResponse.prodcount);
    setSitesCount(dashboardDataResponse.sitescount);

    setOtherCount(dashboardDataResponse.otherCount || 0);
    setCountriesCount(dashboardDataResponse.countriescount);
    SetPendingAcksCount(dashboardDataResponse.pendingAcksCount);
    setTilesLoading(false);
  };
  useEffect(() => {
    fetchStatsData();
  }, []);

  const reportIdicatorSmallCardItems = [
    {
      title: "7-day SUSAR",
      count: sevenDaysSusar,
      clickable: true,
      url: "/search?susar=7",
    },
    {
      title: "15-day SUSAR",
      count: fifteenDaysSusar,
      clickable: true,
      url: "/search?susar=15",
    },
    {
      title: "Reminders Due",
      count: pendingAcksCount,
      clickable: true,
      url: "/reminders-due",
    },
    {
      title: "Other Safety Documents",
      count: otherCount,
      clickable: true,
      url: "/search?susar=other",
    },
  ];

  const reportIdicatorBigCardItems = [
    {
      title: "Products",
      count: prodcount,
    },
    {
      title: "Studies",
      count: studyCount,
    },
    {
      title: "Number Of Sites",
      count: sitescount,
    },
    {
      title: "Number Of Countries",
      count: countriescount,
    },
  ];
  const uniViewConfig: IUniView = {
    uniView: "dashboard",
    viewName: "reportsData",
    apiUrl: API_URL,
    option: {
      orderBy: {
        key: "lrd",
        order: "DESC",
      },
      limit: 10,
    },
    filter: {},
  };

  return (
    <styles.Container className="page-content">
      <div className="top-container">
        <styles.ReportIdicatorSmallCardItems>
          {reportIdicatorSmallCardItems.map((item) => {
            return (
              item && <ReportIndicator item={item} loading={tilesLoading} />
            );
          })}
        </styles.ReportIdicatorSmallCardItems>
        <styles.ReportIdicatorBigCardItems>
          {reportIdicatorBigCardItems.map((item) => {
            return (
              item && (
                <div>
                  <h1>{item.count}</h1>
                  <h4>{item.title}</h4>
                </div>
              )
            );
          })}
        </styles.ReportIdicatorBigCardItems>
      </div>
     
      <ReTable
        name="Dashboard"
        scroll={{
          x: 700,
          y: 800,
        }}
        columnOptions={{
          sorting: {
            columns: [
              "safetyDatabaseReportNumber",
              "product",
              "studyID",
              "type",
              "sitesToBeNotified",
              "id",
            ],
            sortingDirections: ["ascend", "descend"],
          },
          filter: {
            columns: ["safetyDatabaseReportNumber","study_number", "product", "studyID", "type", "id","usde-id","sitesNotifiedToIRB"],
          },
        }}
        columns={columns}
        uniView={uniViewConfig}
      />
    </styles.Container>
  );
}
export default Home;

import { Link } from "react-router-dom";
import {
  convertDateInReadableFormat,
  getPercentTagColor,
  truncate,
} from "../../Helper/Methods";
import { Tag } from "antd";
import moment from "moment";

export const segregatedSitesReportColumns = [
  {
    title: "Site Number",
    dataIndex: "siteNumber",
    key: "siteNumber",
  },
  {
    title: "Safety Document ID",
    dataIndex: "safetyDatabaseReportNumber",
    key: "safetyDatabaseReportNumber",
    render: (safetyDatabaseReportNumber: string, data: any) => {
      return (
        <Link to={`/reports/view/${data?.reportID}`}>
          {safetyDatabaseReportNumber}
        </Link>
      );
    },
  },

  {
    title: "Site Name",
    dataIndex: "siteName",
    key: "siteName",
    render: (siteName: any) => {
      siteName = !siteName ? "-" : siteName;
      return siteName?.length === 60 ? truncate(siteName, 60) : siteName;
    },
  },
  {
    title: "Product",
    dataIndex: "product",
    key: "product",
  },
  {
    title: "Study Number",
    dataIndex: "studyNumber",
    key: "studyNumber",
  },
  {
    title: "Safety Document Type",
    dataIndex: "type",
    key: "type",
    render: (type: any, record: any) => {
      return type === 0 ? record.typeValue : `${type}-day SUSAR`
    },
  },
  {
    title: "Day 0",
    dataIndex: "lrd",
    key: "lrd",
    render: (lrd: string) => {
      return lrd ? moment(lrd).format("DD-MMM-YYYY") : "-";
    },
  },
  {
    title: "Date of Notification (CD)",
    dataIndex: "sentOn",
    key: "sentOn",
    render: (sentOn: any, data: any) => {
      return (
        <div>
          {sentOn?.length > 0
            ? sentOn?.map((ele: any) => {
                const { date, calendarDays } = ele;
                return (
                  <p>{`${date ? convertDateInReadableFormat(date) : ""} ${
                    calendarDays ? `(${calendarDays})` : "-"
                  }`}</p>
                );
              })
            : "-"}
        </div>
      );
    },
  },
  {
    title: "Date of Acknowledgement (CD)",
    dataIndex: "acknowledgedOn",
    key: "acknowledgedOn",
    render: (acknowledgedOn: any) => {
      return (
        <div>
          {acknowledgedOn?.length > 0
            ? acknowledgedOn?.map((ele: any) => {
                const { date, calendarDays } = ele;
                return (
                  <p>{`${date ? convertDateInReadableFormat(date) : ""} ${
                    calendarDays ? `(${calendarDays})` : "-"
                  }`}</p>
                );
              })
            : "-"}
        </div>
      );
    },
  },
  {
    title: "Date of EC/IRB Notification (CD)",
    dataIndex: "irbNotifiedOn",
    key: "irbNotifiedOn",
    render: (irbNotifiedOn: any) => {
      return (
        <div>
          {irbNotifiedOn?.length > 0
            ? irbNotifiedOn?.map((ele: any) => {
                const { date, calendarDays } = ele;
                return (
                  <p>{`${date ? convertDateInReadableFormat(date) : ""} ${
                    calendarDays ? `(${calendarDays})` : "-"
                  }`}</p>
                );
              })
            : "-"}
        </div>
      );
    },
  },
  {
    title: "USDE ID",
    dataIndex: "reportID",
    key: "reportID",
  },
];

export const aggregatedSitesReportColumns = [
  {
    title: "Site Number",
    dataIndex: "siteNumber",
    key: "siteNumber",
    width: 200,
    render: (data: string) => {
      return data || "-";
    },
  },
  {
    title: "Site Name",
    dataIndex: "siteName",
    key: "siteName",
    width: 200,
    render: (data: string) => {
      return data || "-";
    },
  },
  {
    title: "Product",
    dataIndex: "product",
    key: "product",
    width: 200,
    render: (data: string) => {
      return data || "-";
    },
  },
  {
    title: "Contact Country",
    dataIndex: "country",
    key: "country",
    width: 200,
    render: (data: string) => {
      return data || "-";
    },
  },
  {
    title: "Number of Safety Documents Shared",
    dataIndex: "totalDocShared",
    key: "totalDocShared",
    width: 280,
    render: (data: string) => {
      return data || "-";
    },
  },
  {
    title: "Site Notification Compliance",
    dataIndex: "siteNotificationCompliance",
    key: "siteNotificationCompliance",
    width: 280,
    render: (data: any) => {
      const tagColor = getPercentTagColor(data?.value);
      const percentVal = `${parseFloat(data?.value).toFixed(0)}%`;
      const value = `${String(data?.data)}/${String(data?.total)}`;
      return (
        <>
          <Tag color={tagColor} className="percentTag">
            {percentVal}
          </Tag>
          <span>{value}</span>
        </>
      );
    },
  },
  {
    title: "Site Acknowledgement Compliance",
    dataIndex: "siteAcknowledgementCompliance",
    key: "siteAcknowledgementCompliance",
    width: 280,
    render: (data: any) => {
      const tagColor = getPercentTagColor(data?.value);
      const percentVal = `${parseFloat(data?.value).toFixed(0)}%`;
      const value = `${String(data?.data)}/${String(data?.total)}`;
      return (
        <>
          <Tag color={tagColor} className="percentTag">
            {percentVal}
          </Tag>
          <span>{value}</span>
        </>
      );
    },
  },
  {
    title: "EC/IRB Notification Compliance",
    dataIndex: "ECIRBNotificationCompliance",
    key: "ECIRBNotificationCompliance",
    width: 280,
    render: (data: any) => {
      const tagColor = getPercentTagColor(data?.value);
      const percentVal = `${parseFloat(data?.value).toFixed(0)}%`;
      const value = `${String(data?.data)}/${String(data?.total)}`;
      return (
        <>
          <Tag color={tagColor} className="percentTag">
            {percentVal}
          </Tag>
          <span>{value}</span>
        </>
      );
    },
  },
];

import * as Styles from "./SafetyDocumentStyles";
import { useState } from "react";
import { ModalHeader } from "../SendNotificationModal/SendNotificationModalStyles";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import ReModal from "../../unity-ui-common/Components/ReModal";
import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";
import {
  Button,
  Popconfirm,
  Space,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import {
  FilePdfTwoTone,
  DeleteOutlined,
  FileAddOutlined,
  UploadOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { auth } from "../../API/Auth";
import { file } from "../../API/File";
import { report } from "../../API/Report";

function SafetyDocument(props: {
  filesData: any[];
  reportDetail: any;
  setFlag: Function;
  flag: boolean;
}) {
  const { filesData, reportDetail, setFlag, flag } = props;
  const [simpleTableLoading, setSimpleTableLoading] = useState(false);
  const [addAttachmentModal, setAddAttachmentModal] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [filesInfo, setFilesInfo] = useState({});
  const [uploading, setUploading] = useState<any>(false);
  const [isFileUploded, setIsFileUploded] = useState(false);

  async function handleDeleteAttachment(fileId: string, reportId: string) {
    setSimpleTableLoading(true);
    let res = await report.deleteAttachment(fileId, { reportId });
    if (res?.statusCode) {
      ReNotification({
        description: res?.message,
        duration: 1000,
        placement: "bottomLeft",
        type: "success",
      });
    } else {
      ReNotification({
        description: "File Deleted Successfully",
        duration: 1000,
        placement: "bottomLeft",
        type: "success",
      });
      setFlag(!flag);
    }
    setSimpleTableLoading(false);
  }

  const handleNewAttachmentUpload = async () => {
    setSimpleTableLoading(true);
    const payload = {
      attachments: [filesInfo],
    };
    let res = await report.addAttachment(reportDetail.id, payload);
    if (res?.status === 200) {
      setAddAttachmentModal(false);
      setFileList([]);
      setIsFileUploded(false);
      setAddAttachmentModal(false);
      ReNotification({
        description: "File Uploaded Successfully",
        duration: 1000,
        placement: "bottomLeft",
        type: "success",
      });
      setFlag(!flag);
    } else {
      console.log(res);
    }
    setSimpleTableLoading(false);
  };

  async function uploadFile(fileObj: any) {
    setUploading(true);

    const filenameArr = fileObj["name"].split(".");
    const file_type = filenameArr[filenameArr.length - 1];

    const res = await file.getUrl(file_type);
    const fileName = filenameArr[0];

    await file.remoteUploadFile(res.url, fileObj);
    setUploading(false);

    return {
      fileUID: fileObj.uid,
      id: res.id,
      filename: fileName,
      file_type,
    };
  }
  const uploadProps: UploadProps = {
    onRemove: (fileObj) => {
      const index = fileList.indexOf(fileObj);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFilesInfo({});
      setIsFileUploded(false);
    },
    beforeUpload: async (fileObj) => {
      setFileList([...fileList, fileObj]);
      const res = await uploadFile(fileObj);
      setFilesInfo(res);
      setIsFileUploded(true);
      return true;
    },
    fileList,
    disabled: uploading,
  };

  const safetyDocumentColumns = [
    {
      title: "File",
      dataIndex: "url",
      key: "url",
      render: (url: string, file: any) => (
        <Button
          key={file.fileRecord.id}
          type="link"
          href={url}
          icon={<FilePdfTwoTone />}
          style={{ padding: "0" }}
        >
          {file.fileRecord.filename}
        </Button>
      ),
    },
    auth.isPermissionExists(["0"])
      ? {
          title: "Action",
          key: "action",
          width: 80,
          render: (record: any) => {
            return (
              <Popconfirm
                title={
                  <p style={{ margin: "0" }}>
                    Are you sure you want to delete this file?
                  </p>
                }
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                  handleDeleteAttachment(record.fileRecord.id, reportDetail.id);
                }}
                placement="topLeft"
              >
                <DeleteOutlined className="deleteIcon" />
              </Popconfirm>
            );
          },
        }
      : {},
  ];

  return (
    <Styles.Container>
      <ReModal
        width={600}
        visibility={addAttachmentModal}
        onCancel={() => {
          setAddAttachmentModal(false);
        }}
        closable={false}
        title={
          <ModalHeader>
            <CloseOutlined
              onClick={() => {
                setAddAttachmentModal(false);
              }}
            />
            <h3>New Attachment</h3>
          </ModalHeader>
        }
        footer={null}
      >
        <Space direction="vertical" size="large">
          <Upload {...uploadProps} maxCount={1}>
            {!isFileUploded && (
              <Button icon={<UploadOutlined />}>Click to Import</Button>
            )}
          </Upload>
          <Button
            loading={simpleTableLoading}
            disabled={!isFileUploded}
            type="primary"
            onClick={async () => {
              await handleNewAttachmentUpload();
            }}
          >
            Upload
          </Button>
        </Space>
      </ReModal>
      <ReTable
      name="Safety Document"
        columns={safetyDocumentColumns}
        data={filesData || []}
        loading={simpleTableLoading}
        scroll={{
          x: 500,
          y: 800,
        }}
        headerButtons={[
          {
            title: "Add File",
            icon: <FileAddOutlined />,
            onClick: () => {
              setAddAttachmentModal(!addAttachmentModal);
            },
            visibility: auth.isPermissionExists(["0"]),
          },
        ]}
      />
    </Styles.Container>
  );
}

export default SafetyDocument;

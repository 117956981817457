import { Button, Form, Select, Space } from "antd";
import * as Styles from "./ContactsReportStyles";
import { useState, useEffect } from "react";
import { Header, SideMenu } from "../../Components";
import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";
import ReusableForm from "../../unity-ui-common/Components/ReForm";
import ReDatePicker from "../../unity-ui-common/Components/ReFormFields/ReDatePicker";
import { GiantFilter } from "../SafetyDocumentReport/SafetyDocumentReportStyles";
import { SearchOutlined } from "@ant-design/icons";
import ReTab from "../../unity-ui-common/Components/ReTab";
import ReFilter from "../../unity-ui-common/Components/ReFormFields/ReFilter";
import { ICheckbox } from "../../unity-ui-common/Components/ReFormFields/Interface";
import {
  aggregatedReportTypes,
  // getAggregatedContactReportColumns,
  segregatedContactReportColumns,
} from "./Constants";
import { contactMapping } from "../../Helper/Mappings";
import { IUniView } from "../../unity-ui-common/Components/ReTable/Interface";
import { convertDateInReadableFormat } from "../../Helper/Methods";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import { useNavigate } from "react-router-dom";
import { Uniview } from "../../API/Uniview";
import { API_URL } from "../../API/Config";

const ContactsReport = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const defaultRequiredGroupby = [
    ["product", "siteNumber", "role", "country"],
    ["product", "studyID", "country"],
  ];
  let allFilterItems: any = {
    product: [],
    siteNumber: [],
    role: [],
    studyID: [],
    country: [],
  };
  // const option = {
  //   limit: 10,
  //   orderBy: {
  //     createdAt: "ASC",
  //   },
  // };

  const [refreshReTable, setRefreshReTable] = useState<boolean>(false);
  const [selectedTableRow, setSelectedTableRow] = useState<string[]>([]);
  const [selectedRowValues, setSelectedRowValues] = useState<any[]>([]);
  const [activeTabValue, setActiveTabValue] = useState<string>("0");
  const [showFilterItems, setShowFilterItems] = useState<any>(allFilterItems);
  const [selectedFilters, setSelectedFilters] = useState<any>({});
  // const [tableData, setTableData] = useState<Array<any>>([]);
  // const [univiewFilter, setUniviewFilter] = useState({});
  // const [paginationPageSize, setPaginationPageSize] = useState<number>(0);
  const [selectedAggregatedReportType, setSelectedAggregatedReportType] =
    useState<string>("segregatedByCountry");
  const [requiredGroupBy, setRequiredGroupBy] = useState(
    defaultRequiredGroupby
  );
  const [loading, setLoading] = useState({
    product: false,
    siteNumber: false,
    role: false,
    studyID: false,
    country: false,
    table: false,
  });
  const uniViewConfig: IUniView = {
    uniView: "distributions",
    viewName: "contactReport",
    apiUrl: API_URL,
    option: {
      orderBy: {
        key: "createdAt",
        order: "ASC",
      },
      limit: 10,
    },
    filter: selectedFilters,
    extra:
      activeTabValue === "1"
        ? {
            aggregatedReportType: selectedAggregatedReportType,
          }
        : {},
  };

  // const getTableData = async (filter: any, aggreReportType?: string) => {
  //   console.log(filter);

  //   setUniviewFilter(filter);
  //   const dataFetcher = new Uniview("distributions", "contactReport");
  //   setLoading((prevValue) => ({
  //     ...prevValue,
  //     table: true,
  //   }));
  //   const tableDataRes = await dataFetcher.fetchData(
  //     filter,
  //     option,
  //     activeTabValue === "1" ? "aggregateData" : "data",
  //     activeTabValue === "1"
  //       ? {
  //           aggregatedReportType:
  //             aggreReportType || selectedAggregatedReportType,
  //         }
  //       : {}
  //   );
  //   setLoading((prevValue) => ({
  //     ...prevValue,
  //     table: false,
  //   }));
  //   if (tableDataRes?.status) {
  //     setTableData(tableDataRes.data);
  //     setPaginationPageSize(tableDataRes.count);
  //   }
  // };

  const getFitersData = async (filter?: any, currentGroupBy?: string[]) => {
    if (!filter || Object?.keys(filter)?.length === 0) {
      setLoading((prevValue) => ({
        ...prevValue,
        product: true,
        siteNumber: true,
        role: true,
        studyID: true,
        country: true,
      }));
    } else if (currentGroupBy && currentGroupBy?.length) {
      let loadingState: any = {};
      currentGroupBy?.forEach((ele: string) => {
        loadingState[ele] = true;
      });
      setLoading(loadingState);
    }
    const dataFetcher = new Uniview("distributions", "contactReport");
    const groupByElements =
      currentGroupBy || requiredGroupBy[parseInt(activeTabValue)];
    const res = await dataFetcher.getFilter(
      filter || {},
      currentGroupBy || requiredGroupBy[parseInt(activeTabValue)]
    );
    setLoading((prevValue) => ({
      ...prevValue,
      product: false,
      siteNumber: false,
      role: false,
      studyID: false,
      country: false,
    }));
    if (res?.status) {
      if (res?.data?.role && res?.data?.role?.length) {
        res.data.role = res.data.role.map((ele: any) => {
          return { label: contactMapping(ele?.label), name: ele?.name };
        });
      }
      let filterData: any = {};
      groupByElements?.forEach((filterItems) => {
        filterData[filterItems] = res?.data?.[filterItems];
      });
      setShowFilterItems((prevValue: any) => ({
        ...prevValue,
        ...res.data,
      }));
      allFilterItems = {
        ...allFilterItems,
        ...res.data,
      };
    }
  };

  const handleFilterCheckboxSelect = async (
    filterName: string,
    checkboxEle: ICheckbox
  ) => {
    let filter = selectedFilters,
      groupBy = requiredGroupBy;
    let currentGroupBy = requiredGroupBy[parseInt(activeTabValue)];
    const index = currentGroupBy.indexOf(filterName);
    if (form.getFieldValue(checkboxEle?.name)) {
      setShowFilterItems((prevValue: any) => ({
        ...prevValue,
        [filterName]: allFilterItems?.[filterName],
      }));
      if (filter.hasOwnProperty(filterName)) {
        delete filter[filterName];
      }
      index === -1 && currentGroupBy.push(filterName);
    } else {
      setShowFilterItems((prevValue: any) => ({
        ...prevValue,
        [filterName]: [checkboxEle],
      }));
      filter[filterName] = {
        "=": checkboxEle?.name,
      };
      index !== -1 && currentGroupBy.splice(index, 1);
    }
    groupBy[parseInt(activeTabValue)] = currentGroupBy;
    setSelectedFilters(filter);
    setRequiredGroupBy(groupBy);
    await getFitersData(filter, currentGroupBy);
    if (activeTabValue === "0") {
      // setUniviewFilter(filter);
      setRefreshReTable(!refreshReTable);
    } else {
      // getTableData(filter);
    }
  };

  const handleFilterTextChange = (filterName: string): any => {
    if (
      !allFilterItems?.[filterName] ||
      allFilterItems?.[filterName]?.length === 0
    )
      return;
    const lowerCaseSearchTerm = form.getFieldValue(filterName)?.toLowerCase();
    const searchBasedFilter = allFilterItems?.[filterName]?.filter(
      (ele: any) =>
        ele.label.toLowerCase().includes(lowerCaseSearchTerm) ||
        ele.name.toLowerCase().includes(lowerCaseSearchTerm)
    );
    setShowFilterItems((prevValue: any) => ({
      ...prevValue,
      [filterName]: searchBasedFilter,
    }));
  };

  const handleFilterReset = async () => {
    form.resetFields();
    // setTableData([]);
    setShowFilterItems(allFilterItems);
    setSelectedFilters({});
    setRequiredGroupBy(defaultRequiredGroupby);
    await getFitersData(
      {},
      activeTabValue === "0"
        ? defaultRequiredGroupby[0]
        : defaultRequiredGroupby[1]
    );
    // await getTableData({});
    navigate(`${window.location.pathname}`);
    setRefreshReTable(!refreshReTable);
  };

  // const handleAggregatedReportTypeChanges = async (value: string) => {
  //   form.resetFields();
  //   setSelectedAggregatedReportType(value);
  //   setSelectedFilters({});
  //   setShowFilterItems({});
  //   setSelectedRowValues([]);
  //   setSelectedTableRow([]);
  //   await getFitersData({}, ["product", "studyID", "role", "country"]);
  //   await getTableData(univiewFilter, value);
  // };

  const handleDateRangeChange = async (value: any, name: string) => {
    let filters = {};
    if (value === null) {
      let newFilters = selectedFilters;
      delete newFilters[`${name}`];
      filters = newFilters;
    } else {
      const start = new Date(value?.[0]._d);
      const end = new Date(value?.[1]._d);
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 59);
      filters = {
        ...selectedFilters,
        [name]: {
          ">": start,
          "<": end,
        },
      };
    }
    setSelectedFilters(filters);
    setRefreshReTable(!refreshReTable);
  };

  const generatePDF = async () => {
    const selectionCriteria = {
      "Day 0": selectedFilters.day0
        ? `${convertDateInReadableFormat(
            selectedFilters?.day0[">"]
          )} to ${convertDateInReadableFormat(selectedFilters?.day0["<"])}`
        : "NA",
      "Due Date": selectedFilters.dueDate
        ? `${convertDateInReadableFormat(
            selectedFilters?.dueDate[">"]
          )} to ${convertDateInReadableFormat(selectedFilters?.dueDate["<"])}`
        : "NA",
      Product: selectedFilters?.product
        ? selectedFilters?.product?.["="]
        : "NA",
      ...((activeTabValue === "0" ||
        ["segregatedByCountry", "segregatedByContactType"].includes(
          selectedAggregatedReportType
        )) && {
        "Site Number": selectedFilters?.siteNumber
          ? selectedFilters?.siteNumber["="]
          : "NA",
      }),
      ...((activeTabValue === "0" ||
        selectedAggregatedReportType !== "segregatedByCountry") && {
        "Contact Country": selectedFilters?.country
          ? selectedFilters?.country?.["="]
          : "NA",
      }),
      ...(activeTabValue === "0" && {
        "Contact Type": selectedFilters?.role
          ? selectedFilters?.role?.["="]
          : "NA",
      }),
    };
    const univiewService = new Uniview("distributions", "contactReport");
    const extra = {
      selectionCriteria,
      reportName: "Contact Segregated Data",
      selectedTableRow: selectedRowValues,
    };

    try {
      const res = await univiewService.generatePdf(
        selectedFilters,
        {},
        "Safety Document Report",
        "data",
        extra
      );

      if (res.statusCode === 200) {
        ReNotification({
          header: "Downloaded Successfully.",
          description: "File Download Successful! ",
          type: "success",
        });
        setSelectedRowValues([]);
      }
    } catch (e) {
      console.log(e);
      ReNotification({
        header: "File Download Failed",
        description: "Please Try Again Later.",
        type: "error",
      });
    }
  };

  const countryFilter = (
    <ReFilter
      reInputProps={{
        name: "country",
        label: "Contact Country",
        type: "simple",
        prefix: <SearchOutlined />,
      }}
      filterItems={showFilterItems?.country || []}
      handleCheckboxClick={handleFilterCheckboxSelect}
      onTextFieldChange={handleFilterTextChange}
      loading={loading?.country}
    />
  );
  const commonGaintFilterForm = (
    <ReusableForm
      onSubmit={() => {}}
      formInstance={form}
      resetFieldsAfterSubmit={false}
      className="form"
    >
      <div className="top-filter">
        <div className="date-container">
          <ReDatePicker
            label="Day 0"
            name="day0"
            type="rangePicker"
            dateFormat="DD-MMM-YYYY"
            width="100%"
            featureDates
            onChange={handleDateRangeChange}
          />
          <ReDatePicker
            label="Due Date"
            name="dueDate"
            type="rangePicker"
            dateFormat="DD-MMM-YYYY"
            width="100%"
            featureDates
            onChange={handleDateRangeChange}
          />
        </div>
      </div>
      <div className="filterItems">
        <ReFilter
          reInputProps={{
            name: "product",
            label: "Product",
            type: "simple",
            prefix: <SearchOutlined />,
          }}
          filterItems={showFilterItems?.product || []}
          handleCheckboxClick={handleFilterCheckboxSelect}
          onTextFieldChange={handleFilterTextChange}
          loading={loading?.product}
        />

        {activeTabValue === "0" && (
          <>
            <ReFilter
              reInputProps={{
                name: "siteNumber",
                label: "Site Number",
                type: "simple",
                prefix: <SearchOutlined />,
              }}
              filterItems={showFilterItems?.siteNumber || []}
              handleCheckboxClick={handleFilterCheckboxSelect}
              onTextFieldChange={handleFilterTextChange}
              loading={loading?.siteNumber}
            />
            {countryFilter}
            <ReFilter
              reInputProps={{
                name: "role",
                label: "Contact Type",
                type: "simple",
                prefix: <SearchOutlined />,
              }}
              filterItems={showFilterItems?.role || []}
              handleCheckboxClick={handleFilterCheckboxSelect}
              onTextFieldChange={handleFilterTextChange}
              loading={loading?.role}
            />
          </>
        )}
        {activeTabValue === "1" && (
          <>
            {["segregatedByCountry", "segregatedByContactType"].includes(
              selectedAggregatedReportType
            ) && (
              <ReFilter
                reInputProps={{
                  name: "studyID",
                  label: "Study Number",
                  type: "simple",
                  prefix: <SearchOutlined />,
                }}
                filterItems={showFilterItems?.studyID || []}
                handleCheckboxClick={handleFilterCheckboxSelect}
                onTextFieldChange={handleFilterTextChange}
                loading={loading?.studyID}
              />
            )}
            {selectedAggregatedReportType !== "segregatedByCountry" &&
              countryFilter}
          </>
        )}
      </div>
    </ReusableForm>
  );

  // useEffect(() => {
  //   handleFilterReset();
  // }, [activeTabValue]);

  useEffect(() => {
    getFitersData();
  }, []);

  return (
    <Styles.Container className="subContainer">
      <GiantFilter>
        <div className="tab-container">
          <ReTab
            activeKey={activeTabValue}
            onChange={(id: string) => {
              setActiveTabValue(id);
            }}
            tabBarExtraContent={
              <Space direction="horizontal">
                {activeTabValue === "1" && (
                  <Select
                    placeholder="Select Report Type"
                    value={selectedAggregatedReportType}
                    onChange={(value: string) =>
                      // handleAggregatedReportTypeChanges(value)
                      {}
                    }
                    options={aggregatedReportTypes}
                    style={{ width: "15rem" }}
                  />
                )}
                <Button onClick={handleFilterReset}>Reset</Button>
              </Space>
            }
            styles={{ padding: "0" }}
            items={[
              {
                title: "Segregated Report",
                key: "0",
                children: commonGaintFilterForm,
              },
              // {
              //   title: "Aggregated Report",
              //   key: "1",
              //   children: commonGaintFilterForm,
              // },
            ]}
          />
        </div>
      </GiantFilter>
      {/* <ReTable
            refresh={tableFlag}
            loading={loading?.table}
            name="Contact Compliance"
            showTableStats
            data={tableData}
            columns={
              activeTabValue === "0"
                ? segregatedContactReportColumns
                : getAggregatedContactReportColumns(
                    selectedAggregatedReportType
                  )
            }
            scroll={{
              x: activeTabValue === "0" ? 2800 : 1900,
              y: 800,
            }}
            uniView={activeTabValue === "0" ? uniViewConfig : undefined}
            exportOption={{
              csv: {
                endPoint: activeTabValue === "1" ? "aggregateData" : "data",
                selectRowtype: "allData",
                disabled: !tableData.length,
                params: uniViewConfig,
                columns:
                  activeTabValue === "1"
                    ? getAggregatedContactReportColumns(
                        selectedAggregatedReportType
                      ).map((col: any) => col.key)
                    : [],
              },
              pdf: {
                onClick: generatePDF,
                disabled: !tableData.length,
                loading: false,
              },
            }}
            rowSelection={{
              type: "checkbox",
              selectedRowKeys: selectedTableRow,
              setSelectedRowKeys: setSelectedTableRow,
              rowKey: "id",
              selectRowtype: "allData",
              selectedRowValues: selectedRowValues,
              setSelectedRowValues: setSelectedRowValues,
            }}
            pagination={{
              total: paginationPageSize,
            }}
          /> */}
      <ReTable
        refresh={refreshReTable}
        name="Contact Compliance"
        title="Contact Compliance"
        showTableStats
        columns={segregatedContactReportColumns}
        scroll={{
          x: 2800,
          y: 800,
        }}
        uniView={uniViewConfig}
        exportOption={{
          csv: {
            endPoint: "data",
            selectRowtype: "allData",
            params: uniViewConfig,
          },
          pdf: {
            onClick: generatePDF,
          },
        }}
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: selectedTableRow,
          setSelectedRowKeys: setSelectedTableRow,
          rowKey: "id",
          selectRowtype: "allData",
          selectedRowValues: selectedRowValues,
          setSelectedRowValues: setSelectedRowValues,
        }}
      />
    </Styles.Container>
  );
};

export default ContactsReport;

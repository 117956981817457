import {
  PathIndicator,
  NotificationDetail,
} from "../../Components";
import * as styles from "./NotificationStyles";
import { useState } from "react";

function NotificationsDetail() {
  const [id, setId] = useState("");
  
  
  return (
    <styles.Container>
        <PathIndicator
          items={[
            {
              title: "Notifications",
              url: "/notifications",
            },
            {
              title: id,
              noLink: true,
            },
          ]}
        />
        <NotificationDetail setId={setId} user="si" editable={false} />
      </styles.Container>
  );
}

export default NotificationsDetail;

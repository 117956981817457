export const monthInShortFormat = (dateString: string) => {
  const dateStringArray = dateString.split(' ');
  dateStringArray[0] = dateStringArray[0].substring(0, 3);
  return dateStringArray.join(' ');
}

export function groupBy(xs: Array<any>, key: string) {
  if (!xs) {
    return {}
  }

  return xs.reduce(function (rv: any, x: any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

/**************
 * subtract two date and return number of days
 * @param endDate Date
 * @param startDate Date
*/
export const subtractDate = (endDate: Date, startDate: Date) => Math.floor(Math.abs(endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24))
export function remap(value: any, input_start: any, input_end: any, output_start: any, output_end: any) {
  return output_start + ((output_end - output_start) / (input_end - input_start)) * (value - input_start)
}
import {
    SearchOutlined,
    ArrowRightOutlined,
    PlusOutlined,
    FileSearchOutlined,
    EditOutlined
  } from "@ant-design/icons";
  import { Tag } from 'antd';
  import Search from "antd/lib/input/Search";
  import { Link } from "react-router-dom";
  import * as Styles from "./DocumentManagementStyles";
  import { useEffect, useState } from "react";
  import { template } from "../../API/Template";
  import ReSelect from "../../unity-ui-common/Components/ReFormFields/ReSelect";
  
  function DocumentManagement() {
  
    const [allTemplateData, setAllTemplateData] = useState<any>([]);
    const [viewTemplateData, setViewTemplateData] = useState<any>([]);
    const [onlineTemplateData, setOnlineTemplateData] = useState<any>([]);
    const [searchTerm, setsearchTerm] = useState('');
  
    useEffect(() => {
      onPageLoad();
    }, [])
  
    async function onPageLoad() {
      const allTemplateRes = await template.getAllTemplates();
      setAllTemplateData(allTemplateRes);
      setViewTemplateData(allTemplateRes?.configuredTemplate);
      setOnlineTemplateData(allTemplateRes?.onlineTemplate);
    }

    const onSearch = (searchTerm: string) => {
      const value = searchTerm || "";
      const fieldsToSearch = ['body', 'description', 'mode', 'name', 'subject', 'trigger', 'type', 'createdAt', 'updatedAt'];
      const filterData: any = [];
      setsearchTerm(value);
      if (value === '') {
        setViewTemplateData(allTemplateData?.configuredTemplate)
        return;
      }
      allTemplateData?.configuredTemplate?.forEach((data: any) => {
        fieldsToSearch?.every((field: string) => {
          if (((data[field] || "").toLowerCase().trim()).includes(value.toLowerCase().trim())) {
            filterData.push(data);
            return false;
          }
          return true;
        });
      });
      allTemplateData?.onlineTemplate?.forEach((data: any) => {
        fieldsToSearch?.every((field: string) => {
          if (((data[field] || "").toLowerCase().trim()).includes(value.toLowerCase().trim())) {
            filterData.push(data);
            return false;
          }
          return true;
        });
      });
      setViewTemplateData(filterData);
    }
  
    const handleModeSelect = (value: any) => {
      onSearch(value);
    }
  
    const handleTriggerSelect = (value: any) => {
      onSearch(value);
    }
  
    const handleTypeSelect = (value: any) => {
      onSearch(value);
    }
  
    return (
      <Styles.Container>
        <div className="heading">
          <h2>Document Management</h2>
        </div>
        <div className="searchBar">
          <Search
            placeholder="Search text"
            allowClear
            enterButton={<SearchOutlined />}
            size="large"
            onSearch={onSearch}
          />
        </div>
        <div className="headingSection"><h2>{searchTerm?.length > 0 ? "Search" : "Configured Documents"}</h2></div>
        {viewTemplateData?.length ?
          <div className="allTemplate">
            {viewTemplateData?.map((data: any) => (
              <div className="templateContainer">
                <Link to={`/create-document/${data.id}`} key={data?.id}>
                  <div className="templateCard">
                    {data?.status === 0 && <div className="inactive"></div>}
                    <div className="nameContainer">
                      <div className="name">{data?.name}</div>
                      <Tag>{data?.mode}</Tag>
                      <Tag>{data?.type}</Tag>
                      {data?.status === 0 && <Tag color="red">Inactive</Tag>}
                    </div>
                    <div className="description">{data?.description?.length > 180 ? (data?.description).substring(0, 180).trim() + "......" : data?.description}</div>
                    <div className="edit">View Document <ArrowRightOutlined /></div>
                  </div>
                </Link>
                {/* <Link to={`/template-management/${data?.id}`} className="action"><EditOutlined /></Link> */}
              </div>
            ))}
          </div> :
          <div className="templatesNotFound">
            <FileSearchOutlined />
            <div className="text">{allTemplateData?.length > 0 && viewTemplateData?.length === 0 ? "Couldn't Find The Template" : "No Template Configured"}</div>
          </div>
        }
        <Link to={`/template-management/new-template`}>
          <div className="addNewTemplateBtn">
            <div className="icon"><PlusOutlined /></div>
            <div className="text">Add New Document</div>
          </div>
        </Link>
      </Styles.Container>
    )
  }
  
  export default DocumentManagement;
import styled from "styled-components";

export const SubContainer = styled.div`
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 95vh;
  overflow-y: scroll;
  padding-bottom: 3rem;
`;
export const FormButtons = styled.div`
  display: flex;
  gap: 1rem;
`;
export const CreateReportBtn = styled.form`
  margin-top: 1rem;
`;

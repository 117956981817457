import { Input } from "antd";
import { auth } from "../../API/Auth";

function NotiDetailedTemplate(props: {
  report?: any;
  distribution?: any;
  additionalText?: any;
  onAdditionalTextChange?: any;
  notificationTemplate?: string | null;
  showAdditionalText?: boolean;
}) {

  const { TextArea } = Input;
  const {
    type,
    typeValue,
    org,
    product,
    studyTitle,
    studyID,
    safetyDatabaseReportNumber,
    notificationTemplateHtml,
  } = props.report;
  const { name, studyNumber } = props.distribution;

  const { additionalText, showAdditionalText, onAdditionalTextChange } = props;
  const template = notificationTemplateHtml ? notificationTemplateHtml :
    props.report.notificationTemplateHtml ? props.report.notificationTemplateHtml : props.notificationTemplate;

  if (template) {

    return <>
      <div dangerouslySetInnerHTML={
        {
          __html: additionalText && !(showAdditionalText && auth.isPermissionExists(["0"])) ? 
          template.replace("<wbr></wbr>", `<p>${additionalText}</p>`) :
          template.replace("<wbr></wbr>", ``)
        }} />
      {showAdditionalText && auth.isPermissionExists(["0"]) && (
        <TextArea
          onChange={(event) => onAdditionalTextChange(event.target.value)}
          style={{ marginBottom: "1rem" }}
          showCount
          maxLength={2000}
          placeholder="Add any additional information, as required"
        />
      )}
    </>
  }
  return (
    <div>
      <p>Dear {name || "{Name}"}</p>
      <p>
        {org} is the Sponsor of the clinical study {studyID || "{Study ID}"} -{" "}
        {studyTitle || "{Study Title}"}. {org} is required to inform all
        participating Investigators in writing of any suspected, unexpected
        serious adverse reaction (SUSAR) that occurs in a study evaluating the
        investigational product. Soterius, on behalf of{" "}
        {org || "{Sponsor Name}"}, is providing you with a suspected unexpected
        serious adverse reaction (SUSAR) from study {studyID} for {product}.
      </p>

      {showAdditionalText && auth.isPermissionExists(["0"]) ? (
        <TextArea
          onChange={(event) => onAdditionalTextChange(event.target.value)}
          style={{ marginBottom: "1rem" }}
          showCount
          maxLength={2000}
          placeholder="Add any additional information, as required"
        />
      ) : (
        <p>{additionalText}</p>
      )}

      <p>
        Enclosed, please find the following documents with regards to this{" "}
        {type == "100" ? typeValue : `${type}-day SUSAR`}:{" "}
        {safetyDatabaseReportNumber}:
      </p>

      <p style={{ paddingLeft: "1.5rem" }}>
        <ol>
          <li>Investigator Notification Letter</li>
          <li>CIOMS/MedWatch Form </li>
        </ol>
      </p>

      <p>
        Please review the attached CIOMS/MedWatch form for this case to evaluate
        the risks and benefits of the study{" "}
        {studyNumber ? `(${studyNumber})` : `({Study Number})`} at your
        investigational site. Additionally, also file the documents in the
        regulatory section of your study binder and please report this
        information to your Institutional Review Board or Ethics Committee in
        accordance with applicable laws and regulations.
      </p>

      <p>
        Thank you very much and feel free to reach out to the Safety Team /
        Medical Monitor as per the Contact Information provided by {org}, should
        you have any questions.
      </p>

      <p>
        Note: Request you to kindly provide your acknowledgement of receipt of
        this communication and also provide the confirmation of the notification
        to EC/IRB.
      </p>

      <p>{additionalText || ""}</p>

      <p>Kind regards,</p>
      <p>Soterius Safety Team</p>
      <p>On behalf of {org}</p>
    </div>
  );
}

export default NotiDetailedTemplate;

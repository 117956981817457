import styled from "styled-components";

export const ModalBody = styled.div`
  display: grid;
  text-align: center;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
`;
export const Success = styled.div`
  display: flex;
  gap: 1rem;
  align-items: start;
`;
import { useParams } from "react-router-dom";
import ManageRoles from "../../Components/Management/ManageRoles/ManageRoles";
import ManageSponsors from "../../Components/Management/ManageSponsors/ManageSponsors";
import ManageUser from "../../Components/Management/ManageUsers/ManageUsers";

function UserManagement() {
  const { type } = useParams();
  return (
    <div className="subContainer">
      {type === "sponsors" && <ManageSponsors />}
      {type === "roles" && <ManageRoles />}
      {type === "users" && <ManageUser />}
    </div>
  );
}

export default UserManagement;

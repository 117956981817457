import * as Styles from "./PPAndTCModalStyles";
import ReModal from "../../unity-ui-common/Components/ReModal";
import { Iprops } from "./Constant";
import { PrivacyPolicyTemplate, TermsAndConditionsTemplate } from "../../Configs/Templates/PPAndTCTemplate";

function PPAndTCModal(props: Iprops) {
  const { visibility, setVisibility, type } = props;
  return (
    <ReModal
      visibility={visibility}
      width={1200}
      title={`${type === "TC" ? "Terms & Conditions" : "Privacy Policy"}`}
      onCancel={() => {
        setVisibility((pre: any) => {
          return {
            ...pre,
            visibility: false,
          };
        });
      }}
      footer={false}
    >
      <Styles.Body>
        {type === "PP" ? PrivacyPolicyTemplate : TermsAndConditionsTemplate}
      </Styles.Body>
    </ReModal>
  );
}

export default PPAndTCModal;

import { Button } from "antd";
import * as styles from "./SmallSupportCardStyles";

function SmallSupportCard(props: {
  Icon: JSX.Element;
  title: string;
  link?: string;
  buttonTitle: string;
  onClick?: Function;
}) {
  const { Icon, title, link, buttonTitle, onClick } = props;
  return (
    <styles.Container>
      {Icon}
      <h4>{title}</h4>
      <Button type="primary" size="small" href={link} target="_blank" onClick={(e)=>{onClick && onClick(e)}}>
        {buttonTitle}
      </Button>
    </styles.Container>
  );
}

export default SmallSupportCard;

import { useEffect, useState } from "react";
import ReTab from "../../unity-ui-common/Components/ReTab";
import "../../Helper/Methods";
import ReusableForm from "../../unity-ui-common/Components/ReForm";
import { Form } from "antd";
import ReInput from "../../unity-ui-common/Components/ReFormFields/ReInput";
import ReSelect from "../../unity-ui-common/Components/ReFormFields/ReSelect";
import ReDatePicker from "../../unity-ui-common/Components/ReFormFields/ReDatePicker";
import {
  operationTypeForConfiguration,
  operationTypeForSafetyDocument,
} from "./common";
import * as styles from "./SystemLogsStyles";
import moment from "moment";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import LoginTable from "./LoginTable";
import { useAppSelector } from "../../app/hooks";
import { Uniview } from "../../API/Uniview";

function SystemLogs() {
  const [form] = Form.useForm();
  const auditLogUniView = "audit-logs";
  const sessionData = useAppSelector((state) => state.session);

  const [fetchDataLoginAudit, setFetchDataLoginAudit] = useState(false);
  const [reloadLoginAudit, setReloadLoginAudit] = useState(false);
  const [activeTabValue, setActiveTabValue] = useState<string>("0");
  const [allUsers, setAllUsers] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState({ table: false, pdf: false });
  const fetchAllUsers = async () => {
    const univiewService = new Uniview("user", "getAll");
    const res = await univiewService.fetchData(
      {
        status: { "=": 1 },
      },
      {
        orderBy: {
          name: "ASC",
        },
      }
    );
    if (res?.status) {
      setAllUsers(res.data);
    }
  };

  // async function generateConsolidatedReport(dateRange: any) {
  //   const univiewService = new Uniview("distributions", "consolidatedReport");
  //   const start = new Date(dateRange?.[0]._d);
  //   const end = new Date(dateRange?.[1]._d);
  //   start.setHours(0, 0, 0, 0);
  //   end.setHours(23, 59, 59, 59);
  //   dateRange = {
  //     ">": start,
  //     "<": end,
  //   };
  //   await univiewService.generateCSV(
  //     {},
  //     {},
  //     { day0: dateRange },
  //     "Consolidated Report"
  //   );
  // }

  async function handleFormSubmitButtonClicks(values: any) {
    // if (activeTabValue === "3") {
    //   generateConsolidatedReport(values?.day0);
    //   return;
    // }

    let extra: any = {};
    const options = {
      orderBy: { createdAt: "DESC" },
    };
    const filter: any = {
      owner: { "=": values.owner },
      org: {
        "=": sessionData?.selectedTenant?.id,
      },
      meta: {
        ".": { auditType: { "=": activeTabValue === "1" ? 1 : 0 } },
      },
    };

    const univiewService = new Uniview(
      auditLogUniView,
      activeTabValue === "0" ? "Configuration" : "Safety Document"
    );
    ReNotification({
      description: "The Audit Trail Report May Take a Few Minutes to Generate.",
      placement: "bottomLeft",
      type: "info",
    });

    if (activeTabValue === "0") {
      // for configuration
      filter["type"] = { "=": values.type };
      moment().format("DD-MM-YYYY");
      filter["createdAt"] = { ">": values.date[0]._d, "<": values.date[1]._d };
      // data["startDate"] = moment(values.date[0]._d).format("DD-MM-YYYY");
      // data["endDate"] = moment(values.date[1]._d).format("DD-MM-YYYY");
    } else if (activeTabValue === "1") {
      // for safety document
      filter["entityID"] = { "=": values.usdeId };
      filter["ref"] = {
        ".": { day0: { ">": values.day0[0]._d, "<": values.day0[1]._d } },
      };
      // extra["startDate"] = moment(values.day0[0]._d).format("DD-MM-YYYY");
      // extra["endDate"] = moment(values.day0[1]._d).format("DD-MM-YYYY");
      if (values.SafetyDocAuditType !== null) {
        extra["SafetyDocAuditType"] = values.SafetyDocAuditType;
      }
    }

    await univiewService.generatePdf(
      filter,
      options,
      `${
        activeTabValue === "0" ? "Configuration" : "Safety Document"
      } Audit Logs`,
      "data",
      extra
    );
  }

  const commonForm = (
    <ReusableForm
      formInstance={form}
      onSubmit={
        activeTabValue === "2"
          ? () => {
              setReloadLoginAudit(false);
              const day0Val = form.getFieldValue("day0");
              if (day0Val?.[0]._d || day0Val?.[1]._d) {
                setFetchDataLoginAudit(true);
              }
            }
          : handleFormSubmitButtonClicks
      }
      onChange={
        activeTabValue === "2"
          ? () => {
              setFetchDataLoginAudit(false);
              const day0Val = form.getFieldValue("day0");
              if (!day0Val?.[0]._d || !day0Val?.[1]._d) {
                setFetchDataLoginAudit(false);
                setReloadLoginAudit(true);
              }
            }
          : undefined
      }
      className="ReForm"
      submitBtn
      resetBtn
      submitBtnText="Generate"
      fieldsStyles={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: "0.5rem",
        alignItems: "center",
      }}
    >
      {!["2", "3"].includes(activeTabValue) && (
        <ReSelect
          label="User ID"
          name="owner"
          items={allUsers?.map((cou: any) => {
            return {
              value: cou.id,
              title: cou.name,
            };
          })}
          searchable
        />
      )}
      {/* {activeTabValue === "3" && (
        <ReDatePicker
          label="Day 0"
          name="day0"
          type="rangePicker"
          dateFormat="DD-MMM-YYYY"
          width="100%"
          featureDates
          required
        />
      )} */}

      {activeTabValue === "0" && (
        <>
          <ReSelect
            label="Operation Type"
            name="type"
            items={operationTypeForConfiguration?.map((type: any) => {
              return {
                value: type.value,
                title: type.title,
              };
            })}
          />
          <ReDatePicker
            label="Date"
            name="date"
            type="rangePicker"
            dateFormat="DD-MMM-YYYY"
            width="100%"
            featureDates
            required
          />
        </>
      )}

      {activeTabValue === "1" && (
        <>
          <ReSelect
            label="Operation Type"
            name="SafetyDocAuditType"
            items={operationTypeForSafetyDocument?.map((type: any) => {
              return {
                value: type.value,
                title: type.title,
              };
            })}
          />
          <ReInput label="USDE ID" name="usdeId" type="simple" />
          <ReDatePicker
            label="Day 0"
            name="day0"
            type="rangePicker"
            dateFormat="DD-MMM-YYYY"
            width="100%"
            featureDates
            required
          />
        </>
      )}
      {activeTabValue === "2" && (
        <>
          <ReSelect
            label="User ID"
            name="owner"
            items={allUsers?.map((cou: any) => {
              return {
                value: cou.id,
                title: cou.email,
              };
            })}
            searchable
          />
          <ReDatePicker
            label="Date"
            name="day0"
            type="rangePicker"
            dateFormat="DD-MMM-YYYY"
            width="100%"
            featureDates
            required
          />
        </>
      )}
    </ReusableForm>
  );

  useEffect(() => {
    fetchAllUsers();
  }, []);
  return (
    <div className="subContainer">
      <styles.GiantFilter>
        <ReTab
          activeKey={activeTabValue}
          onChange={(id: string) => {
            form.resetFields();
            setActiveTabValue(id);
          }}
          items={[
            {
              title: "Configuration",
              key: "0",
              children: commonForm,
            },
            {
              title: "Safety Document",
              key: "1",
              children: commonForm,
            },
            {
              title: "Login Audit",
              key: "2",
              children: commonForm,
            },
            {
              title: "Consolidated Report",
              key: "3",
              children: commonForm,
            },
          ]}
        />
      </styles.GiantFilter>

      {activeTabValue === "2" && (
        <styles.SeperateContainer>
          <LoginTable
            reload={reloadLoginAudit}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            loading={loading}
            setLoading={setLoading}
            fetchDataLoginAudit={fetchDataLoginAudit}
            form={form}
            allUsers={allUsers}
          />
        </styles.SeperateContainer>
      )}
    </div>
  );
}

export default SystemLogs;

import { LoginForm } from "../../Components";
import * as styles from "./ContactUserLoginStyles";

function ContactUserLogin() {
  return (
    <styles.Container>
      <LoginForm noLogout />
    </styles.Container>
  );
}

export default ContactUserLogin;

import { RoutesDataType } from "../Interfaces/Config/Routes.interface";
import {
  ViewSafetyDocument,
  AllNotifiactions,
  Notification,
  ContactUserLogin,
  CommonLogin,
  UnAuthorized,
  Profile,
  Support,
  SitesReport,
  Search,
  RemindersDueToday,
  PageNotFound,
  RuleBuilder,
  ContactsReport,
  RuleRepository,
  SafetyDocumentReport,
  Home,
  SystemLogs,
  ConsolidatedReport,
  TemplateManagement,
  ViewTemplate,
  CreateDocument,
  DocumentManagement,
  ComingSoon,
  WorkFlowConfig
} from "../Routes";
import DocEditor from "../Routes/TemplateManagement/DocEditor";
import PrivilegesManagement from "../Routes/UserManagement/PrivilegesManagement/PrivilegesManagement";
import UserManagement from "../Routes/UserManagement/UserManagement";
import Workspace from "../Routes/Workspace/Workspace";

const routes: Array<RoutesDataType> = [
  {
    path: "*",
    element: <PageNotFound />,
  },
  {
    path: "/",
    element: <CommonLogin />,
  },
  {
    path: "/workspace",
    element: <Workspace />,
  },
  {
    path: "/create-document/:id",
    element: <ComingSoon />,
    sideMenu: true,
    header: true,
  },
  {
    path: "/coming-soon/:name",
    element: <ComingSoon />,
    sideMenu: true,
    header: true,
  },
  {
    path: "/create-document/:id",
    element: <CreateDocument />,
    sideMenu: true,
    header: true,
  },
  {
    path: "/document-management",
    element: <DocumentManagement />,
    sideMenu: true,
    header: true,
  },
  {
    path: "home",
    element: <Home />,
    sideMenu: true,
    header: true,
    privileges: ["0", "1", "2", "3"],
  },
  {
    path: "si/login/:emailID",
    element: <ContactUserLogin />,
  },

  {
    path: "si/login",
    element: <ContactUserLogin />,
  },
  {
    path: "search",
    sideMenu: true,
    header: true,
    element: <Search />,
    privileges: ["3"],
  },
  {
    path: "cumulative-raw-data-report",
    sideMenu: true,
    header: true,
    element: <ConsolidatedReport />,
    privileges: ["3"],
  },
  {
    path: "reports/view/:reportId",
    sideMenu: true,
    header: true,
    element: <ViewSafetyDocument />,
  },
  {
    path: "notifications",
    sideMenu: true,
    header: true,
    element: <AllNotifiactions />,
    privileges: ["5", "6"],
  },
  {
    path: "notification/:token",
    header: true,
    element: <Notification />,

    privileges: ["5", "6"],
  },
  {
    path: "coming-soon",
    element: <UnAuthorized msg="Coming Soon" />,
  },
  {
    path: "unauthorized",
    element: <UnAuthorized msg="UnAuthorized" />,
  },
  {
    path: "safety-document",
    element: <SafetyDocumentReport />,
    sideMenu: true,
    header: true,
    privileges: ["3"],
  },
  {
    path: "sites",
    element: <SitesReport />,
    sideMenu: true,
    header: true,
    privileges: ["3"],
  },
  {
    path: "auditlog",
    element: <SystemLogs />,
    sideMenu: true,
    header: true,
    privileges: ["7"],
  },
  {
    path: "contact-compliance",
    element: <ContactsReport />,
    sideMenu: true,
    header: true,
    privileges: ["3"],
  },
  {
    path: "profile",
    sideMenu: true,
    header: true,
    element: <Profile />,
  },
  {
    path: "support",
    sideMenu: true,
    header: true,
    element: <Support />,
  },
  {
    path: "reminders-due",
    sideMenu: true,
    header: true,
    element: <RemindersDueToday />,
  },
  {
    path: "reporting-rules",
    element: <RuleRepository />,
    sideMenu: true,
    header: true,
    privileges: ["10", "11"],
  },
  {
    path: "rule-builder",
    element: <RuleBuilder />,
    sideMenu: true,
    header: true,
    privileges: ["10", "11"],
  },
  {
    path: "rule-builder/:ruleId",
    element: <RuleBuilder />,
    sideMenu: true,
    header: true,
    privileges: ["10", "11"],
  },
  {
    path: "/user-management/:type",
    element: <UserManagement />,
    sideMenu: true,
    header: true,
    privileges: ["9"],
  },
  {
    path: "/privileges",
    element: <PrivilegesManagement />,
    sideMenu: true,
    header: true,
    privileges: ["9"],
  },
  {
    path: "/template-management",
    element: <TemplateManagement />,
    sideMenu: true,
    header: true,
    // privileges: [""],
  },
  {
    path: "/template-management/:id",
    element: <ViewTemplate />,
    sideMenu: true,
    header: true,
    // privileges: [""],
  },
  {
    path: "/template-management/editor",
    element: <DocEditor />,
    sideMenu: true,
    header: true,
    // privileges: [""],
  },
  {
    path: "/workflow-configuration",
    element: <WorkFlowConfig />,
    sideMenu: true,
    header: true,
  }
  // {
  //   path: "/pdf-report-builder",
  //   element: <PdfReportBuilder />,
  // },
];

export default routes;

import TextArea from "antd/lib/input/TextArea";
import { auth } from "../../API/Auth";

function ReminderTemplate(props: {
  templateType: string;
  additionalText: string;
  onAdditionalTextChange: any;
  report: any;
}) {
  const { additionalText, onAdditionalTextChange, report, templateType } =
    props;
  const { org,IRBECnotificationReminderTemplateHtml,notificationReminderTemplateHtml } = report;
    const template = templateType =='IRB/EC'? IRBECnotificationReminderTemplateHtml:notificationReminderTemplateHtml
   
    
    if(template){
      return <div>
    <div dangerouslySetInnerHTML={{__html: template}} />

      {auth.isPermissionExists(["0"]) ? (
        <TextArea
          onChange={(event) => onAdditionalTextChange(event.target.value)}
          style={{ marginBottom: "1rem" }}
          showCount
          maxLength={2000}
          placeholder="Add any additional information, as required"
        />
      ) : (
        <p>{additionalText}</p>
      )}


    </div>
    }
    else{
      return <div>
      <p>Dear Dr. {"{Name}"},</p>
      <p>
        This is a gentle reminder on the SUSAR notification. Request you to
        kindly provide{" "}
        {templateType === "Acknowledgement"
          ? "your acknowledgement of receipt of the SUSAR notification."
          : "the confirmation of the notification to EC/IRB."}
      </p>

      {auth.isPermissionExists(["0"]) ? (
        <TextArea
          onChange={(event) => onAdditionalTextChange(event.target.value)}
          style={{ marginBottom: "1rem" }}
          showCount
          maxLength={2000}
          placeholder="Add any additional information, as required"
        />
      ) : (
        <p>{additionalText}</p>
      )}

      {/* <Link to Access and Acknowledge SUSAR> */}

      <p>
        Thank you, and feel free to contact us should you have any questions.
      </p>
      <div>
        <p>Regards,</p>
        <p>Soterius Safety Team</p>
        <p>On behalf of {org}</p>
      </div>
    </div>
    }
   
 
}

export default ReminderTemplate;

import { Tag } from "antd";
import { getPercentTagColor } from "../../Helper/Methods";
import { replaceValuesWithDash } from "../../unity-ui-common/Methods/Methods";

export const aggregatedReportTypes = [
  {
    value: "segregatedByCountry",
    label: "Segregated by Country",
  },
  {
    value: "segregatedByStudy",
    label: "Segregated by Study",
  },
  {
    value: "segregatedByProduct",
    label: "Segregated by Product",
  },
  {
    value: "segregatedByContactType",
    label: "Segregated by Contact Type",
  },
];

export const reportedWithinDueDateItems = [
  {
    title: "Yes",
    value: 0,
  },
  {
    title: "Not Applicable",
    value: 1,
  },
  {
    title: "No",
    value: 2,
  },
];

export const contactTypeItem = [
  {
    label: "Principal Investigator",
    name: "15",
  },
  {
    label: "Other Site Personnel",
    name: "16",
  },
  {
    label: "Regulatory Authority",
    name: "18",
  },
  {
    label: "Ethics Committee / Institutional Review Board",
    name: "17",
  },
  {
    label: "Partner",
    name: "19",
  },
];
export const distributionListcolumns = [
  {
    title: "Contact Type",
    dataIndex: "role",
    width: 170,
    key: "role",
  },
  {
    title: "Contact Country",
    width: 150,
    dataIndex: "country",
    key: "country",
  },
  {
    title: "Contact Name",
    width: 150,
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Reporting Method",
    width: 190,
    dataIndex: "modeOfCorrespondence",
    key: "modeOfCorrespondence",
  },
  {
    title: "Day 0",
    width: 150,
    dataIndex: "irb",
    key: "irb",
  },
  {
    title: "Due Date",
    width: 150,
    dataIndex: "dueDate",
    key: "dueDate",
  },
  {
    title: "Reporting Date/Notification Date",
    width: 280,
    dataIndex: "sentOn",
    key: "sentOn",
  },
  {
    title: "Notified within due date",
    width: 250,
    dataIndex: "reportedWithinDueDate",
    key: "reportedWithinDueDate",
  },
];

export const segregatedContactReportColumns = [
  {
    title: "Contact Type",
    dataIndex: "role",
    key: "role",
  },
  {
    title: "Contact Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Email ID",
    dataIndex: "primary_email_id",
    key: "primary_email_id",
  },
  {
    title: "Site Number",
    dataIndex: "site_number",
    key: "site_number",
  },
  {
    title: "Site Name",
    dataIndex: "site_name",
    key: "site_name",
  },
  {
    title: "Contact Country",
    dataIndex: "country",
    key: "country",
  },
  {
    title: "Reporting Method",
    dataIndex: "modeOfCorrespondence",
    key: "modeOfCorrespondence",
  },
  {
    title: "Number of Safety Documents Shared",
    dataIndex: "totalDocShared",
    key: "totalDocShared",
    width: 280,
    render: (data: string) => {
      return data || "-";
    },
  },
  {
    title: "Safety Document Notification Compliance",
    dataIndex: "siteNotificationCompliance",
    key: "siteNotificationCompliance",
    width: 300,
    render: (data: any) => {
      const tagColor = getPercentTagColor(data?.value);
      const percentVal = `${parseFloat(data?.value).toFixed(0)}%`;
      const value = `${String(data?.data)}/${String(data?.total)}`;
      return (
        <>
          <Tag color={tagColor} className="percentTag">
            {percentVal}
          </Tag>
          <span>{value}</span>
        </>
      );
    },
  },
  {
    title: "Acknowledgement Compliance",
    dataIndex: "siteAcknowledgementCompliance",
    key: "siteAcknowledgementCompliance",
    width: 280,
    render: (data: any) => {
      const tagColor = getPercentTagColor(data?.value);
      const percentVal = `${parseFloat(data?.value).toFixed(0)}%`;
      const value = `${String(data?.data)}/${String(data?.total)}`;
      return (
        <>
          <Tag color={tagColor} className="percentTag">
            {percentVal}
          </Tag>
          <span>{value}</span>
        </>
      );
    },
  },
  {
    title: "EC/IRB Notification Compliance",
    dataIndex: "ECIRBNotificationCompliance",
    key: "ECIRBNotificationCompliance",
    width: 280,
    render: (data: any) => {
      const tagColor = getPercentTagColor(data?.value);
      const percentVal = `${parseFloat(data?.value).toFixed(0)}%`;
      const value = `${String(data?.data)}/${String(data?.total)}`;
      return (
        <>
          <Tag color={tagColor} className="percentTag">
            {percentVal}
          </Tag>
          <span>{value}</span>
        </>
      );
    },
  },
];

export const aggregatedContactReportByCountryColumns = [
  {
    title: "Contact Type",
    dataIndex: "role",
    key: "role",
    render: (role: any) => {
      const text = Array.isArray(role) ? (
        <div>
          {role.map((rol: any) => {
            return <p style={{ margin: "0" }}>{rol}</p>;
          })}
        </div>
      ) : (
        role
      );
      return text;
    },
  },
  {
    title: "Contact Country",
    dataIndex: "country",
    key: "country",
    render: (country: any) => {
      const text = Array.isArray(country) ? country.join(", ") : country;
      return text;
    },
  },
  {
    title: "Study Number",
    dataIndex: "studyId",
    key: "studyId",
    render: (studyId: any) => {
      const text = Array.isArray(studyId)
        ? replaceValuesWithDash(studyId).join(", ")
        : studyId;
      return text;
    },
  },
  {
    title: "Product",
    dataIndex: "product",
    key: "product",
    render: (product: any) => {
      const text = Array.isArray(product)
        ? replaceValuesWithDash(product).join(", ")
        : product;
      return text;
    },
  },

  {
    title: "Number of Safety Documents Shared",
    dataIndex: "totalDocShared",
    key: "totalDocShared",
    width: 280,
    render: (data: string) => {
      return data || "-";
    },
  },
  {
    title: "Safety Document Notification Compliance",
    dataIndex: "safetyDocumentNotificationCompliance",
    key: "safetyDocumentNotificationCompliance",
    width: 280,
    render: (data: any) => {
      const tagColor = getPercentTagColor(data?.value);
      const percentVal = `${parseFloat(data?.value).toFixed(0)}%`;
      const value = `${String(data?.data)}/${String(data?.total)}`;
      return (
        <>
          <Tag color={tagColor} className="percentTag">
            {percentVal}
          </Tag>
          <span>{value}</span>
        </>
      );
    },
  },
];

export const aggregatedContactReportByStudyColumns = [
  {
    title: "Contact Type",
    dataIndex: "role",
    key: "role",
    render: (role: any) => {
      const text = Array.isArray(role) ? (
        <div>
          {role.map((rol: any) => {
            return <p style={{ margin: "0" }}>{rol}</p>;
          })}
        </div>
      ) : (
        role
      );
      return text;
    },
  },
  {
    title: "Study Number",
    dataIndex: "studyId",
    key: "studyId",
    render: (studyId: any) => {
      const text = Array.isArray(studyId)
        ? replaceValuesWithDash(studyId).join(", ")
        : studyId;
      return text;
    },
  },
  {
    title: "Contact Country",
    dataIndex: "country",
    key: "country",
    render: (country: any) => {
      const text = Array.isArray(country) ? country.join(", ") : country;
      return text;
    },
  },
  {
    title: "Product",
    dataIndex: "product",
    key: "product",
    render: (product: any) => {
      const text = Array.isArray(product)
        ? replaceValuesWithDash(product).join(", ")
        : product;
      return text;
    },
  },

  {
    title: "Number of Safety Documents Shared",
    dataIndex: "totalDocShared",
    key: "totalDocShared",
    width: 280,
    render: (data: string) => {
      return data || "-";
    },
  },
  {
    title: "Safety Document Notification Compliance",
    dataIndex: "safetyDocumentNotificationCompliance",
    key: "safetyDocumentNotificationCompliance",
    width: 280,
    render: (data: any) => {
      const tagColor = getPercentTagColor(data?.value);
      const percentVal = `${parseFloat(data?.value).toFixed(0)}%`;
      const value = `${String(data?.data)}/${String(data?.total)}`;
      return (
        <>
          <Tag color={tagColor} className="percentTag">
            {percentVal}
          </Tag>
          <span>{value}</span>
        </>
      );
    },
  },
];

export const aggregatedContactReportByProductColumns = [
  {
    title: "Contact Type",
    dataIndex: "role",
    key: "role",
    render: (role: any) => {
      const text = Array.isArray(role) ? (
        <div>
          {role.map((rol: any) => {
            return <p style={{ margin: "0" }}>{rol}</p>;
          })}
        </div>
      ) : (
        role
      );
      return text;
    },
  },
  {
    title: "Product",
    dataIndex: "product",
    key: "product",
    render: (product: any) => {
      const text = Array.isArray(product)
        ? replaceValuesWithDash(product).join(", ")
        : product;
      return text;
    },
  },
  {
    title: "Contact Country",
    dataIndex: "country",
    key: "country",
    render: (country: any) => {
      const text = Array.isArray(country) ? country.join(", ") : country;
      return text;
    },
  },
  {
    title: "Study Number",
    dataIndex: "studyId",
    key: "studyId",
    render: (studyId: any) => {
      const text = Array.isArray(studyId)
        ? replaceValuesWithDash(studyId).join(", ")
        : studyId;
      return text;
    },
  },
  {
    title: "Number of Safety Documents Shared",
    dataIndex: "totalDocShared",
    key: "totalDocShared",
    width: 280,
    render: (data: string) => {
      return data || "-";
    },
  },
  {
    title: "Safety Document Notification Compliance",
    dataIndex: "safetyDocumentNotificationCompliance",
    key: "safetyDocumentNotificationCompliance",
    width: 280,
    render: (data: any) => {
      const tagColor = getPercentTagColor(data?.value);
      const percentVal = `${parseFloat(data?.value).toFixed(0)}%`;
      const value = `${String(data?.data)}/${String(data?.total)}`;
      return (
        <>
          <Tag color={tagColor} className="percentTag">
            {percentVal}
          </Tag>
          <span>{value}</span>
        </>
      );
    },
  },
];

export const getAggregatedContactReportColumns = (type: string) => {
  switch (type) {
    case "segregatedByCountry":
      return aggregatedContactReportByCountryColumns;
    case "segregatedByStudy":
      return aggregatedContactReportByStudyColumns;
    case "segregatedByProduct":
      return aggregatedContactReportByProductColumns;
    default:
      return aggregatedContactReportByCountryColumns;
  }
};

export const getAggregatedContactReportColumnsDataName = (type: string) => {
  switch (type) {
    case "segregatedByCountry":
      return "aggregatedContactReportByCountryColumns";
    case "segregatedByStudy":
      return "aggregatedContactReportByStudyColumns";
    case "segregatedByProduct":
      return "aggregatedContactReportByProductColumns";
    default:
      return "aggregatedContactReportByCountryColumns";
  }
};

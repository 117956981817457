import {
  DashboardOutlined,
  SearchOutlined,
  CustomerServiceOutlined,
  BarChartOutlined,
  UserOutlined,
  CodeSandboxOutlined,
  UserSwitchOutlined,
  FileSyncOutlined,
  SnippetsOutlined,
  AntDesignOutlined,
  DatabaseOutlined,
  EditOutlined,
  CalendarOutlined,
  BellOutlined,
  FileDoneOutlined,
  InteractionOutlined
} from "@ant-design/icons";
import { IMenuItem } from "../Interfaces/Config/SideMenuItems.interface";

const sideMenuItems: IMenuItem[] = [
  {
    key: "1",
    Icon: <DashboardOutlined />,
    title: "Home",
    url: "/home",
    privileges: ["0", "1", "2", "3"],
  },
  {
    key: "2",
    Icon: <InteractionOutlined />,
    title: "Safety Document Exchange",
    url: "/search",
    privileges: ["3"],
  },
  {
    key: "1111",
    Icon: <CalendarOutlined />,
    title: "Safety Document Calendar",
    url: "/coming-soon/Safety_Document_Calendar",
    // privileges: ["3"],
  },
  {
    key: "111",
    Icon: <FileDoneOutlined />,
    title: "Safety Document Development",
    // url: "/search",
    // privileges: ["3"],
    subItems: [
      {
        
        key: "33",
        Icon: <SnippetsOutlined />,
        url: "/template-management",
        title: "Template Manager",
        // privileges: ["3"],
      },
      {
        key: "44",
        Icon:<AntDesignOutlined />,
        url: "/coming-soon/Workflow_Manager",
        title: "Workflow Manager",
        privileges: ["3"],
      },
      {
        key: "55",
        url: "/coming-soon/Content_Manager",
        Icon:<DatabaseOutlined />,
        title: "Content Manager",
        privileges: ["3"],
      },
      {
        key: "66",
        url: "/coming-soon/Editor",
        Icon:<EditOutlined />,
        title: "Editor",
        privileges: ["3"],
      },
      
    ]
  },
  {
    key: "11111",
    Icon: <BellOutlined />,
    title: "Safety Document Notifications",
    url: "/notifications",
    // privileges: ["3"],
  },
  // {
  //   key: "12",
  //   Icon: <DashboardOutlined />,
  //   title: "Notifications",
  //   url: "/notifications",
  //   privileges: ["5", "6"]
  // },
  {
    key: "sub-1",
    Icon: <BarChartOutlined />,
    title: "Compliance Reports",
    subItems: [
      {
        key: "3",
        url: "/safety-document",
        title: "Safety Documents",
        privileges: ["3"],
      },
      {
        key: "4",
        url: "/contact-compliance",
        title: "Contacts",
        privileges: ["3"],
      },
      {
        key: "5",
        url: "/sites",
        title: "Sites",
        privileges: ["3"],
      },
      {
        key: "6",
        url: "/cumulative-raw-data-report",
        title: "Cumulative Raw Data Report",
        privileges: ["3"],
      },
      {
        key: "7",
        url: "/auditlog",
        title: "Audit Log",
        privileges: ["7"],
      },
    ],
  },
  // {
  //   key: "15",
  //   Icon: <FileSyncOutlined />,
  //   title: "Template Management",
  //   url: "/template-management",
  //   // privileges: ["10"]
  // },
  // {
  //   key: "16",
  //   Icon: <FileSyncOutlined />,
  //   title: "Document Management",
  //   url: "/document-management",
  //   // privileges: ["10"]
  // },
  {
    key: "11",
    Icon: <CodeSandboxOutlined />,
    title: "Reporting Rules",
    url: "/reporting-rules",
    privileges: ["10"]
  },
  {
    key: "12",
    Icon: <CodeSandboxOutlined />,
    title: "WorkFlow Configuration",
    url: "/workflow-configuration",
  },
  {
    key: "sub-2",
    Icon: <UserSwitchOutlined />,
    title: "User Management",
    subItems: [
      {
        key: "8",
        url: "/user-management/sponsors",
        title: "Sponsors",
        privileges: ["9"]
      },
      {
        key: "9",
        url: "/user-management/roles",
        title: "User Roles",
        privileges: ["9"]
      },
      {
        key: "10",
        url: "/user-management/users",
        title: "Users",
        privileges: ["9"]
      },
    ],
  },
  
  {
    key: "13",
    Icon: <UserOutlined />,
    title: "Profile",
    url: "/profile",
  },
  {
    key: "14",
    Icon: <CustomerServiceOutlined />,
    title: "Support",
    url: "/support",
  },
];

export default sideMenuItems;

import { useState } from "react";
import ReModal from "../../unity-ui-common/Components/ReModal";
import { file } from "../../API/File";
import { Button, Divider, Result } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import * as Styles from "./ImportContactListModalStyles";
import ReUpload from "../../unity-ui-common/Components/ReFormFields/ReUpload";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import { convertToSimpleCaseFromCamelCase } from "../../Helper/Methods";
import { distributions } from "../../API/Distribution";

interface IProps {
  visibility: boolean;
  setVisibility: Function;
  // getTableData: Function;
  isImported: boolean;
  setIsImported: Function;
  isFileUploded: boolean;
  setIsFileUploded: Function;
  safetyDocumentID: string | undefined;
  flag: boolean;
  setFlag: Function;
}

function ImportContactListModal(props: IProps) {
  const {
    visibility,
    setVisibility,
    // getTableData,
    isImported,
    setIsImported,
    isFileUploded,
    setIsFileUploded,
    safetyDocumentID,
    flag,
    setFlag
  } = props;

  const [importButtonLoading, setImportButtonLoading] =
    useState<boolean>(false);
  const [response, setResponse] = useState({
    imported: 0,
    updated: 0,
    duplicate: 0,
    total: 0,
  });
  const [attachmentFormData, setAttachmentFormData] = useState({});
  const [attachmentList, setAttachmentList] = useState<any>([]);

  const onBeforeAttachmentUpload = async (fileformData: any) => {
    const filenameArr = fileformData["name"].split(".");
    const file_type = filenameArr[filenameArr.length - 1];

    const res = await file.getUrl(file_type);
    const fileName = filenameArr[0];
    setAttachmentList([fileformData]);
    await file.remoteUploadFile(res.url, fileformData);

    setAttachmentFormData({
      fileUID: fileformData.uid,
      id: res.id,
      filename: fileName,
      file_type,
    });
    setIsFileUploded(true);
  };

  const handleAttachmentFileRemove = (fileformData: any) => {
    const index = attachmentList.indexOf(fileformData);
    const newFileList = attachmentList.slice();
    newFileList.splice(index, 1);
    setAttachmentList(newFileList);
    setAttachmentFormData({});
    setIsFileUploded(false);
  };

  function downloadFile() {
    const link = document.createElement("a");
    link.href = "/Assets/Templates/template.xlsx";
    link.download = "Contact List Template";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleSubmit = async () => {
    setImportButtonLoading(true);
    if (!safetyDocumentID) throw new Error("Report ID not found");
    let res;
    res = await distributions.import(
      safetyDocumentID,
      attachmentList[0],
      attachmentFormData
    );
    if (!res?.statusCode) {
      setResponse(res);
      setIsImported(true);
    } else {
      ReNotification({
        description: convertToSimpleCaseFromCamelCase(
          res.error.split("\n")[0]
        ).replace("distribution.", " "),
        duration: 1000,
        placement: "bottomLeft",
        type: "error",
      });
    }
    setImportButtonLoading(false);
    // getTableData();
    setFlag(!flag)
  };

  return (
    <ReModal
      width={600}
      title="Import Contact List"
      visibility={visibility}
      onCancel={() => {
        setVisibility(false);
      }}
      footer={false}
    >
      <Styles.ModalBody>
        {!isImported && (
          <>
            <Button
              type="link"
              onClick={downloadFile}
              icon={<DownloadOutlined />}
            >
              Download Contact List Template
            </Button>
            <Divider />
          </>
        )}
        {isImported ? (
          <Result
            status="success"
            title="Contact List Imported Successfully"
            subTitle={
              <>
                <strong>{response?.imported} New Contacts</strong> were imported,{" "}
                <strong>{response?.updated} Contacts</strong> updated,{" "}
                <strong>{response?.duplicate} duplicate records</strong>{" "}
                detected, <strong>total contacts were {response?.total} </strong>
              </>
            }
            extra={[
              <Button
                onClick={async () => {
                  setAttachmentList([]);
                  setIsImported(false);
                  setIsFileUploded(false);
                  setVisibility(false);
                }}
              >
                Done
              </Button>,
            ]}
          />
        ) : (
          <>
            <ReUpload
              errorMessage="Please enter your file"
              accept=".xlsx"
              label=""
              BtnTitle="Click to Upload File"
              name="attachments"
              onBeforeUpload={onBeforeAttachmentUpload}
              onRemove={handleAttachmentFileRemove}
              fileListMaxCount={1}
              fileList={attachmentList}
            />
            {isFileUploded && (
              <Button
                loading={importButtonLoading}
                type="primary"
                onClick={handleSubmit}
              >
                Import
              </Button>
            )}
          </>
        )}
      </Styles.ModalBody>
    </ReModal>
  );
}

export default ImportContactListModal;

import styled from "styled-components";

export const Container = styled.div`
  .ruleForm{
    padding: 0.5rem 1rem;
    background-color: #ffff
  }
`;
export const QueryBuilderContainer = styled.div`
  .ruleGroup-addRule {
    background-color: #dd2515;
    color: #fff;
    border: 1px solid #dd2515;
    padding: 0 0.5rem;
    border-radius: 5px;
    cursor: pointer;
  }
  .rule-remove {
    width: 20px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #d9d9d9;
  }
  .ruleGroup-addGroup {
    background-color: #dd2515;
    color: #fff;
    border: 1px solid #dd2515;
    padding: 0 0.5rem;
    border-radius: 5px;
    cursor: pointer;
  }
  .rule-fields {
    border: 1px solid #d9d9d9;
  }
  .rule-operators {
    border: 1px solid #d9d9d9;
  }
  .rule-value {
    border: 1px solid #d9d9d9;
  }
  .ruleGroup {
    background-color: #ffffff;
    border: 1px solid #ffffff;
    padding: 0.5rem;
  }
  .queryBuilder {
    /* border: 1px solid #dd2515; */
    border-right: 1px solid #d9d9d9;
    border-radius: 5px;
  }
`;

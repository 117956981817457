import styled from "styled-components";

export const Container = styled.div`
  background-color: #ffffff;
  padding: 1rem;
  min-width: 10rem;
  color: #1890ff;
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100px;
    cursor: pointer;
    h4 {
      color: #1890ff;
    }
  }
  span {
    font-size: 50px;
    font-weight: 600;
  }
`;

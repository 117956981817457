import * as Styles from "./QcConfigurationDrawerStyles";
import { useEffect, useState } from "react";
import { Button, Drawer, Form } from "antd";
import { ReNotification } from "../../../../unity-ui-common/Components/ReNotification";
import ReInput from "../../../../unity-ui-common/Components/ReFormFields/ReInput";
import { tenant } from "../../../../API/UserManagement/Tenant";

function QcConfigurationDrawer(props: {
  visibility: boolean;
  setVisibility: Function;
  currEditingSponor: any;
  setCurrEditingSponor: Function;
}) {
  const { visibility, setVisibility, currEditingSponor } = props;

  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const onFinish = async (values: any) => {
    setSubmitting(true);

    values.contactsToSend = values.contactsToSend.split(",");
    const res = await tenant.update(currEditingSponor.id, {
      configs: values,
    });
    if (res?.status) {
      ReNotification({
        header: "Sponsor Configuration",
        description: "Sponsor is updated successfully",
        type: "success",
        duration: 1000,
        placement: "bottomLeft",
      });
      // fetchAllSponors();
    } else {
      ReNotification({
        header: "Sponsor Configuration",
        description: "Sponsor is not updated",
        type: "error",
        duration: 1000,
        placement: "bottomLeft",
      });
    }
    setVisibility(false);

    setSubmitting(false);
    setVisibility(false);
    // dispatch(getAllSponsors());
  };

  const setInitialValuesOfForm = () => {
    const configs = currEditingSponor?.configs;
    form.setFieldsValue({
      numberOfDays: configs?.numberOfDays,
      contactsToSend: configs?.contactsToSend
        ? configs?.contactsToSend.join(",")
        : "",
    });
  };

  useEffect(() => {
    setInitialValuesOfForm();
  }, [currEditingSponor]);

  return (
    <div>
      <Drawer
        className="drawer"
        title="Configuration"
        width={600}
        placement="right"
        onClose={() => {
          setVisibility(false);
        }}
        open={visibility}
      >
        <Styles.Body>
          <Form
            className="form"
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={() => {}}
          >
            <ReInput
              min={0}
              required
              name="numberOfDays"
              type="number"
              label="Send reminder after (days)"
            />
            <ReInput
              required
              name="contactsToSend"
              type="textArea"
              label="Alert e-mail IDs"
            />
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={submitting}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Styles.Body>
      </Drawer>
    </div>
  );
}

export default QcConfigurationDrawer;

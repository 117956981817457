import * as styles from "./SupportIdGeneratorStyles";
import {
  RedoOutlined,
  FileProtectOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import { useEffect } from "react";
import { supportIdGenerator } from "../../Helper/Methods";

function SupportIdGenerator(props: { supportId: string, setSupportId: Function }) {

  const { supportId, setSupportId } = props;
  
  function handleRefresh() {
    setSupportId(supportIdGenerator());
  }

  useEffect(() => {
    setSupportId(supportIdGenerator());
  }, []);

  return (
    <styles.Container>
      <h3>Support ID</h3>
      <styles.Body>
        <styles.SubContainer>
          <FileProtectOutlined className="icon" />
          <styles.Id>
            <h1>
              <span>#</span>
              {supportId.toUpperCase()}
            </h1>
            <h4>Your Support ID</h4>
          </styles.Id>
        </styles.SubContainer>
        <Button
          danger
          icon={<RedoOutlined />}
          size="large"
          onClick={handleRefresh}
        />
      </styles.Body>
    </styles.Container>
  );
}

export default SupportIdGenerator;

import Styled from 'styled-components';

export const Container = Styled.div`
    width: 100%;
    padding: 1rem 1rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 95vh;
    overflow-y: scroll;

    a{
        color: inherit!important;
    }
    .searchBar{
        margin-top: 20px
    }

    .filter{
        margin-top: 20px;
        height: 50px;
        display: flex;
        gap: 10px;

        .ant-form-item{
            width: 250px;
        }
    }

    .allTemplate{
        /* margin-top: 20px; */
        display: grid;
        place-items: center;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        gap: 10px;
        
        .templateContainer{
            width: 100%!important;
            position: relative;

            .action{
                position: absolute;
                top: 5px;
                right: 5px;
                height: 25px;
                width: 25px;
                background-color: #DD2515;
                color: white!important;
                border-radius: 2px;
                display: grid;
                place-items: center;
            }
        }
    }
    
    .templatesNotFound {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 2px dashed darkgrey;
        padding: 30px;
        font-size: 2.5rem;
        border-radius: 2px;
        color: #DD2515;

        .text{
            margin-top: 10px;
            font-size: 1.25rem;
            color: black;
        }
    }

    .headingSection{
        border-bottom: 1px solid #DD2515;
        margin-bottom: 5px;
    }

    .templateCard{
        width: 100%;
        padding: 15px 20px;
        padding-bottom: 33px;
        background-color: white;
        position: relative;
        transition: 0.5s ease-in-out;
        min-height: 155px;

        .inactive{
            position: absolute;
            inset: 0;
            background: rgba(235, 235, 235, 0.3);
            outline: 2px solid rgb(235, 235, 235);
        }
        
        :hover{
            cursor: pointer;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
            .edit{
                opacity: 1;
            }
        }

        .nameContainer{
            display: flex;
            align-items: center;
            gap: 5px;
            margin-bottom: 5px;

            .ant-tag{
                margin: 0;
            }
            .name{
                font-size: 1.2rem;
                font-weight: 500;
                margin-right: 5px;
            }
        }
        

        .description{
            word-wrap: break-word;
            hyphens: auto;
            white-space: normal
            overflow-wrap: break-word;
        }

        .edit{
            position: absolute;
            right: 20px;
            bottom: 10px;
            opacity: 0;
            transition: 0.25s ease-in-out;
        }
    }

    .addNewTemplateBtn{
        position: fixed;
        bottom: 20px;
        right: 20px;
        height: 40px;
        width: 40px;
        background-color: #DD2515;
        border-radius: 2px;
        font-size: 1rem;
        display: flex;
        jutify-content: center;
        align-items: center;
        white-space: nowrap;
        color: white;
        transition: 0.25s ease-in-out;

        .icon{
            font-size: 1.75rem;
            margin-inline: 6px;
        }
        .text{
            opacity: 0;
            transition: 0.25s ease-in-out;
        }
        :hover{
            width: 185px;
            .text{
                opacity: 1
            }
        }
    }
`;
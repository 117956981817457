import styled from "styled-components";

export const Container = styled.div``;
export const Options = styled.div`
  display: flex;
  gap: 1rem;
`;
export const Body = styled.div`
  /* min-height: 40vh; */
  /* margin-bottom: 3rem; */
`;
export const success = styled.div`
  display: flex;
  gap: 1rem;
  align-items: start;
`;

import { useEffect, useState } from "react";
import moment from "moment";
import CaseDetailHeader from "../ReportDetailHeader/ReportDetailHeader";
import * as styles from "./SafetyDocumentDetailInformationStyles";
import { auth } from "../../API/Auth";
import ReusableForm from "../../unity-ui-common/Components/ReForm";
import ReInput from "../../unity-ui-common/Components/ReFormFields/ReInput";
import ReSelect from "../../unity-ui-common/Components/ReFormFields/ReSelect";
import ReDatePicker from "../../unity-ui-common/Components/ReFormFields/ReDatePicker";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import { convertDateInReadableFormat } from "../../Helper/Methods";
import ReRadioGroup from "../../unity-ui-common/Components/ReFormFields/ReRadioGroup";
import { Collapse, Button, Form } from "antd";
import {
  InvestigatorCausalityDropDownItems,
  seriousnessCriteriaDropDownItems,
  seriousnessCriteriaDropDownItemsDisplay,
  severityDropDownItems,
} from "../../Helper/Mappings";
import { convertCamelToSimpleCase } from "../../unity-ui-common/Methods/Methods";
import { report } from "../../API/Report";
import { ruleEngine } from "../../API/RuleEngine";
import ReCustomSelect from "../../unity-ui-common/Components/ReCustomSelect/ReCustomSelect";

type Props = {
  setReportDetails: Function;
  reportDetials: any;
  reportId: string | undefined;
  allVersions: any;
  selectedReport: any;
  setSelectedReport: React.Dispatch<any>;
};

function SafetyDocumentDetailInformation(props: Props) {
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const { reportDetials, reportId, setReportDetails } = props;

  const [allCountries, setAllCountries] = useState<any>([]);
  const [editable, setEditable] = useState(false);
  const [documentTypeOtherVisibility, setdocumentTypeOtherVisibility] =
    useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [commaSeperatedValues, setCommaSeperatedValues] = useState<any>({});

  const setReportData = async () => {
    if (reportId) {
      const seriousnessCriteriaValue = Array.isArray(
        reportDetials?.meta?.seriousnessCriteria
      )
        ? reportDetials?.meta?.seriousnessCriteria?.map(
            (seriousnessValue: string) => {
              return convertCamelToSimpleCase(seriousnessValue);
            }
          )
        : reportDetials?.meta?.seriousnessCriteria;

      const severityValue = Array.isArray(reportDetials?.meta?.severity)
        ? reportDetials?.meta?.severity?.filter((val: any) => val !== "")
        : [];

      setCommaSeperatedValues({
        seriousnessCriteria: seriousnessCriteriaValue,
        investigatorCausality: reportDetials?.meta?.investigatorCausality,
        sponsorCausality: reportDetials?.meta?.sponsorCausality,
        severity: severityValue,
      });

      form.setFieldsValue({
        safetyDatabaseReportNumber: reportDetials.safetyDatabaseReportNumber,
        seriousnessCriteria: reportDetials?.meta?.seriousnessCriteria,
        product: reportDetials.product,
        studyID: reportDetials.studyID,
        investigatorCausality: reportDetials?.meta?.investigatorCausality,
        studyTitle: reportDetials.studyTitle,
        saeReportedTerms: reportDetials?.meta?.saeReportedTerms,
        sourceSiteName: reportDetials.sourceSiteName,
        sourceSiteID: reportDetials.sourceSiteID,
        sponsorCausality: reportDetials?.meta?.sponsorCausality,
        lrd: reportDetials.lrd ? moment(reportDetials.lrd) : null,
        type: reportDetials.type,
        typeValue: reportDetials.typeValue,
        country: reportDetials?.country,
      });
    }
  };

  const saveReport = async (values: any) => {
    setSubmitLoading(true);
    setCommaSeperatedValues({
      seriousnessCriteria: values.seriousnessCriteria,
      investigatorCausality: values.investigatorCausality,
      sponsorCausality: values.sponsorCausality,
    });

    let newSeverityValue: string[] = [];
    values?.severity?.forEach((item: any) => {
      newSeverityValue.push(item.label);
    });

    values["meta"] = {
      saeReportedTerms: values.saeReportedTerms,
      seriousnessCriteria: values.seriousnessCriteria,
      severity: newSeverityValue,
      investigatorCausality: values.investigatorCausality,
      sponsorCausality: values.sponsorCausality,
    };
    delete values.saeReportedTerms;
    delete values.seriousnessCriteria;
    delete values.investigatorCausality;
    delete values.sponsorCausality;
    delete values.severity;

    let res = await report.update(reportId, values);

    if (res && res?.status === 200) {
      ReNotification({
        description: "Report saved Successfully",
        duration: 1000,
        placement: "bottomLeft",
        type: "success",
      });
      setEditable(false);
      setReportDetails({
        ...reportDetials,
        ...values,
      });
    } else {
      ReNotification({
        description: "An error ocurred",
        duration: 1000,
        placement: "bottomLeft",
        type: "error",
      });
    }
    setSubmitLoading(false);
  };

  const handleFormChanges = (changedValues: any, allValues: any) => {
    if (allValues.type === 0) {
      setdocumentTypeOtherVisibility(true);
    } else {
      setdocumentTypeOtherVisibility(false);
    }
  };

  const handleSeriousnessCriteriaDisplay = () => {
    let finalValue = "";
    if (
      commaSeperatedValues?.seriousnessCriteria &&
      commaSeperatedValues?.seriousnessCriteria?.length > 0 &&
      Array.isArray(commaSeperatedValues?.seriousnessCriteria)
    ) {
      const formatedValueArr = commaSeperatedValues?.seriousnessCriteria?.map(
        (ele: any) => {
          let formattedValue;
          seriousnessCriteriaDropDownItemsDisplay?.forEach(
            (dropDownOptions) => {
              if (dropDownOptions.key === ele) {
                formattedValue = dropDownOptions.title;
              }
            }
          );
          return formattedValue;
        }
      );
      finalValue = formatedValueArr?.join(", ");
    }

    return finalValue || "-";
  };

  const getCountries = async () => {
    const res = await ruleEngine.getCountries();
    setAllCountries(res);
  };

  useEffect(() => {
    getCountries();
    setReportData();
    if (reportDetials?.type === 0) {
      setdocumentTypeOtherVisibility(true);
    }
  }, [reportId, reportDetials]);

  return (
    <styles.Container>
      <CaseDetailHeader
        reportId={form.getFieldValue("safetyDatabaseReportNumber")}
        lrd={convertDateInReadableFormat(form.getFieldValue("lrd"))}
        dueDate={convertDateInReadableFormat(reportDetials?.dueDate)}
        allVersions={props.allVersions}
        selectedReport={props.selectedReport}
        setSelectedReport={props.setSelectedReport}
      />
      <Collapse bordered={false} className="dropDown">
        <Panel header="Detailed Information" key="1">
          <styles.Options>
            {!editable && auth.isPermissionExists(["1"]) && (
              <Button
                size="large"
                type="link"
                onClick={() => {
                  setEditable(true);
                }}
              >
                Edit
              </Button>
            )}
            {editable && (
              <>
                <Button
                  size="large"
                  type="text"
                  onClick={() => {
                    setEditable(false);
                    setReportData();
                  }}
                >
                  Cancel
                </Button>

                <Button
                  size="large"
                  loading={submitLoading}
                  type="link"
                  onClick={() => {
                    form.submit();
                  }}
                >
                  Save
                </Button>
              </>
            )}
          </styles.Options>
          <ReusableForm
            formInstance={form}
            onSubmit={saveReport}
            layout="horizontal"
            onChange={handleFormChanges}
            className="safety-document-edit-form"
          >
            <div className="formItem">
              <span>Safety Document Type</span>
              {editable ? (
                <div className="formInput">
                  <ReRadioGroup
                    defaultValue={7}
                    label="Safety Document"
                    noStyle
                    name="type"
                    items={[
                      {
                        title: "7 day SUSAR",
                        value: 7,
                      },
                      {
                        title: "15 day SUSAR",
                        value: 15,
                      },
                      {
                        title: "Other",
                        value: 0,
                      },
                    ]}
                  />
                  {documentTypeOtherVisibility && (
                    <div style={{ marginTop: "0.5rem" }}>
                      <ReInput
                        type="simple"
                        name="typeValue"
                        label=""
                        noStyle
                        size="small"
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div className="formData">
                  {form.getFieldValue("typeValue")?.length > 0 &&
                  form.getFieldValue("type") === 0
                    ? form.getFieldValue("typeValue")
                    : form.getFieldValue("type") + " day SUSAR"}
                </div>
              )}
            </div>
            <div className="formItem">
              <span>SAE Reported Term(s)</span>
              {editable ? (
                <div className="formInput">
                  <ReInput
                    noStyle
                    name="saeReportedTerms"
                    label=""
                    type="simple"
                  />
                </div>
              ) : form.getFieldValue("saeReportedTerms") ? (
                <div className="formData">
                  {form.getFieldValue("saeReportedTerms")}
                </div>
              ) : (
                "-"
              )}
            </div>
            <div className="formItem">
              <span>Seriousness Criteria</span>
              {editable ? (
                <div className="formInput">
                  <ReSelect
                    noStyle
                    name="seriousnessCriteria"
                    label=""
                    items={seriousnessCriteriaDropDownItems?.map((ele: any) => {
                      return {
                        value: ele.key,
                        title: ele.title,
                      };
                    })}
                    type="multiple"
                  />
                </div>
              ) : form.getFieldValue("seriousnessCriteria")?.length ? (
                <div className="formData">
                  {handleSeriousnessCriteriaDisplay()}
                </div>
              ) : (
                "-"
              )}
            </div>
            <div className="formItem">
              <span>Product</span>
              {editable ? (
                <div className="formInput">
                  <ReInput noStyle name="product" label="" type="simple" />
                </div>
              ) : form.getFieldValue("product") ? (
                <div className="formData">{form.getFieldValue("product")}</div>
              ) : (
                "-"
              )}
            </div>
            <div className="formItem">
              <span>Severity</span>
              {editable ? (
                <div className="formInput">
                  <ReCustomSelect
                    formInstance={form}
                    label=""
                    noStyle
                    name="severity"
                    dropDownItems={severityDropDownItems?.map((ele: any) => {
                      return {
                        key: ele.title,
                        label: ele.title,
                      };
                    })}
                    defaultValues={reportDetials?.meta?.severity?.map(
                      (ele: any, idx: number) => {
                        return {
                          key: `${ele}_${idx}`,
                          label: ele,
                        };
                      }
                    )}
                  />
                </div>
              ) : (
                <div className="formData">
                  {reportDetials?.meta?.severity?.length
                    ? // Array.isArray(commaSeperatedValues?.severity) ? (
                      //   // commaSeperatedValues?.severity?.join(", ")
                      //   ""
                      // ) : (
                      //   // form.getFieldValue("severity")
                      //   ""
                      // )
                      commaSeperatedValues?.severity?.join(", ")
                    : "-"}
                </div>
              )}
            </div>
            <div className="formItem">
              <span>Study Number</span>
              {editable ? (
                <div className="formInput">
                  <ReInput noStyle name="studyID" label="" type="simple" />
                </div>
              ) : form.getFieldValue("studyID") ? (
                <div className="formData">{form.getFieldValue("studyID")}</div>
              ) : (
                "-"
              )}
            </div>
            <div className="formItem">
              <span>Investigator Causality</span>
              {editable ? (
                <div className="formInput">
                  <ReSelect
                    noStyle
                    name="investigatorCausality"
                    label=""
                    type="multiple"
                    items={InvestigatorCausalityDropDownItems?.map(
                      (ele: any) => {
                        return {
                          value: ele.title,
                          title: ele.title,
                        };
                      }
                    )}
                  />
                </div>
              ) : (
                <div className="formData">
                  {form.getFieldValue("investigatorCausality")?.length
                    ? Array.isArray(commaSeperatedValues?.investigatorCausality)
                      ? commaSeperatedValues?.investigatorCausality?.join(", ")
                      : form.getFieldValue("investigatorCausality")
                    : "-"}
                </div>
              )}
            </div>
            <div className="formItem">
              <span>Study Title</span>
              {editable ? (
                <div className="formInput">
                  <ReInput noStyle name="studyTitle" label="" type="simple" />
                </div>
              ) : form.getFieldValue("studyTitle") ? (
                <div className="formData">
                  {form.getFieldValue("studyTitle")}
                </div>
              ) : (
                "-"
              )}
            </div>
            <div className="formItem">
              <span>Sponsor Causality</span>
              {editable ? (
                <div className="formInput">
                  <ReSelect
                    allowClear={false}
                    noStyle
                    name="sponsorCausality"
                    type="multiple"
                    label=""
                    items={InvestigatorCausalityDropDownItems?.map(
                      (ele: any) => {
                        return {
                          value: ele.title,
                          title: ele.title,
                        };
                      }
                    )}
                  />
                </div>
              ) : (
                <div className="formData">
                  {form.getFieldValue("sponsorCausality")?.length
                    ? Array.isArray(commaSeperatedValues?.sponsorCausality)
                      ? commaSeperatedValues?.sponsorCausality?.join(", ")
                      : form.getFieldValue("sponsorCausality")
                    : "-"}
                </div>
              )}
            </div>
            <div className="formItem">
              <span>Site Number</span>
              {editable ? (
                <div className="formInput">
                  <ReInput noStyle name="sourceSiteID" label="" type="simple" />
                </div>
              ) : form.getFieldValue("sourceSiteID") ? (
                <div className="formData">
                  {form.getFieldValue("sourceSiteID")}
                </div>
              ) : (
                "-"
              )}
            </div>
            <div className="formItem">
              <span>Day 0</span>
              {editable ? (
                <div className="formInput">
                  <ReDatePicker
                    allowClear={false}
                    noStyle
                    label=""
                    name="lrd"
                    type="simple"
                    dateFormat="DD-MMM-YYYY"
                    width="100%"
                    featureDates
                  />
                </div>
              ) : (
                <div className="formData">
                  {convertDateInReadableFormat(form.getFieldValue("lrd") || "")}
                </div>
              )}
            </div>
            <div className="formItem">
              <span>Site Name</span>
              {editable ? (
                <div className="formInput">
                  <ReInput
                    noStyle
                    name="sourceSiteName"
                    label=""
                    type="simple"
                  />
                </div>
              ) : form.getFieldValue("sourceSiteName") ? (
                <div className="formData">
                  {form.getFieldValue("sourceSiteName")}
                </div>
              ) : (
                "-"
              )}
            </div>
            <div className="formItem">
              <span>Safety Document ID</span>
              {editable ? (
                <div className="formInput">
                  <ReInput
                    label=""
                    noStyle
                    name="safetyDatabaseReportNumber"
                    type="simple"
                  />
                </div>
              ) : form.getFieldValue("safetyDatabaseReportNumber") ? (
                <div className="formData">
                  {form.getFieldValue("safetyDatabaseReportNumber")}
                </div>
              ) : (
                "-"
              )}
            </div>
            <div className="formItem">
              <span>Country</span>
              {editable ? (
                <div className="formInput">
                  <ReSelect
                    label=""
                    noStyle
                    name="country"
                    searchable
                    items={allCountries.map((cou: any) => {
                      return {
                        value: cou.alpha2,
                        title: cou.name,
                      };
                    })}
                  />
                </div>
              ) : form.getFieldValue("country") ? (
                <div className="formData">
                  {
                    allCountries.find(
                      (co: any) => co.alpha2 === form.getFieldValue("country")
                    ).name
                  }
                </div>
              ) : (
                "-"
              )}
            </div>
          </ReusableForm>
        </Panel>
      </Collapse>
    </styles.Container>
  );
}

export default SafetyDocumentDetailInformation;

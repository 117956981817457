import { useState, useEffect } from "react";
import { ConfigProvider, Form } from "antd";
import { useNavigate } from "react-router-dom";
import * as Styles from "./WorkspaceStyles";
import LogoWithText from "../../Assets/Icons/LogoWithText.svg";
import { useAppSelector } from "../../app/hooks";
import ReusableForm from "../../unity-ui-common/Components/ReForm";
import ReSelect from "../../unity-ui-common/Components/ReFormFields/ReSelect";
import { workspaceChange } from "./Constant";
import { loadState } from "../../sessionstorage";

function Workspace() {
  const [workspaces, setWorkspaces] = useState<any>([]);
  const sessionData = useAppSelector((state) => state.session);

  const [form] = Form.useForm();

  const [roleData, setRoleData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const userData = loadState()?.session?.userData;

    if (userData?.tenants) {
      const uniqueWorkspaces = Object.values(
        userData?.tenants?.reduce((acc: any, item: any) => {
          acc[item.id] = item;
          return acc;
        }, {})
      );
      setWorkspaces(uniqueWorkspaces);
      setRoleData(userData.roleMatrices);
    }
  }, []);

  async function handleWorkspaceChange(id: any) {
    const data = await workspaceChange(id, sessionData);
    if (data && Object.keys(data).length) {
      const { endPoint } = data;

      navigate(endPoint ?? "", { replace: true });
    }
  }

  return (
    <Styles.SubContainer>
      <ConfigProvider componentSize="middle">
        <Styles.WorkSpaceContainer>
          <Styles.ImgContainer>
            <img src={LogoWithText} alt="logo with text" className="logo-img" />
          </Styles.ImgContainer>

          <Styles.SelectContainer>
            <ReusableForm
              formInstance={form}
              onChange={(values: any) => {
                handleWorkspaceChange(values.selectedWorkspace);
              }}
              onSubmit={() => {}}
            >
              <ReSelect
                label="Select Workspace"
                name="selectedWorkspace"
                autoFocus
                items={workspaces?.map((workspace: any) => {
                  return {
                    value: workspace.id,
                    title: workspace.name,
                  };
                })}
              />
            </ReusableForm>
          </Styles.SelectContainer>
        </Styles.WorkSpaceContainer>
      </ConfigProvider>
    </Styles.SubContainer>
  );
}

export default Workspace;

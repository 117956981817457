import { Tag } from "antd";
import {
  convertDateInReadableFormat,
  getPercentTagColor,
} from "../../Helper/Methods";
import { Link } from "react-router-dom";

export const safetyDocTypeItems = [
  {
    title: "7-Day SUSAR",
    value: 7,
  },
  {
    title: "15-Day SUSAR",
    value: 15,
  },
  {
    title: "Other",
    value: 0,
  },
];

export const safetyDocTypeFilter: any = {
  7: {
    name: "7",
    label: "7-Day SUSAR",
  },
  15: {
    name: "15",
    label: "15-Day SUSAR",
  },
  0: {
    name: "0",
    label: "Other Safety Document",
  },
};

export const aggregatedSafetyDocColumns = [
  {
    title: "Safety Document Type ",
    dataIndex: "type",
    key: "type",
    width: 220,
    render: (data: any) => {
      return safetyDocTypeFilter?.[data]?.label;
    },
  },
  {
    title: "Volume",
    dataIndex: "totalSafetyDoc",
    key: "totalSafetyDoc",
    width: 120,
  },
  {
    title: "Site Notification Compliance",
    dataIndex: "siteNotificationCompliance",
    key: "siteNotificationCompliance",
    width: 220,
    render: (data: any) => {
      const tagColor = getPercentTagColor(data?.value);
      const percentVal = `${parseFloat(data?.value).toFixed(0)}%`;
      const value = `${String(data?.data)}/${String(data?.total)}`;
      return (
        <>
          <Tag color={tagColor} className="percentTag">
            {percentVal}
          </Tag>
          <span>{value}</span>
        </>
      );
    },
  },
  {
    title: "Site Acknowledgement Compliance",
    dataIndex: "siteAcknowledgementCompliance",
    key: "siteAcknowledgementCompliance",
    width: 220,
    render: (data: any) => {
      const tagColor = getPercentTagColor(data?.value);
      const percentVal = `${parseFloat(data?.value).toFixed(0)}%`;
      const value = `${String(data?.data)}/${String(data?.total)}`;
      return (
        <>
          <Tag color={tagColor} className="percentTag">
            {percentVal}
          </Tag>
          <span>{value}</span>
        </>
      );
    },
  },
  {
    title: "EC/IRB Notification Compliance",
    dataIndex: "ECIRBNotificationCompliance",
    key: "ECIRBNotificationCompliance",
    width: 220,
    render: (data: any) => {
      const tagColor = getPercentTagColor(data?.value);
      const percentVal = `${parseFloat(data?.value).toFixed(0)}%`;
      const value = `${String(data?.data)}/${String(data?.total)}`;
      return (
        <>
          <Tag color={tagColor} className="percentTag">
            {percentVal}
          </Tag>
          <span>{value}</span>
        </>
      );
    },
  },
  {
    title: "Regulatory Reporting Compliance",
    dataIndex: "regulatoryReportingCompliance",
    key: "regulatoryReportingCompliance",
    width: 220,
    render: (data: any) => {
      const tagColor = getPercentTagColor(data?.value);
      const percentVal = `${parseFloat(data?.value).toFixed(0)}%`;
      const value = `${String(data?.data)}/${String(data?.total)}`;
      return (
        <>
          <Tag color={tagColor} className="percentTag">
            {percentVal}
          </Tag>
          <span>{value}</span>
        </>
      );
    },
  },
];

export const segregatedDataColumn = [
  {
    title: "Safety Document ID",
    dataIndex: "safetyDatabaseReportNumber",
    key: "safetyDatabaseReportNumber",
    width: 200,
    render: (safetyDatabaseReportNumber: string, data: any) => {
      const { id } = data;
      return (
        <Link to={"/reports/view/" + id}>
          {safetyDatabaseReportNumber || "-"}
        </Link>
      );
    },
  },
  {
    title: "Day 0",
    dataIndex: "lrd",
    key: "lrd",
    width: 200,
    render: (lrd: string) => {
      return lrd ? convertDateInReadableFormat(lrd) : "-";
    },
  },
  {
    title: "Product",
    dataIndex: "product",
    key: "product",
    width: 220,
    render: (product: string) => {
      return product || "-";
    },
  },
  {
    title: "Study Number",
    dataIndex: "studyID",
    width: 160,
    render: (studyID: string) => {
      return studyID || "-";
    },
  },
  {
    title: "Safety Document Type",
    dataIndex: "type",
    key: "type",
    width: 210,
    render: (type: number, row: any) => {
      if (row.type === 0 || row.type === 100) {
        return row.typeValue;
      } else {
        return `${type}-day SUSAR`;
      }
    },
  },
  {
    title: "Sites to be Notified",
    dataIndex: "sitesToBeNotified",
    key: "sitesToBeNotified",
    width: 190,
    render: (sitesToBeNotified: Array<string>) => {
      return (sitesToBeNotified && sitesToBeNotified?.length) || 0;
    },
  },
  {
    title: "Sites Notified",
    dataIndex: "sitesNotified",
    key: "sitesNotified",
    width: 180,
    render: (sitesNotified: Array<string>, record: any) => {
      const { notifiedPercentage, sitesToBeNotified } = record;
     
      const tagColor = getPercentTagColor(notifiedPercentage);
      const percentVal = `${parseFloat(notifiedPercentage).toFixed(0)}%`;
      const value = `${String(sitesNotified?.length)}/${String(
        sitesToBeNotified?.length
      )}`;
      return (
        <>
          <Tag color={tagColor} className="percentTag">
            {percentVal}
          </Tag>
          <span>{value}</span>
        </>
      );
    },
  },
  {
    title: "Acknowledgements Received",
    dataIndex: "sitesAcknowledged",
    key: "sitesAcknowledged",
    width: 280,
    render: (sitesAcknowledged: Array<string>, record: any) => {
      const { acknowledgedPercentage, sitesToBeNotified } = record;
     
      const tagColor = getPercentTagColor(acknowledgedPercentage);
      const percentVal = `${parseFloat(acknowledgedPercentage).toFixed(0)}%`;
      const value = `${String(sitesAcknowledged?.length)}/${String(
        sitesToBeNotified?.length
      )}`;
      return (
        <>
          <Tag color={tagColor} className="percentTag">
            {percentVal}
          </Tag>
          <span>{value}</span>
        </>
      );
    },
  },
  {
    title: "EC/IRB Notifications Sent",
    dataIndex: "sitesNotifiedToIRB",
    key: "sitesNotifiedToIRB",
    width: 220,
    render: (sitesNotifiedToIRB: Array<string>, record: any) => {
      const { irbNotifiedPercentage, sitesToBeNotified } = record;
      const tagColor = getPercentTagColor(irbNotifiedPercentage);
      const percentVal = `${parseFloat(irbNotifiedPercentage).toFixed(0)}%`;
      const value = `${String(sitesNotifiedToIRB?.length)}/${String(
        sitesToBeNotified?.length
      )}`;
      return (
        <>
          <Tag color={tagColor} className="percentTag">
            {percentVal}
          </Tag>
          <span>{value}</span>
        </>
      );
    },
  },
  {
    title: "USDE ID",
    dataIndex: "id",
    key: "id",
    width: 130,
    render: (id: string) => {
      return <>{id || "-"}</>;
    },
  },
];

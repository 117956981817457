import styled from "styled-components";

export const Body = styled.div`
  display: grid;
  grid-auto-flow: column;
  `;
export const Container = styled.div`
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 95vh;
  overflow-y: scroll;
  padding-bottom: 3rem;
  .percentTag {
    width: 50px;
    text-align: center;
  }
`;
export const Filter = styled.div`
display: flex;
gap: 1rem;
background-color: #ffffff;
  padding: 1rem;
`;

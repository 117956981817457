import { createAsyncThunk } from "@reduxjs/toolkit";
import { tenant } from "../../../API/UserManagement/Tenant";

const getAllSponsors = createAsyncThunk(
  "user/getAllSponsors",
  async () => {
    try {
      const data = await tenant.getAll();
      return data;
    } catch (err) {
      console.log("error");
      ;
    }
  }
);

export { getAllSponsors };

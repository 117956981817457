import Styled from 'styled-components';

export const Container = Styled.div``;
export const QueryBuilderContainer = Styled.div`
  .ruleGroup-header .ruleGroup-combinators {
    border-radius: 5px;
    padding: 1px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
  }
  .ruleGroup-addRule {
    background-color: #dd2515;
    color: #fff;
    border: 1px solid #dd2515;
    padding: 0 0.5rem;
    border-radius: 5px;
    cursor: pointer;
  }
  .rule-remove, .ruleGroup-remove {
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding-bottom: 2.5px;
    cursor: pointer;
    border: 1px solid #d9d9d9;
  }
  .ruleGroup-addGroup {
    background-color: #dd2515;
    color: #fff;
    border: 1px solid #dd2515;
    padding: 0 0.5rem;
    border-radius: 5px;
    cursor: pointer;
  }
  .rule-fields {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
  }
  .rule-operators {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    width: 50px;
  }
  .rule-value {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
  }
  .ruleGroup {
    background-color: #ffffff;
    border: 1px solid #ffffff;
    padding: 0.5rem;
  }
  .queryBuilder {
    border-radius: 5px;
  }
`;
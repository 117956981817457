import * as Styles from "./HeaderStyles";
import Logo from "../../Assets/Icons/Logo.svg";
import { Avatar, Card, Modal, Select } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { updateSubMenuActiveId } from "../../slices/SideMenu/SideMenuSlices";
import { auth } from "../../API/Auth";
import { workspaceChange } from "../../Routes/Workspace/Constant";
import { loadState } from "../../sessionstorage";

function Header() {
  const navigate = useNavigate();
  const { Meta } = Card;
  const dispatch = useAppDispatch();
  const sessionData = useAppSelector((state) => state.session);
  const selectedTenant = sessionData.selectedTenant;

  const location = useLocation();
  const currentPath = location.pathname;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [switchModalOpen, setSwitchModalOpen] = useState(false);
  const [workspaces, setWorkspaces] = useState<any>([]);
  const [dataId, setDataId] = useState();

  const [selectedWorkspace, setSelectedWorkspace] = useState<any>(
    sessionData.selectedTenant || {}
  );

  useEffect(() => {
    const sessionData = loadState()?.session || {};
    const selectedTenant = sessionData.selectedTenant;
    setSelectedWorkspace(selectedTenant || {});
  }, [currentPath, selectedTenant]);
  const showSwitchModal = (id: any) => {
    setSwitchModalOpen(true);
    setDataId(id);
  };

  const handleLogoutConfirmClicks = async () => {
    setIsModalOpen(false);
    await handleLogoutClick();
  };

  const handleLogoutClick = async () => {
    await auth.logout();

    sessionStorage.clear();
    dispatch(updateSubMenuActiveId([]));
    navigate("/");
  };

  const handleButtonClick = () => {
    handleWorkspaceChange(dataId);
    setSwitchModalOpen(false);
  };

  const onClose = () => {
    setShowProfile(false);
  };

  const userDetails = sessionData.userData;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClose]);

  useEffect(() => {
    const userData = sessionData.userData;

    if (Object.keys(userData).length > 0) {
      const uniqueWorkspaces = Object.values(
        userData?.tenants?.reduce((acc: any, item: any) => {
          acc[item.id] = item;
          return acc;
        }, {})
      );
      setWorkspaces(uniqueWorkspaces);
    }
  }, []);

  const useDetectClick = (ref: any, handlerFunc: () => void) => {
    useEffect(() => {
      const checkIfClickOutside = (e: any) => {
        if (ref && ref.current && !ref.current.contains(e.target)) {
          handlerFunc();
        }

        document.addEventListener("mousedown", checkIfClickOutside);

        return () => {
          document.removeEventListener("mousedown", checkIfClickOutside);
        };
      };
    }, [ref, handlerFunc]);
  };

  useDetectClick(ref, () => setShowProfile(false));

  const { Option } = Select;

  async function handleWorkspaceChange(id: any) {
    const data = await workspaceChange(id, sessionData);
    if (data && Object.keys(data).length) {
      const { endPoint } = data;

      window.location.href = endPoint ?? "";
    }
  }

  return (
    <Styles.Container>
      <img src={Logo} alt="logo" />
      <div className="options">
        <Modal
          open={isModalOpen}
          onOk={handleLogoutConfirmClicks}
          okText="Yes"
          onCancel={() => {
            setIsModalOpen(false);
          }}
          centered
        >
          Are you sure you want to logout?
        </Modal>

        <Modal
          open={switchModalOpen}
          onOk={handleButtonClick}
          okText="Yes"
          onCancel={() => {
            setSwitchModalOpen(false);
          }}
          centered
        >
          Are you sure you want to switch the workspace?
        </Modal>

        <div>
          <div>
            <Select
              className="my-select"
              placeholder="Workspace"
              onChange={(workspaceId: any) => {
                showSwitchModal(workspaceId);
              }}
              defaultValue={selectedWorkspace?.name}
              value={selectedWorkspace?.name}
              style={{ width: "100%" }}
            >
              {workspaces.map((workspace: any) => (
                <Option key={workspace.id} value={workspace.id}>
                  {workspace.name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <Avatar
          icon={<UserOutlined />}
          onClick={() => {
            setShowProfile(true);
          }}
        />

        {showProfile ? (
          <div ref={ref}>
            <Card
              style={{
                position: "absolute",
                right: "0",
                marginRight: "1rem",
                zIndex: "99",
              }}
              actions={[
                <LogoutOutlined
                  label="logout"
                  onClick={() => setIsModalOpen(true)}
                />,
              ]}
            >
              <Meta avatar={<UserOutlined />} title={userDetails?.name} />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "1rem",
                }}
              >
                <p>{userDetails?.email}</p>
                <p>{userDetails?.status === 1 ? "Active" : "Inactive"}</p>
              </div>
            </Card>
          </div>
        ) : (
          ""
        )}
      </div>
    </Styles.Container>
  );
}

export default Header;

import * as styles from "./CommonLoginStyles";
import { LoginForm } from "../../Components";

function CommonLogin() {
  return (
    <styles.Container>
      <LoginForm />
    </styles.Container>
  );
}

export default CommonLogin;

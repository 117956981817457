import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  height: 100vh;
  gap: 0.5rem;
  position: relative;
  transition-duration: 120ms;
  .menu {
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .toggleBtn {
    position: absolute;
    z-index: 1000;
    right: -10px;
    font-size: 25px;
    background-color: white;
    border-radius: 50%;
    color: #4c4c4c;
    top: 2px;
  }
`;
export const MenuFooter = styled.div`
  text-align: center;
  padding-bottom: 4.5rem;
  width: 230px;
  h5 {
    margin: 0;
    span {
      color: #dd2515;
    }
  }
`;
export const FooterLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  h5 {
    color: #1890ff;
  }
`;

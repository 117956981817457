import { Form, Drawer, Switch } from "antd";
import { UserRoleMatrix } from "./RoleMatrix/UserRoleMatrix";
import { useAppDispatch } from "../../app/hooks";
import { useEffect, useState } from "react";
import { user } from "../../API/UserManagement/User";
import { getUsers } from "../../slices/User/Helpers/GetAllUsers";
import ReusableForm from "../../unity-ui-common/Components/ReForm";
import ReInput from "../../unity-ui-common/Components/ReFormFields/ReInput";
import ReSelect from "../../unity-ui-common/Components/ReFormFields/ReSelect";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import { role } from "../../API/UserManagement/Role";
import { tenant } from "../../API/UserManagement/Tenant";
import { GetRoleName } from "../../Helper/Methods";
import { ICreateUser } from "../../Interfaces/Apis/User.interface";

export const UserCreationForm = (props: {
  drawerType: string;
  visibility: boolean;
  setUserDrawerType: Function;
  setUserDrawerVisibility: Function;
  currEditingUser: any;
}) => {
  const [form] = Form.useForm();
  const {
    drawerType,
    visibility,
    setUserDrawerType,
    setUserDrawerVisibility,
    currEditingUser,
  } = props;
  const dispatch = useAppDispatch();
  const initialFormValues = {
    name: "",
    email: "",
    phoneNumber: "",
    roleMatrices: [{ roles: [""], tenant: "" }],
    status: 1,
  };

  const [selectedRoles, setSelectedRoles] = useState<any[]>([]);
  const [selectedSponsors, setSelectedSponsors] = useState<any[]>([]);
  const [tempUser, setTempUser] = useState<ICreateUser>(initialFormValues);
  const [allRoles, setAllRoles] = useState([]);
  const [allSponsors, setAllSponsors] = useState([]);

  const handleSelectChanges = (type: string, value: string[]) => {
    const data = value.map((id: string) => ({
      name: GetRoleName(id, type === "role" ? allRoles : allSponsors),
      value: id,
    }));
    if (type === "role") {
      setSelectedRoles(data);
    } else {
      setSelectedSponsors(data);
      const allIds = data.map((ele) => ele.value);
      const filtered = tempUser.roleMatrices.filter((mt) =>
        allIds.includes(mt.tenant)
      );
      setTempUser((prevData: any) => {
        return {
          ...prevData,
          roleMatrices: filtered,
        };
      });
    }
  };

  const onClose = () => {
    setUserDrawerType("create");
    setUserDrawerVisibility(false);
    form.resetFields();
  };

  const handleFormSubmit = async (values: any) => {
    if (values?.phoneNumber && values?.phoneNumber?.toString()?.length !== 10) {
      ReNotification({
        description: "Enter A Valid Phone Number",
        type: "error",
      });
      return;
    }
    const payload = {
      name: values?.name,
      email: values?.email?.trim()?.toLowerCase() || "",
      phoneNumber: values.phoneNumber?.toString() || "",
      roleMatrices: tempUser?.roleMatrices,
      status: tempUser?.status,
    };
    if (drawerType === "create") {
      let res = await user.create(payload);
      if (res?.statusCode > 300 || res.status === false) {
        ReNotification({
          description: res?.message || "Something went wrong",
          type: "error",
        });
        return;
      }

      ReNotification({
        description: "User Created Successfully",
        type: "success",
      });

      setUserDrawerType("create");
      setUserDrawerVisibility(false);

      dispatch(getUsers());
    } else {
      
      let res = await user.update(currEditingUser.id, payload);
      res = await res?.json();
      if (res?.statusCode > 300) {
        ReNotification({
          description: res?.message || "Something went wrong",
          type: "error",
        });
        return;
      }

      dispatch(getUsers());
      ReNotification({
        description: "User Updated Successfully",
        type: "success",
      });

      setUserDrawerType("create");
      setUserDrawerVisibility(false);
    }
  };

  const getRoles = async () => {
    const res = await role.getAll();
    setAllRoles(res);
  };
  const getSponsors = async () => {
    const res = await tenant.getAll();
    setAllSponsors(res);
  };

  useEffect(() => {
    getRoles();
    getSponsors();
  }, []);

  useEffect(() => {
    if (drawerType === "edit") {
      // findinig already have sponsors
      const alreadySelectedSponsors = currEditingUser.roleMatrices.map(
        (ele: any) => ({
          name: GetRoleName(ele.tenant, allSponsors),
          value: ele.tenant,
        })
      );
      setSelectedSponsors(alreadySelectedSponsors);
      // findinig already have roles
      const uniqueRoleIds: any = [];
      currEditingUser.roleMatrices.map((ele: any) => {
        return ele.roles.map((id: string) => {
          if (!uniqueRoleIds.includes(id)) {
            uniqueRoleIds.push(id);
          }
        });
      });
      uniqueRoleIds.map((id: string) => ({
        name: GetRoleName(id, allRoles),
        value: id,
      }));
      const alreadySelectedRoles = uniqueRoleIds.map((id: string) => ({
        name: GetRoleName(id, allRoles),
        value: id,
      }));
      setSelectedRoles(alreadySelectedRoles);
      setTempUser({
        name: currEditingUser?.name,
        email: currEditingUser?.email,
        phoneNumber: currEditingUser?.phoneNumber,
        roleMatrices: currEditingUser?.roleMatrices,
        status: currEditingUser?.status,
      });
      form.setFieldsValue({
        name: currEditingUser?.name,
        email: currEditingUser?.email,
        phoneNumber: currEditingUser?.phoneNumber,
        roleMatrices: currEditingUser?.roleMatrices,
        status: currEditingUser?.status,
        role: alreadySelectedRoles,
        sponsor: alreadySelectedSponsors,
      });
    } else {
      setTempUser(initialFormValues);
      setSelectedRoles([]);
      setSelectedSponsors([]);
    }
  }, [drawerType]);

  return (
    <div className="common-flex-row justify-end user-creation-form">
      <Drawer
        placement="right"
        onClose={onClose}
        open={visibility}
        width="1000"
        title={drawerType === "edit" ? "Edit User" : "Create User"}
      >
        <ReusableForm
          formInstance={form}
          onSubmit={handleFormSubmit}
          className="form"
          submitBtn
          submitBtnText={drawerType === "edit" ? "Update" : "Create"}
        >
          <ReInput label="Name" name="name" type="simple" required />
          <ReInput label="Email ID" name="email" type="email" required />
          <ReInput label="Phone Number" name="phoneNumber" type="number" />
          <ReSelect
            label="Sponsor/MAH"
            name="sponsor"
            type="multiple"
            items={allSponsors?.map((sp: any) => {
              return {
                title: sp.name,
                value: sp.id,
                disable: sp.status === 0 || false,
              };
            })}
            onChange={(value: any) => {
              handleSelectChanges("sponsor", value);
            }}
            required
            searchable
          />
          <ReSelect
            label="Role"
            name="role"
            type="multiple"
            items={allRoles?.map((rol: any) => {
              return {
                title: rol.name,
                value: rol.id,
                disable: rol.status === 0 || false,
              };
            })}
            onChange={(value: any) => {
              handleSelectChanges("role", value);
            }}
            required
            searchable
          />

          <Form.Item label="Status" rules={[{ required: true }]} required>
            <Switch
              checked={tempUser?.status === 1}
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              onChange={(checked) => {
                setTempUser((prevData) => ({
                  ...prevData,
                  status: checked ? 1 : 0,
                }));
              }}
            />
            <UserRoleMatrix
              selectedSponsors={selectedSponsors}
              selectedRoles={selectedRoles}
              setTempUser={setTempUser}
              tempUser={tempUser}
            />
          </Form.Item>
        </ReusableForm>
      </Drawer>
    </div>
  );
};

import { createSlice } from "@reduxjs/toolkit";

interface SessionStorageState {
  selectedTenantId: string;
  selectedTenant: Record<string, string>;
  userPermissions: string[];
  userAccessToken: string;
  refreshToken: string;
  userData: any;
}

const initialState: SessionStorageState = {
  selectedTenantId: "",
  selectedTenant: {},
  userPermissions: [],
  userAccessToken: "",
  refreshToken: "",
  userData: {},
};

const SessionStorageSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    handleSessionUpdate: (state, action) => {
      state.selectedTenantId =
        action.payload?.selectedTenantId || state.selectedTenantId;
      state.selectedTenant =
        action.payload?.selectedTenant || state.selectedTenant;
      state.userPermissions =
        action.payload?.userPermissions || state.userPermissions;

      state.userAccessToken =
        action.payload?.userAccessToken || state.userAccessToken;
      state.refreshToken = action.payload?.refreshToken || state.refreshToken;
      state.userData = action.payload?.userData || state.userData;
    },
  },
});

export const { handleSessionUpdate } = SessionStorageSlice.actions;
export default SessionStorageSlice.reducer;

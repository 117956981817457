import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Styles from "./RuleBuilderStyle";
import "react-querybuilder/dist/query-builder.scss";
import "react-querybuilder/dist/query-builder.css";
import RuleBuilderForm from "../../Components/RuleBuilderForm/RuleBuilderForm";
import { useEffect, useState } from "react";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import { ruleEngine } from "../../API/RuleEngine";

const RuleBuilder = () => {
  const { ruleId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [view, setview] = useState<boolean>(false);

  const handleFormSubmit = async (values: any) => {
    let payload: any = {
      query: values.caseRuleQuery,
      caseRuleDesc: values.caseRuleDes,
      contactRule: values.contactCriteriaQuery,
      contactRuleDesc: values.contactCriteriaDes,
      name: values.name,
      dueDays: values.dueDays,
    };

    let res;
    if (ruleId) {
      res = await ruleEngine.updateRule(ruleId, payload);
      res = await res?.json();
    } else {
      res = await ruleEngine.createRule(payload);
    }

    const popupTest = `Rule ${ruleId ? "Updated" : "Created"} Successfully`;
    if (res?.status) {
      ReNotification({
        description: popupTest,
        type: "success",
      });
    } else {
      ReNotification({
        description: res?.message || "Something went wrong",
        type: "error",
      });
    }
    navigate("/reporting-rules");
  };

  useEffect(() => {
    const type = searchParams.get("type");
    if (type === "view") {
      setview(true);
    }
  }, [location]);

  return (
    <Styles.Container className="subContainer">
    <RuleBuilderForm
      type="ruleBuilder"
      ruleId={ruleId}
      onSubmit={handleFormSubmit}
      view={view}
    />
  </Styles.Container>
  );
};

export default RuleBuilder;

import QcCofigurationDrawer from "./QcCofigurationDrawer/QcCofigurationDrawer";
import ReTable from "../../../unity-ui-common/Components/ReTable/ReTable";
import { Space, Tag, Tooltip } from "antd";
import { useState } from "react";
import { SponserCreationForm } from "../../Drawers/SponserCreationForm";
import { API_URL } from "../../../API/Config";
import * as Styles from "./ManageSponsorsStyles";
import {
  EditOutlined,
  SettingOutlined,
  UserAddOutlined,
} from "@ant-design/icons";

function ManageSponsors() {
  const [selectedTableRow, setSelectedTableRow] = useState<string[]>([]);
  const [currEditingSponor, setCurrEditingSponor] = useState({});
  const [sponsorDrawerVisibility, setSponsorDrawerVisibility] =
    useState<boolean>(false);
  const [sponsorDrawerType, setSponsorDrawerType] = useState<string>("");
  const [qcDrawerVisibility, setQcDrawerVisibility] = useState<boolean>(false);

  const columns = [
    {
      title: "",
      width: 60,
      render: (record: any) => {
        return (
          <>
            {selectedTableRow.length !== 0 ? (
              ""
            ) : (
              <Space size="middle">
                <Tooltip title="Edit">
                  <EditOutlined
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setCurrEditingSponor(record);

                      setSponsorDrawerVisibility(true);
                      setSponsorDrawerType("edit");
                    }}
                  />
                </Tooltip>
                <Tooltip title="configuration">
                  <SettingOutlined
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setQcDrawerVisibility(true);
                      setCurrEditingSponor(record);
                    }}
                  />
                </Tooltip>
              </Space>
            )}
          </>
        );
      },
    },
    {
      title: "Sponsor/MAH Name",
      dataIndex: "name",
      width: 300,
    },
    {
      title: "Sponsor/MAH Unit",
      width: 200,
      dataIndex: "abbreviation",
    },
    {
      title: "Email ID",
      dataIndex: "email",
      width: 250,
    },
    {
      title: "Country",
      width: 200,
      dataIndex: "country",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 150,
      render: (record: any) => {
        const text = record === 1 ? "Active" : "Inactive";
        const color = record === 1 ? "green" : "red";
        return (
          <Tag color={color} title="status">
            {text}
          </Tag>
        );
      },
    },
  ];

  // const navigate = useNavigate();
  // const location = useLocation();
  // const urlQuery = new URLSearchParams(location.search);

  // useEffect(() => {
  //   navigate("/user-management/sponsors");

  //   // const decrypted = await SecureDataService.decrypt(
  //   //   urlQuery.get(
  //   //     `${"All Sponsors/MAHs"?.replace(/\s+/g, "")?.toLocaleLowerCase()}PaginationData`
  //   //   )
  //   // );
  //   // setPaginationCurrentChangesProps(JSON.parse(decrypted));
  //   const urlSearchParams = "?" + decodeURIComponent(urlQuery.toString());
  //   console.log("im inside");

  //   navigate(`${location.pathname}${urlSearchParams}`);
  // }, []);

  return (
    <Styles.Container>
      <SponserCreationForm
        drawerType={sponsorDrawerType}
        visibility={sponsorDrawerVisibility}
        setSponsorDrawerType={setSponsorDrawerType}
        setSponsorDrawerVisibility={setSponsorDrawerVisibility}
        currEditingSponor={currEditingSponor}
        setCurrEditingSponor={setCurrEditingSponor}
      />
      <ReTable
        name="All Sponsors/MAHs"
        title="All Sponsors/MAHs"
        columns={columns}
        refresh={sponsorDrawerVisibility || qcDrawerVisibility}
        scroll={{
          x: 800,
          y: 500,
        }}
        rowSelection={{
          selectedRowKeys: selectedTableRow,
          type: "checkbox",
          setSelectedRowKeys: setSelectedTableRow,
          rowKey: "id",
        }}
        uniView={{
          uniView: "tenant",
          viewName: "all",
          apiUrl: API_URL,
          option: {
            orderBy: {
              key: "name",
              order: "ASC",
            },
            limit: 10,
          },
        }}
        exportOption={{
          csv: {},
        }}
        headerButtons={[
          {
            title: "Add New",
            icon: <UserAddOutlined />,
            onClick: () => {
              setSponsorDrawerVisibility(true);
              setSponsorDrawerType("create");
            },
          },
        ]}
      />
      <QcCofigurationDrawer
        visibility={qcDrawerVisibility}
        setVisibility={setQcDrawerVisibility}
        currEditingSponor={currEditingSponor}
        setCurrEditingSponor={setCurrEditingSponor}
      />
    </Styles.Container>
  );
}

export default ManageSponsors;

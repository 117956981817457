import styled from "styled-components";

export const Container = styled.div`
  background-color: #ffffff;
  padding: 1rem;
  display: grid;
  align-items: center;
`;
export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  h3 {
    margin: 0;
  }
`;
export const ModalBody = styled.div`
  h4 {
    color: gray;
    margin-bottom: 0;
  }
`;
export const RadioBtns = styled.div`
  margin-top: 1rem;
  display: grid;
  gap: 1rem;
  p {
    margin: 0;
  }
  h5 {
    margin: 0;
    font-style: italic;
    color: gray;
  }
`;
export const ModalFooter = styled.div`
  text-align: left;
`;
export const Indicator = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Label = styled.div`
  margin-top: 1rem;
  font-weight: 600;
`;
export const TextEditorContainer = styled.div`
  .ck-editor__editable_inline {
    min-height: 200px;
    padding: 0 1.5rem;
  }
  .ck.ck-editor {
  }
`;
export const ExtraInfoContainer = styled.div`
  position: absolute;
  top: 7.5rem;
  left: 2rem;
  background: white;
  padding: 1rem;
`;

export const AlertContainer = styled.div`
  margin-bottom: 8px;
`;

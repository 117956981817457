interface IOperationType {
  title: string;
  value: boolean | string | number;
}

export const operationTypeForConfiguration: IOperationType[] = [
  {
    title: "Add",
    value: 1,
  },
  {
    title: "Update",
    value: 2,
  },
  {
    title: "Delete",
    value: 3,
  },
];

export const operationTypeForSafetyDocument: IOperationType[] = [
  {
    title: "New",
    value: 6,
  },
  {
    title: "Update",
    value: 2,
  },
  {
    title: "Acknowledge Pending",
    value: false,
  },
  {
    title: "Acknowledge Received",
    value: true,
  },
];

interface OperationTypeMapping {
  [key: number]: string;
}
export const operationTypeMapping: OperationTypeMapping = {
  1: "Add",
  2: "Update",
  3: "Delete",
  6: "New",
  7: "Acknowledge Pending",
  8: "Acknowledge Received",
};

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 0.5rem;
  display: flex;
  gap: 0.5rem;
`;

export const Left = styled.div`
  min-width: 500px;
  width: 40%;
  .smallCards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }
`;
export const Right = styled.div`
  width: 60%;
  position: relative;

  .contact-us-form{
    width: 100%;
    .upload-btn{
      max-width: 300px;
    }
  }
  .close{
    position: absolute;
    top: 6px;
    right: 9px;
    font-size: 1.3rem;
    cursor: pointer;
    padding: 5px;
  }
`;
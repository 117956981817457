import TextArea from "antd/lib/input/TextArea";
import parse from 'html-react-parser'

function IrbNotificationTemplate(props: {
  distribution: any;
  report: any;
  additionalText: string;
  coverText: any;
  onAdditionalTextChange: any;
  notificationAckTemplate?: string | null
  showAdditionalText: any;
  notificationTemplate?: string | null
}) {
  const {
    distribution,
    report,
    coverText,
    onAdditionalTextChange,
    showAdditionalText,
    notificationTemplate
  } = props;
  const {
    type,
    org,
    product,
    studyTitle,
    studyID,
    safetyDatabaseReportNumber,
  } = report;
  const { siteName, studyNumber } = distribution;

  const template = props.report.IRBECnotificationTemplateHtml ? props.report.IRBECnotificationTemplateHtml : notificationTemplate;
  if (coverText) {
    return (
      <div>
        {parse(coverText)}
      </div>
    )
  } else if (template) {
    return <>
      <div dangerouslySetInnerHTML={
        {
          __html: [16, 17].includes(distribution?.role)  ? 
            template.replace("<wbr></wbr>", ``) : 
            template.replace("<wbr></wbr>", `<p>${showAdditionalText}</p`)
        }} />
      {[16, 17].includes(distribution?.role)  && (
        <TextArea
          onChange={(event) => onAdditionalTextChange(event.target.value)}
          style={{ marginBottom: "1rem" }}
          showCount
          maxLength={2000}
          placeholder="Add any additional information, as required"
        />
      )}
    </>
    // Delete this code
    // return <>
    //   <div dangerouslySetInnerHTML={{ __html: template }} />
    //   {[16, 17].includes(distribution?.role) ? (
    //     <TextArea
    //       onChange={(event) => onAdditionalTextChange ? onAdditionalTextChange(event.target.value) : null}
    //       style={{ marginBottom: "1rem" }}
    //       showCount
    //       maxLength={2000}
    //       placeholder="Add any additional information, as required"
    //     />
    //   ) : (
    //     <p>{showAdditionalText}</p>
    //   )}

    // </>
  }
  else {
    return <div>
      <p>Dear {siteName || "{Site Name}"} Colleagues,</p>
      <p>
        {org} is the Sponsor of the clinical study {studyID || "{Study ID}"} -{" "}
        {studyTitle || "{Study Title}"}. {org} is required to inform EC/IRB in
        writing of any suspected, unexpected serious adverse reaction (SUSAR)
        that occurs in a study evaluating the investigational product. Soterius,
        on behalf of {org}, is providing you with a suspected unexpected serious
        adverse reaction (SUSAR) from study {studyID} for {product}.
      </p>

      {[16, 17].includes(distribution?.role) ? (
        <TextArea
          onChange={(event) => onAdditionalTextChange(event.target.value)}
          style={{ marginBottom: "1rem" }}
          showCount
          maxLength={2000}
          placeholder="Add any additional information, as required"
        />
      ) : (
        <p>{showAdditionalText}</p>
      )}

      <p>
        Enclosed, please find the following documents with regards to this{" "}
        {type} day SUSAR: {safetyDatabaseReportNumber}:
      </p>

      <p style={{ paddingLeft: "1.5rem" }}>
        <ol>
          <li>Investigator Notification Letter</li>
          <li>CIOMS/MedWatch Form </li>
        </ol>
      </p>

      <p>
        Please review the attached CIOMS/MedWatch form for this case to as per
        your internal processes and evaluate the risks and benefits of the study {studyNumber ? `(${studyNumber})` : `({Study Number})`}. Please do let us
        know if you foresee a change in, or an impact on the benefit-risk
        balance of the {studyID} study.
      </p>

      <p>
        Thank you very much and feel free to reach out to the Safety Team /
        Medical Monitor as per the Contact Information provided by {org}, should
        you have any questions.
      </p>

      <p>
        Note: Request you to kindly provide your acknowledgement of receipt of
        this communication.
      </p>

      <p>Kind regards, </p>
      <p>Soterius Safety Team</p>
      <p>On behalf of {org} </p>
    </div>
  }


}
export default IrbNotificationTemplate;

import styled from "styled-components";

export const EmailTo = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  margin: 1rem 0;
  .to {
    border: 1px solid #d9d9d9;
    padding: 2px 10px;
    border-radius: 2px;
  }
  .emails {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
    width: 100%;
    min-height: 1.8rem;
  }
`;

export const ModalBody = styled.div`
  display: grid;
  text-align: center;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
`;

import styled from "styled-components";

export const Container = styled.form`
  text-align: center;
  width: 30rem;
  img {
    width: 15rem;
  }
  h2 {
    margin: 0;
  }
  .loginForm {
    background-color: #ffffff;
    padding: 3rem;
    text-align: left;
    h3 {
      color: gray;
    }
    .Fields {
      width: 100%;
      text-align: left;
      margin-top: 1.5rem;
    }
    .timer-container {
      display: flex;
      justify-content: center;
      align-items: center;
      .resend-btn {
        color: #dd2515;
        span {
          text-decoration: underline !important;
        }
      }
    }
  }
`;

export const ExtraInfo = styled.form`
  display: grid;
  justify-content: center;
  margin-top: 0.5rem;
  span {
    font-size: 11px;
    color: gray;
  }
`;

export const ModalBody = styled.div`
display: grid;
    grid-auto-flow: column;
  .icon{
    display: flex;
    justify-content: center;
    svg{
      width: 60px;
      fill: #DD2515;
    }
  }
  p{
    margin: 0;
  }
`;

import { Container } from "../Home/HomeStyles";
import { Header, SideMenu } from "../../Components";
import ReDatePicker from "../../unity-ui-common/Components/ReFormFields/ReDatePicker";
import ReusableForm from "../../unity-ui-common/Components/ReForm";
import { Card, Form } from "antd";
import { Uniview } from "../../API/Uniview";
export default function ConsolidatedReport() {
  const [form] = Form.useForm();
  async function generateConsolidatedReport(dateRange: any) {
    const univiewService = new Uniview("distributions", "consolidatedReport");
    const start = new Date(dateRange?.[0]._d);
    const end = new Date(dateRange?.[1]._d);
    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 59);
    dateRange = {
      ">": start,
      "<": end,
    };
    await univiewService.generateCSV(
      {},
      {},
      { day0: dateRange },
      "Cumulative Raw Data Report"
    );
  }
  async function handleFormSubmitButtonClicks(values: any) {
    generateConsolidatedReport(values?.day0);
  }

  return (
    <Container>
      <Card
        title={
          <h5 style={{ marginBottom: "0" }}>Cumulative Raw Data Report</h5>
        }
        bordered={false}
        style={{ width: 500, height: 230 }}
      >
        <ReusableForm
          formInstance={form}
          onSubmit={handleFormSubmitButtonClicks}
          submitBtn
          submitBtnText="Generate"
        >
          <ReDatePicker
            label="Day 0"
            name="day0"
            type="rangePicker"
            dateFormat="DD-MMM-YYYY"
            width="100%"
            featureDates
            required
          />
        </ReusableForm>
      </Card>
    </Container>
  );
}

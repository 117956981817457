import { Descriptions } from "antd";

const styles = {
  backgroundColor: "#f5f5f5",
  fontFamily: "monospace",
  padding: "0.5rem",
}

function RulebuilderQueryDes(props: { ruleData: any }) {

  const { ruleData } = props;

  return (
    <div>
      <Descriptions title="If" layout="vertical">
        <Descriptions.Item label="Case Criteria" style={styles}>
          {/* {ruleData.caseRuleDesc ? <ColoredParentheses inputString={replaceRoleAndCountryNames(ruleData.caseRuleDesc)} /> : "NA"} */}
          {ruleData.caseRuleDesc ? ruleData.caseRuleDesc : "NA"}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title="Then" layout="vertical">
        <Descriptions.Item label="Contact Criteria" style={styles}>
          {/* {ruleData.contactRuleDesc ? <ColoredParentheses inputString={replaceRoleAndCountryNames(ruleData.contactRuleDesc)} /> : "NA"} */}
          {ruleData.contactRuleDesc ? ruleData.contactRuleDesc : "NA"}
        </Descriptions.Item>
      </Descriptions>
      {ruleData.dueDays && <Descriptions title="" layout="vertical" style={{ margin: "1rem 0" }}>
        <Descriptions.Item label="Reporting Due Date">
          Day 0 + {ruleData.dueDays} CD
        </Descriptions.Item>
      </Descriptions>}
    </div>
  );
}

export default RulebuilderQueryDes;

import { useEffect, useState } from "react";
import * as Styles from "./RuleRepositoryStyles";
import { convertDateInReadableFormat } from "../../Helper/Methods";
import {
  EditOutlined,
  CopyOutlined,
  PlusOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { Space, Button, Input } from "antd";
import RulebuilderQueryDes from "../../Components/RulebuilderQueryDes/RulebuilderQueryDes";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import ReModal from "../../unity-ui-common/Components/ReModal";
import ReTab from "../../unity-ui-common/Components/ReTab";
import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";
import { IUniView } from "../../unity-ui-common/Components/ReTable/Interface";
import { API_URL } from "../../API/Config";
import { ruleEngine } from "../../API/RuleEngine";

const RuleRepository = () => {
  const navigate = useNavigate();
  const [copyModal, setCopyModal] = useState<boolean>(false);
  const [newRuleTitle, setNewRuleTitle] = useState<string>("");
  const [submitting, setSubmitting] = useState(false);
  const [desModalVisibility, setDesModalVisibility] = useState<boolean>(false);
  const [selectedRule, setSelectedRule] = useState<any>({});
  const [activeTabValue, setActiveTabValue] = useState<string>("0");
  const [refreshReTable, setRefreshReTable] = useState<boolean>(false);
  const [selectedGlobalRulesRowKeys, setSelectedGlobalRowKeys] = useState<any>(
    []
  );
  const [selectedGlobalRulesRow, setSelectedGlobalRow] = useState<any>([]);

  const UniViewConfig: IUniView = {
    uniView: "rule",
    viewName: "all",
    apiUrl: API_URL,
    option: {
      orderBy: {
        key: "createdAt",
        order: "DESC",
      },
      limit: 10,
    },
    filter:
      activeTabValue === "0"
        ? { latest: { "=": true }, isGlobal: { "=": true } }
        : { isGlobal: { "=": false } },
  };

  const columns = [
    {
      title: "Rule Title",
      dataIndex: "name",
      key: "name",
      render: (name: string, record: any) => {
        return <Link to={`/rule-builder/${record.id}?type=view`}>{name}</Link>;
      },
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: any) => convertDateInReadableFormat(date),
    },
    {
      title: "Date Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date: any) => convertDateInReadableFormat(date),
    },
    {
      title: `${activeTabValue === "0" ? "Updated" : "Created"} By`,
      dataIndex: "updatedBy",
      key: "updatedBy",
    },
    {
      title: "Rule Description",
      dataIndex: "combinedQuery",
      render: (combinedQuery: string, record: any) => {
        return (
          <Link
            to=""
            onClick={() => {
              setDesModalVisibility(true);
              setSelectedRule(record);
            }}
          >
            <EyeOutlined /> View
          </Link>
        );
      },
    },
  ];

  const handleTableActionVisibility = () => {
    return selectedGlobalRulesRowKeys.length === 0 ||
      selectedGlobalRulesRowKeys.length >= 2
      ? true
      : false;
  };

  const handleTableActionClick = (
    type: "copy" | "edit" | "add",
    id?: string
  ) => {
    switch (type) {
      case "edit":
        navigate(`/rule-builder/${id}`);
        break;
      case "copy":
        setCopyModal(true);
        break;
      default:
        navigate(`/rule-builder`);
        break;
    }
  };

 

  const handleCreateCopy = async () => {
    setSubmitting(true);
   

    const res = await ruleEngine.createRule({
      ...selectedGlobalRulesRow[0],
      name: newRuleTitle,
    });
    if (res?.status) {
      ReNotification({
        description: "Rule Copied Successfully",
        duration: 1000,
        placement: "bottomLeft",
        type: "success",
      });
      setRefreshReTable(!refreshReTable);
      setCopyModal(false);
      setNewRuleTitle("");
    } else {
      ReNotification({
        description: res?.message,
        duration: 1000,
        placement: "bottomLeft",
        type: "error",
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    setRefreshReTable(!refreshReTable);
  }, [activeTabValue]);

  return (
    <Styles.Container className="subContainer">
      <ReModal
        title="Description"
        visibility={desModalVisibility}
        onOkay={() => {}}
        onCancel={() => {
          setDesModalVisibility(false);
        }}
        footer={false}
      >
        <RulebuilderQueryDes ruleData={selectedRule} />
      </ReModal>
      <ReModal
        title="Copy Rule"
        visibility={copyModal}
        onOkay={() => {}}
        onCancel={() => {
          setCopyModal(false);
        }}
        footer={
          <Space>
            <Button
              type="primary"
              loading={submitting}
              onClick={handleCreateCopy}
            >
              Copy
            </Button>
          </Space>
        }
      >
        <p>New Rule Title</p>
        <Input
          value={newRuleTitle}
          onChange={(e) => {
            setNewRuleTitle(e.target.value);
          }}
        />
      </ReModal>
      <ReTab
        activeKey={activeTabValue}
        onChange={(id: string) => {
          setActiveTabValue(id);
        }}
        items={[
          {
            title: "Global Rules",
            key: "0",
            children: (
              <ReTable
                refresh={refreshReTable}
                name="Reporting Rules"
                title="Reporting Rules"
                columns={columns}
                uniView={UniViewConfig}
                scroll={{
                  x: 800,
                  y: 500,
                }}
                exportOption={{
                  csv: {},
                }}
                rowSelection={{
                  rowKey: "id",
                  selectedRowKeys: selectedGlobalRulesRowKeys,
                  setSelectedRowKeys: setSelectedGlobalRowKeys,
                  type: "checkbox",
                  selectRowtype: "allData",
                  selectedRowValues: selectedGlobalRulesRow,
                  setSelectedRowValues: setSelectedGlobalRow,
                }}
                headerButtons={[
                  {
                    title: "Add New",
                    icon: <PlusOutlined />,
                    onClick: () => {
                      handleTableActionClick("add");
                    },
                  },
                  {
                    title: "Edit",
                    icon: <EditOutlined />,
                    onClick: () => {
                      handleTableActionClick(
                        "edit",
                        selectedGlobalRulesRowKeys[0]
                      );
                    },
                    disabled: handleTableActionVisibility(),
                  },
                  {
                    title: "Copy",
                    icon: <CopyOutlined />,
                    onClick: () => {
                      handleTableActionClick(
                        "copy",
                        selectedGlobalRulesRowKeys[0]
                      );
                    },
                    disabled: handleTableActionVisibility(),
                  },
                ]}
              />
            ),
          },
          {
            title: "Case Specific Rules",
            key: "1",
            children: (
              <ReTable
                refresh={refreshReTable}
                uniView={UniViewConfig}
                name="Rule Repository"
                columns={columns}
                scroll={{
                  x: 800,
                  y: 500,
                }}
              />
            ),
          },
        ]}
      />
    </Styles.Container>
  );
};

export default RuleRepository;

import { OptionGroup } from "@react-querybuilder/ts";

export const contactTypescausalty: OptionGroup[] = [
  {
    label: "Field Values",
    contactTypescausalty: [
      "Definite",
      "Highly probable",
      "No",
      "No Reasonable Possibility",
      "Not Suspected",
      "Not Related",
      "Not Reported",
      "Possible",
      "Probable",
      "Reasonable Possibility",
      "Suspected",
      "Related",
      "Uncertain",
      "Unlikely",
      "Yes",
      "Reasonable Possibility/Suspected",
    ],
  },
].map(({ label, contactTypescausalty }) => ({
  label,
  options: contactTypescausalty.map((s) => ({ name: s, label: s })),
}));

export const contactTypes: any = [
  {
    label: "Field Values",
    contactTypes: [
      {
        label: "Principal Investigator",
        name: 15,
      },
      {
        label: "Other Site Personnel",
        name: 16,
      },
      {
        label: "Regulatory Authority",
        name: 18,
      },
      {
        label: "Ethics Committee / Institutional Review Board",
        name: 17,
      },
      {
        label: "Partner",
        name: 19,
      },
    ],
  },
].map(({ label, contactTypes }) => ({
  label,
  options: contactTypes.map((s) => ({ name: s.name, label: s.label })),
}));

export const seriousCriteria: any = [
  {
    label: "Field Values",
    seriousCriteria: [
      {
        label: "Results in Death",
        name: "resultsInDeath",
      },
      {
        label: "Life Threatening",
        name: "isLifeThreatening",
      },
      {
        label: "Caused / Prolonged Hospitalisation",
        name: "requiresInpatientHospitalization",
      },
      {
        label: "Disabling / Incapacitating",
        name: "resultsInPersistentOrSignificantDisability",
      },
      {
        label: "Congenital Anomaly / Birth Defect",
        name: "congenitalAnomalyBirthDefect",
      },
      {
        label: "Other Medically Important Condition",
        name: "otherMedicallyImportantCondition",
      },
    ],
  },
].map(({ label, seriousCriteria }) => ({
  label,
  options: seriousCriteria.map((s) => ({ name: s.name, label: s.label })),
}));

import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Form } from "antd";
import * as Styles from "./ReRichTextEditorStyles";
import { config } from "./Constant";

interface Iprops {
  label: string;
  name: string;
  noStyle?: boolean;
  required?: boolean;
  disable?: boolean;
  form?: any;
}

export default function ReRichTextEditor(props: Iprops) {
  const { label, name, noStyle, required, form, disable } = props;
  const [rules, setRules] = useState<any[]>([]);

  const handleChanges = (event: any, editor: any) =>  {
    const data = editor.getData();
    form.setFieldValue(name, data);
    // console.log(data);
  };

  useEffect(() => {
    setRules(
      required
        ? [{ required: true, message: `Please Enter The ${label}` }]
        : []
    );
  }, [required, disable, form]);

  return (
    <Form.Item label={label} name={name} rules={rules} noStyle={noStyle}>
      <Styles.Container>
        <CKEditor
          config={config}
          editor={ClassicEditor}
          // onReady={(editor: any) => {
          //   console.log("Editor is ready to use!", editor);
          // }}
          onChange={handleChanges}
          // onBlur={(event: any, editor: any) => {
          //   console.log("Blur.", editor);
          // }}
          // onFocus={(event: any, editor: any) => {
          //   console.log("Focus.", editor);
          // }}
        />
      </Styles.Container>
    </Form.Item>
  );
}

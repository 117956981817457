export const UserMappingByValue = (value: string) => {
  switch (value) {
    case "15":
      return "Principal Investigator";
    case "16":
      return "Other Site Personnel";
    case "18":
      return "Regulatory Authority";
    case "17":
      return "Ethics Committee / Institutional Review Board";
    case "19":
      return "Partner";
    default:
      return "-";
  }
};

export enum permissionsEnum {
  SAFETY_TEAM_ADMIN = 12,
  SAFETY_TEAM = 13, //13
  SPONSOR = 14, //14
  PRINCIPLE_INVESTIGATOR = 15, //15
  OTHER_SITE_PERSONNEL = 16, //16
  INSTITUTIONAL_REVIEW_BOARD = 17, //17
  REGULATORY_AUTHORITY = 18, //18
  PARTNER = 19,
}
export const contactMapping = (value: number) => {
  switch (value) {
    case 15:
      return "Principal Investigator";
    case 16:
      return "Other Site Personnel";
    case 18:
      return "Regulatory Authority";
    case 17:
      return "Ethics Committee / Institutional Review Board";
    case 19:
      return "Partner";
    default:
      return "-";
  }
};

export const ReportWithInDueDateMappingByValue = (value: number) => {
  switch (value) {
    case 0:
      return "Yes";
    case 1:
      return "No";
    case 2:
      return "Not Applicable";
    default:
      return "-";
  }
};

export const seriousnessCriteriaMappingByValue = (value: string) => {
  switch (value) {
    case "resultsInDeath":
      return "Results in Death";
    case "lifeThreatening":
      return "Life Threatening";
    case "requiresInpatientHospitalization":
      return "Caused / Prolonged Hospitalisation";
    case "resultsInPersistentOrSignificantDisability":
      return "Disabling / Incapacitating";
    case "congenitalAnomalyBirthDefect":
      return "Congenital Anomaly / Birth Defect";
    case "otherMedicallyImportantCondition":
      return "Other Medically Important Condition";
    default:
      return "-";
  }
};

export const DistributionStatus: {
  [key: string]: any;
} = {
  0: {
    label: "Pending",
    color: "orange",
  },
  1: {
    label: "Sent",
    color: "blue",
  },
  2: {
    label: "Delivered",
    color: "green",
  },
  3: {
    label: "Failed",
    color: "red",
  },
};

export const AcknowledgedStatus: {
  [key: string]: any;
} = {
  0: {
    label: "No",
    color: "red",
  },
  1: {
    label: "Yes",
    color: "green",
  },
};

export const seriousnessCriteriaDropDownItems = [
  {
    key: "resultsInDeath",
    title: "Results in Death",
  },
  {
    key: "isLifeThreatening",
    title: "Life Threatening",
  },
  {
    key: "requiresInpatientHospitalization",
    title: "Caused / Prolonged Hospitalisation",
  },
  {
    key: "resultsInPersistentOrSignificantDisability",
    title: "Disabling / Incapacitating",
  },
  {
    key: "congenitalAnomalyBirthDefect",
    title: "Congenital Anomaly / Birth Defect",
  },
  {
    key: "otherMedicallyImportantCondition",
    title: "Other Medically Important Condition",
  },
  {
    key: "notApplicable",
    title: "Not Applicable",
  },
];

export const seriousnessCriteriaDropDownItemsDisplay = [
  {
    key: "Results In Death",
    title: "Results in Death",
  },
  {
    key: "Is Life Threatening",
    title: "Life Threatening",
  },
  {
    key: "Requires Inpatient Hospitalization",
    title: "Caused / Prolonged Hospitalisation",
  },
  {
    key: "Results In Persistent Or Significant Disability",
    title: "Disabling / Incapacitating",
  },
  {
    key: "Congenital Anomaly Birth Defect",
    title: "Congenital Anomaly / Birth Defect",
  },
  {
    key: "Other Medically Important Condition",
    title: "Other Medically Important Condition",
  },
  {
    key: "Not Applicable",
    title: "Not Applicable",
  },
];

export const severityDropDownItems = [
  {
    key: "1",
    title: "Mild",
  },
  {
    key: "2",
    title: "Moderate",
  },
  {
    key: "3",
    title: "Severe",
  },
  {
    key: "4",
    title: "Life Threatening",
  },
  {
    key: "5",
    title: "Death",
  },
  {
    key: "6",
    title: "Not Applicable",
  },
];

export const InvestigatorCausalityDropDownItems = [
  {
    key: "1",
    title: "Definite",
  },
  {
    key: "2",
    title: "Possible",
  },
  {
    key: "3",
    title: "Probable",
  },
  {
    key: "4",
    title: "Unlikely",
  },
  {
    key: "5",
    title: "Not Related",
  },
  {
    key: "6",
    title: "Related",
  },
  {
    key: "7",
    title: "Not Applicable",
  },
  {
    key: "8",
    title: "Yes",
  },
  {
    key: "9",
    title: "Uncertain",
  },
  {
    key: "10",
    title: "Suspected",
  },
  {
    key: "11",
    title: "Reasonable Possibility",
  },
  {
    key: "12",
    title: "Not Reported",
  },
  {
    key: "13",
    title: "Not Suspected",
  },
  {
    key: "14",
    title: "No Reasonable Possibility",
  },
  {
    key: "15",
    title: "No",
  },
  {
    key: "16",
    title: "Highly probable",
  },
  {
    key: "17",
    title: "Reasonable Possibility/Suspected",
  },
];

export const distributionsStatusMapping = [
  {
    text: "Pending",
    value: 0,
  },
  {
    text: "Sent",
    value: 1,
  },
  {
    text: "Delivered",
    value: 2,
  },
  {
    text: "Failed",
    value: 3,
  },
]
import { BaseAPI } from "./Common";
class RuleEngine extends BaseAPI {

  async import(query: string, file: any) {
    let data = {
      query,
      file
    }
    return await this.makeRequest(`import`, "post", data)
  }
  async getRules() {
    return await this.makeRequest(`getRules`, "get")
  }
  async getRuleById(ruleId: string) {
    return await this.makeRequest(`getRuleById?ruleId=${ruleId}`, "get")
  }
  async updateRule(ruleId: string, payload: any) {
    return await this.makeRequest(`${ruleId}`, "patch", payload)
  }
  async createRule(data: object) {
    return await this.makeRequest(`create`, "post", data)
  }
  async deleteRule(ruleId: string) {
    return await this.makeRequest(`${ruleId}`, "delete")
  }
  async getCountries() {
    return await this.makeRequest(`country`, "get")
  }
  async check(payload: any) {
    return await this.makeRequest(`check`, "post", payload)
  }
  async createContactRule(payload: any) {
    return await this.makeRequest(`caseSpecific`, "post", payload)
  }
  async getCaseSpecific() {
    return await this.makeRequest(`getCaseSpecific`, "get")
  }
  async contactMapper(payload: any) {
    return await this.makeRequest(`contactMapper`, "post", payload);
  }
}

export const ruleEngine = new RuleEngine("rule")
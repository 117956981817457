import { IUpdateTenant } from "../../Interfaces/Apis/Tenant.interface";
import { BaseAPI } from "../Common";

class Tenant extends BaseAPI {
  create(payload: any): Promise<any> {
    return this.makeRequest(`create`, "post", payload);
  }

  update(tenantId: string, payload: IUpdateTenant): Promise<any> {
    return this.makeRequest(`${tenantId}`, "patch", payload);
  }

  getAll(): Promise<any> {
    return this.makeRequest(`all`);
  }

  getTenant(tenantId: string): Promise<any> {
    return this.makeRequest(`${tenantId}`);
  }
}

export const tenant = new Tenant("tenant");

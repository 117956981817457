import { ProfileDetailCard } from "../../Components";
import * as styles from "./ProfileStyles";

function Profile() {
  return (
    <styles.Container>
      <ProfileDetailCard />
    </styles.Container>
  );
}

export default Profile;

import Styled from 'styled-components';

export const Container = Styled.div`
    width: 100%;
    padding: 1rem 1rem;
    padding-bottom: 3rem;
    display: flex;
    gap: 1rem;
    height: 95vh;
    overflow-y: scroll;

    .headingContainer{
      display: flex;
      justify-content: space-between;
      .btnContainer{
        display: flex;
        align-items: center;
        column-gap: 10px;
      }
    }
    .ant-form-item{
        width: 100%;
        margin-bottom: 10px;

        .ant-form-item-row{
            display: block;

            .ant-col-12{
                text-align: start;
            }
        }
    }
    .formSection1{
      display: flex;
      column-gap: 10px;
    }

    .formSection2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 10px;
    }

    .mainBody {
      width: 81%;
    }

    .helperContainer {
      position: sticky;
      inset: 0;
      min-width: 300px;
      height: 88vh;
      overflow-y: scroll;

      ::-webkit-scrollbar {
        width: 0.25vw;
        height: 0.25vw;
      }

      ::-webkit-scrollbar-thumb {
        background: #d5d5d5;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: #888;
      }
      
      .query{
        max-width: 300px;

        .customQuerryContainer{
          display: flex;
          margin-block: 23px 5px;
          
          .customQuerryDisplay{
            height: 30px;
            border: 1px solid #d5d5d5;
            display: flex;
            align-items: center;
            padding-inline: 7px;
            position: relative;
            background: white;
            overflow-x: scroll;
            width: 100%;
          
            ::-webkit-scrollbar {
              width: 0;
              height: 0;
            }
          }
          
          .copyBtn{
              min-width: 30px;
              display: grid;
              place-items: center;
              background-color: #DD2515;
              color: white;
              cursor: pointer;
            }
        }
      }
      
      .ant-collapse-content-box {
        padding: 0;
        cursor: pointer;
        .optionContainer{
          display: flex;
          align-items: center;
          border-bottom: 2px solid #d5d5d5;
            transition: 0.25s ease-in-out;
            
            :hover{
              color: #DD2515;
              border-bottom: 2px solid #DD2515;
            }

            &:last-child{
              border: none;
            }
          .options {
            padding: 10px;
            width: 100%;
          }

          .actionBtn{
            padding-block: 10px;
            min-width: 30px;
            display: grid;
            place-items: center;
          }
        }
      }
    }
`;

export const TextEditorContainer = Styled.div`
  .ck-editor__editable_inline {
    min-height: 82vh;
    padding: 0 1.5rem;
  }
  .ck.ck-editor__main>.ck-editor__editable.ck-focused{
    border-color: #DD2515;
  }
`;
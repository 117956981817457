import img from './under-construction-illustration.svg';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Styles from "./ComingSoonStyles";

function ComingSoon() {

  const { name } = useParams();
  const [pageName, setPageName] = useState(name);

  useEffect(() => {
    if(!name) return
    if(name?.includes("_")){
      const modifiedName = name.split('_').join(' ');
      setPageName(modifiedName);
    } else{
      setPageName(name);
    }
  }, [name])

  return (
    <Styles.Container>
      <div className="commingSoon">
        <p>{pageName} Page Coming Soon</p>
        <img src={img} alt="" height='450px' />
      </div>
    </Styles.Container>
  );
}

export default ComingSoon
import styled from "styled-components";

export const Container = styled.div`
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 95vh;
  overflow-y: scroll;
  padding-bottom: 3rem;
`;
export const FormInputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 1500px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media only screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 850px) {
    grid-template-columns: 1fr;
  }
`;
export const FormButtons = styled.div`
  display: flex;
  gap: 1rem;
`;

export const importModalBody = styled.div`
  display: grid;
  grid-template-columns: 2fr 0.1fr 2fr;
  justify-content: center;
  gap: 3rem;
  align-items: center;
  .auto {
    display: grid;
    gap: 1rem;
  }
  .divider {
    width: 5rem;
    height: 11rem;
  }
`;

export const CreateReportBtn = styled.form`
  margin-top: 1rem;
`;


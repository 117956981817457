import styled from "styled-components";

export const Container = styled.div`
  .percentTag {
    width: 50px;
    text-align: center;
  }
`;
// export const BodyContainer = styled.form`
//   width: 700px;
//   margin: auto;
//   background-color: #ffffff;
//   margin-top: 1rem;
//   padding: 1rem 2.5rem;
// `;

// this class is also used in sites and contact compliance page so change it carefully
export const GiantFilter = styled.div`
  .tab-container{
    padding: 1rem;
    padding-bottom: 0;
    background-color: #ffffff;
  }
  .form {
    padding: 1rem;
    background-color: #ffffff;
    position: relative;
    .hidden-worker{
      position: absolute;
      top: -55px;
      width: 19rem;
      height: 2rem;
      z-index: 1000;
      background: transparent;
    }
    .top-filter{
      display: flex;
      justify-content: space-between;
      align-items: start;
      .date-container{
        display: flex;
        column-gap: 1rem;
      }
    }
    .filterItems {
      display: flex;
      gap: 0.5rem;
      flex-wrap: wrap;
      .form-filter-component {
        width: 24%;
      }
      .ant-form-item {
        margin-bottom: 10px;
        .ant-col-12{
          width: 100%;
          max-width: 100%;
        }
      }
    }
    .filterItems > * {
      min-width: 12rem;
    }
  }
`;

export const FormInputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1rem;
  width: 60%;
  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;
export const FormButtons = styled.div`
  display: flex;
  gap: 1rem;
  align-items: end;
`;

import moment from "moment";
import ReModal from "../../unity-ui-common/Components/ReModal";
import ReDrawer from "../../unity-ui-common/Components/ReDrawer";
import ReusableForm from "../../unity-ui-common/Components/ReForm";
import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";
import ReInput from "../../unity-ui-common/Components/ReFormFields/ReInput";
import ReDatePicker from "../../unity-ui-common/Components/ReFormFields/ReDatePicker";
import RulebuilderQueryDes from "../../Components/RulebuilderQueryDes/RulebuilderQueryDes";
import EmailSafetyDocumentsDrawer from "../../Components/EmailSafetyDocumentsDrawer/EmailSafetyDocumentsDrawer";
import { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import { GiantFilter } from "../SafetyDocumentReport/SafetyDocumentReportStyles";
import { convertDateInReadableFormat } from "../../Helper/Methods";
import CreateVersionOfSafetyDocument from "../../Components/CreateVersionOfSafetyDocument/CreateVersionOfSafetyDocument";
import { CreateSafetyDocument } from "../../Components";
import {
  Button,
  Form,
  Upload,
  UploadFile,
  UploadProps,
  Radio,
  Space,
} from "antd";
import {
  PlusOutlined,
  UploadOutlined,
  ArrowLeftOutlined,
  CloseOutlined,
  MailOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { safetyDocumentColumns } from "./Constant";
import * as styles from "./SafetyDocumentStyles";
import { report } from "../../API/Report";
import { ruleEngine } from "../../API/RuleEngine";
import { file } from "../../API/File";
import { auth } from "../../API/Auth";
import { IUniView } from "../../unity-ui-common/Components/ReTable/Interface";
import { API_URL } from "../../API/Config";
import { SecureDataService } from "../../unity-ui-common/Classes/SecureData";
import { typeList } from "antd/lib/message";

function SafetyDocument() {
  const { getCurrentRole } = auth;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();
  const currentPath = location.pathname;
  const urlQuery = new URLSearchParams(location.search);

  const [typeOfImport, setTypeOfImport] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [susar, setSusar] = useState<string | null>(searchParams.get("susar"));
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [selectedFilters, setSelectedFilters] = useState<any>({});
  const [refreshReTable, setRefreshReTable] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [selectedRowData, setSelectedRowData] = useState<string[]>([]);
  const [fetchAllData, setFetchAllData] = useState(true);
  const [simpleTableLoading, setSimpleTableLoading] = useState<boolean>(false);
  const [desModalVisibility, setDesModalVisibility] = useState<boolean>(false);
  const [selectedRule, setSelectedRule] = useState<any>({});
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [filesInfo, setFilesInfo] = useState<Array<any>>([]);
  const [uploadingFile, setUploadingFile] = useState<any>(false);
  const [formData, setFormData] = useState<any>({});
  const [drawerVisibility, setDrawerVisibility] = useState<boolean>(false);
  const [matchingRules, setMatchingRules] = useState<any>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [formInitalValue, setFormInitalValue] = useState<any>({});
  const [importTypeFunVisibility, setImportTypeFunVisibility] =
    useState<boolean>(true);
  const [createNewReportForm, setCreateNewReportForm] =
    useState<boolean>(false);
  const [isMatchingRulesVisable, setIsMatchingRulesVisible] =
    useState<boolean>(false);
  const [selectedMatchingRulesRowKeys, setSelectedMatchingRulesRowKeys] =
    useState<string[]>([]);
  const [versionCreateDrawerVisibility, setVersionCreateDrawerVisibility] =
    useState<boolean>(false);
  const [
    safetyDocumentDataForVersionCreate,
    setSafetyDocumentDataForVersionCreate,
  ] = useState<any>({});
  const [
    emailSafetyDocumentsDrawerVisibility,
    setEmailSafetyDocumentsDrawerVisibility,
  ] = useState<boolean>(false);
  useEffect(() => {
    setRefreshReTable(!refreshReTable);
  }, [currentPath]);
  const createNewFilterData = (data: any) => {
    return data.map((record: any) => {
      return {
        ...record,
        lrd: convertDateInReadableFormat(record?.lrd),
        type:
          record?.type === 0 ? record.type_value : `${record.type}-day SUSAR`,
      };
    });
  };
  const UniViewConfig: IUniView = {
    uniView: "reports",
    viewName: "safetyDocument",
    apiUrl: API_URL,
    option: {
      orderBy: {
        key: "updatedAt",
        order: "DESC",
      },
      limit: 10,
    },
    filter: selectedFilters,
  };

  async function getTableData(queryObj?: any) {
    setSimpleTableLoading(true);
    await report.find(queryObj).then((data) => {
      if (susar !== "both" && susar !== "other" && susar != null) {
        const filteredData = data.filter((ele: any) => {
          return ele.type === Number(susar);
        });
        setTableData(createNewFilterData(filteredData));
      } else if (susar == "both") {
        const filteredData = data.filter((ele: any) => {
          return ele.type === 7 || ele.type === 15;
        });
        setTableData(createNewFilterData(filteredData));
      } else {
        let dataToSet: any = data || [];
        if (susar === "other") {
          dataToSet = data.filter(
            (ele: any) => ele.type !== 7 && ele.type !== 15
          );
        }
        setTableData(createNewFilterData(dataToSet));
      }
      setSimpleTableLoading(false);
      setFetchAllData(false);
    });
  }

  const setUrlParams = (pagination: any, filters: any, sorter: any) => {
    const secureDataService = new SecureDataService();
    const paginationLabel = `${"Safety Document"
      ?.replace(/\s+/g, "")
      ?.toLocaleLowerCase()}PaginationData`;
    const paginationData = secureDataService.encrypt({
      pagination,
      filters,
      sorter,
    });
    urlQuery.set(paginationLabel, paginationData);
  };
  const handleSubmit = async (values: any) => {
    let queryObj: any = {};
    let filter: any = {};

    if (values.studyID && values.studyID?.trim() != "") {
      filter.studyID = [];

      filter.studyID.push(values.studyID.trim());
      queryObj["study_id"] = values.studyID.trim();
    }
    if (values.reportNum && values.reportNum?.trim() != "") {
      filter.safetyDatabaseReportNumber = [];

      filter.safetyDatabaseReportNumber.push(values.reportNum.trim());
      queryObj["safety_database_report_number"] = values.reportNum.trim();
    }

    if (
      values.reportType ||
      (values.reportType !== "" &&
        values.reportType != null &&
        values.reportType !== undefined)
    ) {
      const type = values.reportType.trim();
      if (type == 7 || type == 15) {
        filter.type = [];

        queryObj["type"] = type;
        filter.type = [type];
      } else {
        filter.type = [];

        filter.type = [type];
        filter.typeValue = [type];
        queryObj["type_value"] = type;
      }
    }
    if (values.product) {
      filter.product = [];

      filter.product.push(values.product.trim());
      queryObj["product"] = values.product.trim();
    }
    if (values.sourceSiteID) {
      filter.sourceSiteID = [];

      filter.sourceSiteID.push(values.sourceSiteID.trim());
      queryObj["source_site_id"] = values.sourceSiteID.trim();
    }
    if (values.sourceSiteName) {
      filter.sourceSiteName = [];
      filter.sourceSiteName.push(values.sourceSiteName.trim());
      queryObj["source_site_name"] = values.sourceSiteName.trim();
    }
    if (values.dateRange?.length) {
      filter.lrd = JSON.stringify(getLrdRangeObj(values.dateRange));
      queryObj["lrd"] = JSON.stringify(getLrdRangeObj(values.dateRange));
    }
    setSelectedFilters(
      filter.lrd ? { lrd: getLrdRangeObj(values.dateRange) } : {}
    );
    setRefreshReTable(!refreshReTable);
    setUrlParams(
      {
        current: 1,
        pageSize: 10,
        total: 50,
        hideOnSinglePage: true,
        pageSizeOptions: [10, 20, 50, 100],
      },
      filter,
      {}
    );
    const urlSearchParams = "?" + decodeURIComponent(urlQuery.toString());
    navigate(`${location.pathname}${urlSearchParams}`);
    setRefreshReTable(!refreshReTable);
  };

  const clearFilter = async () => {
    navigate(`/reports`);

    form.resetFields();
    setSelectedFilters({});
    setRefreshReTable(!refreshReTable);

    // setFetchAllData(true);
  };

  const getLrdRangeObj = (dateRange: any) => {
    const start = new Date(dateRange[0]._d);
    const end = new Date(dateRange[1]._d);
    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 59);
    return {
      ">": start,
      "<": end,
    };
  };

  const uploadFile = async (fileObj: any) => {
    setUploadingFile(true);

    const filenameArr = fileObj["name"].split(".");
    const file_type = filenameArr[filenameArr.length - 1];

    const res = await file.getUrl(file_type);
    const fileName = filenameArr[0];

    await file.remoteUploadFile(res.url, fileObj);
    setUploadingFile(false);

    return {
      fileUID: fileObj.uid,
      id: res.id,
      filename: fileName,
      file_type,
    };
  };
  const uploadProps: UploadProps = {
    onRemove: (fileObj: any) => {
      const index = fileList.indexOf(fileObj);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFilesInfo((prev) =>
        prev.filter((item) => item.fileUID != fileObj.uid)
      );
    },
    beforeUpload: async (fileObj: any) => {
      setFileList([fileObj]);
      const res = await uploadFile(fileObj);
      setFilesInfo([res]);
      return true;
    },
    fileList,
    disabled: uploadingFile,
    accept: ".xml",
  };

  const setCreateNewRportFormInitialValues = (fileData: any) => {
    setFormInitalValue({
      investigatorCausality: fileData.investigatorCausality,
      sponsorCausality: fileData.sponsorCausality,
      country: fileData.country,
      product: fileData?.investigationalProduct?.join(", "),
      seriousnessCriteria: fileData.seriousCriteria,
      saeReportedTerm: fileData.saeReportedTerm?.join(", "),
      studyID: fileData.studyNumber,
      safetyDatabaseReportNumber: fileData.safetyDatabaseReportNumber,
      studyTitle: fileData.studyTitle,
      lrd: fileData.day0 ? moment(fileData.day0) : "",
      type: fileData.type,
    });
  };

  let handleAutoFillFileUpload = async () => {
    let res = await ruleEngine.import("{}", filesInfo[0]);
    if (res?.status) {
      setCreateNewRportFormInitialValues(res.data);
      setImportTypeFunVisibility(false);
      setCreateNewReportForm(true);
      setFileList([]);
    } else {
      ReNotification({
        description: res?.message,
        duration: 1000,
        placement: "bottomLeft",
        type: "error",
      });
    }
  };

  // function that handles import type, auto or manual
  const handleImportTypeChange = (e: any) => {
    const value = e.target.value;
    setTypeOfImport(value);
    if (value === 1) {
      setCreateNewReportForm(true);
      setImportTypeFunVisibility(false);
      setFormData({});
    }
  };

  // function that handles the going back screen click in drawer
  const handleDrawerRevertAndClose = () => {
    if (
      importTypeFunVisibility &&
      !createNewReportForm &&
      !isMatchingRulesVisable
    ) {
      setDrawerVisibility(false);
    } else if (
      !importTypeFunVisibility &&
      createNewReportForm &&
      !isMatchingRulesVisable
    ) {
      setCreateNewReportForm(false);
      setImportTypeFunVisibility(true);
      setTypeOfImport(0);
    } else {
      setIsMatchingRulesVisible(false);
      setCreateNewReportForm(true);
    }
  };
  const matchingRulescolumns = [
    {
      title: "Rule Title",
      dataIndex: "ruleName",
    },
    {
      title: "Rule Description",
      dataIndex: "contactRuleDesc",
      render: (contactRuleDesc: string, record: any) => {
        const text = contactRuleDesc ? (
          <Link
            to=""
            onClick={() => {
              setDesModalVisibility(true);
              setSelectedRule(record);
            }}
          >
            <EyeOutlined /> View
          </Link>
        ) : (
          "-"
        );
        return text;
      },
    },
  ];

  // functions that handles row selects
  const onSelectMatchingRulesTableRow = async (
    selectedKeys: any,
    selectedRows: any,
    info: { type: any }
  ) => {
    if (info.type === "all" && selectedMatchingRulesRowKeys.length === 0) {
      setSelectedMatchingRulesRowKeys(
        matchingRules.map((row: any) => row.ruleId)
      );
    } else if (info.type === "single") {
      setSelectedMatchingRulesRowKeys(selectedKeys);
    } else {
      setSelectedMatchingRulesRowKeys([]);
    }
  };

  const checkForMatchingRules = async (values: any) => {
    const payload = {
      investigatorCausality: values.investigatorCausality,
      sponsorCausality: values.sponsorCausality,
      country: values.country,
      studyNumber: values.studyNumber,
      investigationalProduct: values.product,
      seriousCriteria: values.seriousnessCriteria,
      saeReportedTerm: values.saeReportedTerm,
    };
    const res = await ruleEngine.check({
      e2br3data: payload,
      isOther: values.typeValue ? true : false,
    });
    if (res?.status) {
      setCreateNewReportForm(false);
      setIsMatchingRulesVisible(true);
      setMatchingRules(res.data);
      setSelectedMatchingRulesRowKeys(res.data.map((row: any) => row.ruleId)); // by default all rows selected
    }
  };

  // functions that handle new report create form submits
  const handleFormSubmit = async (values: any) => {
    setFormData(values);
    setFormInitalValue(values);
    checkForMatchingRules(values);
  };

  const handleCreateReportClicks = async () => {
    // now create a api call for creation
    setIsSubmitting(true);
    delete formData?.severity;

    const create = await report.create(formData, selectedMatchingRulesRowKeys);
    if (create) {
      navigate(`/reports/view/${create.id}`);
    }
    setIsSubmitting(false);
  };

  const getSafetyDocumentData = async (id: string) => {
    return await report.findOne(id);
  };

  const handleCreateVesionButtonClicks = async (id: string) => {
    const res = await getSafetyDocumentData(id);
    setSafetyDocumentDataForVersionCreate(res);
    setVersionCreateDrawerVisibility(true);
  };

  const createNewColumns = (columns: any) => {
    return [
      ...columns.filter((column: any) => {
        return (
          (column.title !== "Sponsor" && column.title !== "Action") ||
          getCurrentRole() !== 2
        );
      }),
      {
        title: "Action",
        dataIndex: "id",
        width: 180,
        render: (id: string) => (
          <Space direction="horizontal">
            <Link to={"/reports/view/" + id}>View</Link>
            <span> | </span>
            <Button
              size="small"
              style={{ padding: "0" }}
              type="link"
              onClick={() => {
                handleCreateVesionButtonClicks(id);
              }}
            >
              Create Version
            </Button>
          </Space>
        ),
      },
    ];
  };

  useEffect(() => {
    setRefreshReTable(!refreshReTable);
  }, []);

  return (
    <styles.Container>
      <GiantFilter>
        <ReusableForm
          className="form"
          formInstance={form}
          onSubmit={handleSubmit}
        >
          <div className="filterItems">
            <ReInput
              name="reportNum"
              label="Safety Document ID"
              type="simple"
              placeholder="Ex. IMP145-3214"
            />
            <ReInput
              name="reportType"
              label="Safety Document Type"
              type="simple"
              placeholder="7"
            />
            <ReDatePicker
              label="Day 0"
              name="dateRange"
              dateFormat="DD-MMM-YYYY"
              featureDates
              type="rangePicker"
              width="100%"
            />
            <ReInput
              label="Product"
              name="product"
              type="simple"
              placeholder="Ex. IMP145"
            />
            <ReInput
              label="Study Number"
              name="studyID"
              type="simple"
              placeholder="Ex. Study Number"
            />
            {/* {(auth.getUsersPrivileges().includes("3") ||
                  auth.getUsersPrivileges().includes("4")) && (
                  <ReSelect
                    label="Sponsor"
                    name="org"
                    items={allSponsors?.map((ele: any) => {
                      return {
                        value: ele.key,
                        title: ele.title,
                      };
                    })}
                  />
                )} */}
            <ReInput
              label="Site Number"
              name="sourceSiteID"
              type="simple"
              placeholder="Ex. Reference/Site Number"
            />
            <ReInput
              label="Site Name"
              name="sourceSiteName"
              type="simple"
              placeholder="Ex. Name"
            />
            {/* <ReInput
                  label="Investigator Name"
                  name="siteContactName"
                  type="simple"
                  placeholder="Ex. Investigator Name"
                /> */}
          </div>
          <styles.FormButtons>
            <Form.Item noStyle>
              <Button
                type="primary"
                size="middle"
                htmlType="submit"
                // disabled={
                //   studyID ||
                //   sourceSiteID ||
                //   sourceSiteName ||
                //   reportNum ||
                //   org ||
                //   dateRange.length ||
                //   product
                //     ? false
                //     : true
                // }
              >
                Filter Report
              </Button>
            </Form.Item>
            <Form.Item noStyle>
              <Button size="middle" onClick={clearFilter}>
                Clear Filter
              </Button>
            </Form.Item>
          </styles.FormButtons>
        </ReusableForm>
      </GiantFilter>
      <ReTable
        columns={createNewColumns(safetyDocumentColumns)}
        // data={tableData}
        // loading={simpleTableLoading}
        refresh={refreshReTable}
        name="Safety Document"
        title="Safety Document"
        uniView={UniViewConfig}
        columnOptions={{
          sorting: {
            columns: [
              "safetyDatabaseReportNumber",
              "lrd",
              "product",
              "studyID",
              "type",
              "sourceSiteID",
              "sourceSiteName",
              "id",
            ],
          },
          filter: {
            columns: [
              "safetyDatabaseReportNumber",
              // "lrd",
              "product",
              "studyID",
              "type",
              "sourceSiteID",
              "sourceSiteName",
              "id",
            ],
          },
        }}
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          setSelectedRowKeys: setSelectedRowKeys,
          type: "checkbox",
          rowKey: "id",
          selectRowtype: "allData",
          selectedRowValues: selectedRowData,
          setSelectedRowValues: setSelectedRowData,
        }}
        scroll={{
          x: 2500,
          y: 800,
        }}
        headerButtons={[
          {
            title: "Add New Safety Document",
            icon: <PlusOutlined />,
            onClick: () => {
              setDrawerVisibility(!drawerVisibility);
            },
            visibility: auth.isPermissionExists(["0"]),
          },
          {
            title: "Email Safety Documents",
            icon: <MailOutlined />,
            onClick: () => {
              setEmailSafetyDocumentsDrawerVisibility(
                !emailSafetyDocumentsDrawerVisibility
              );
            },
            visibility: auth.isPermissionExists(["0"]),
            disabled: !selectedRowKeys.length,
          },
        ]}
        exportOption={{
          csv: {},
        }}
      />
      <ReDrawer
        visibility={drawerVisibility}
        width={750}
        closable={false}
        title={
          <div>
            {importTypeFunVisibility &&
            !createNewReportForm &&
            !isMatchingRulesVisable ? (
              <CloseOutlined
                onClick={handleDrawerRevertAndClose}
                style={{
                  fontSize: "18px",
                  color: "black",
                  marginRight: "0.5rem",
                }}
              />
            ) : (
              <ArrowLeftOutlined
                onClick={handleDrawerRevertAndClose}
                style={{
                  fontSize: "18px",
                  color: "black",
                  marginRight: "0.5rem",
                }}
              />
            )}

            <span>New Safety Document</span>
          </div>
        }
      >
        {importTypeFunVisibility && (
          <Space direction="vertical" size="large">
            <Radio.Group value={typeOfImport} onChange={handleImportTypeChange}>
              <Radio value={0}>Upload XML</Radio>
              <Radio value={1}>Create Document</Radio>
            </Radio.Group>
            <Upload {...uploadProps}>
              <Button
                disabled={typeOfImport === 0 ? false : true}
                size="middle"
                icon={<UploadOutlined />}
              >
                Upload File
              </Button>
            </Upload>
            <Button
              type="primary"
              size="middle"
              onClick={handleAutoFillFileUpload}
              disabled={
                typeOfImport === 0 && fileList.length !== 0 && !uploadingFile
                  ? false
                  : true
              }
            >
              Submit
            </Button>
          </Space>
        )}
        {createNewReportForm && (
          <CreateSafetyDocument
            initialData={formInitalValue}
            onFormSubmit={handleFormSubmit}
            submitButtonText="Next"
            type="new"
          />
        )}
        {isMatchingRulesVisable && (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <p>
                  <span>Safety Document Type: </span>
                  {formData?.type === 0
                    ? formData.typeValue
                    : `${formData.type}-day SUSAR`}
                </p>
                <p>
                  <span>Safety Document ID: </span>
                  {formData?.safetyDatabaseReportNumber
                    ? formData.safetyDatabaseReportNumber
                    : "-"}
                </p>
              </div>
              <p>
                <span>Day 0: </span>
                {formData?.lrd
                  ? convertDateInReadableFormat(formData?.lrd)
                  : "-"}
              </p>
            </div>
            <ReTable
              name="Applicable Rules"
              title="Applicable Rules"
              data={matchingRules}
              columns={matchingRulescolumns}
              rowSelection={{
                selectedRowKeys: selectedMatchingRulesRowKeys,
                type: "checkbox",
                setSelectedRowKeys: setSelectedMatchingRulesRowKeys,
                rowKey: "ruleId",
              }}
            />
            <ReModal
              title="Description"
              visibility={desModalVisibility}
              onOkay={() => {}}
              onCancel={() => {
                setDesModalVisibility(false);
              }}
              footer={false}
            >
              <RulebuilderQueryDes ruleData={selectedRule} />
            </ReModal>
            <styles.CreateReportBtn>
              <Button
                type="primary"
                size="middle"
                onClick={handleCreateReportClicks}
                loading={isSubmitting}
              >
                Create
              </Button>
            </styles.CreateReportBtn>
          </>
        )}
      </ReDrawer>
      <CreateVersionOfSafetyDocument
        setVisibility={setVersionCreateDrawerVisibility}
        visibility={versionCreateDrawerVisibility}
        existingSafetyDocumentData={safetyDocumentDataForVersionCreate}
      />
      <EmailSafetyDocumentsDrawer
        setVisibility={setEmailSafetyDocumentsDrawerVisibility}
        visibility={emailSafetyDocumentsDrawerVisibility}
        selectedSafetyDocument={selectedRowData}
      />
    </styles.Container>
  );
}
export default SafetyDocument;

import type { ColumnsType } from "antd/es/table";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import ReTable from "../../../unity-ui-common/Components/ReTable/ReTable";
import { ICreateUser } from "../../../Interfaces/Apis/User.interface";
export type UserRoleMatrixTypes = {
  creation: boolean;
  deletion: boolean;
  modification: boolean;
  updation: boolean;
  sponsors: string;
};
export const UserRoleMatrixColumns: ColumnsType<UserRoleMatrixTypes[]> = [
  {
    title: "Sponsors",
    dataIndex: "sponsors",
  },
  {
    title: "Creation",
    dataIndex: "creation",
    render: (creation: string, creationRowData) => <input type="checkbox" />,
  },

  {
    title: "Deletion",
    dataIndex: "deletion",
    render: (text: string) => <input type="checkbox" />,
  },
  {
    title: "Modification",
    dataIndex: "modification",
    render: (text: string) => <input type="checkbox" />,
  },
  {
    title: "Updation",
    dataIndex: "updation",
    render: (text: string) => <input type="checkbox" />,
  },
];

export const UserMatrixData = [
  {
    creation: true,
    deletion: true,
    modification: true,
    updation: true,
    sponsors: "tikaj",
  },

  {
    creation: true,
    deletion: false,
    modification: true,
    updation: false,
    sponsors: "amazon",
  },

  {
    creation: false,
    deletion: true,
    modification: false,
    updation: true,
    sponsors: "flipkart",
  },
];
type matrixInput = {
  name: string;
  value: string;
};

export const UserRoleMatrix = ({
  selectedSponsors,
  selectedRoles,
  setTempUser,
  tempUser,
}: {
  selectedSponsors: matrixInput[];
  selectedRoles: matrixInput[];
  setTempUser: Dispatch<SetStateAction<ICreateUser>>;
  tempUser: ICreateUser;
}) => {
  const [tableData, setTableData] = useState(() =>
    selectedSponsors?.map((sp) => {
      const rolesObj = selectedRoles?.reduce((acc: any, curr: any) => {
        return [
          ...acc,
          {
            roleName: curr.name,
            isAssigned: tempUser.roleMatrices
              .find((role) => role.tenant === sp.value)
              ?.roles.includes(curr.value),
            roleId: curr.value,
          },
        ];
      }, []);
      return { roles: rolesObj, sponsors: sp.name, id: sp.value };
    })
  );

  useEffect(() => {
    setTableData(
      selectedSponsors?.map((sp) => {
        const rolesObj = selectedRoles?.reduce((acc: any, curr: any) => {
          return [
            ...acc,
            {
              roleName: curr.name,
              isAssigned: tempUser.roleMatrices
                .find((role) => role.tenant === sp.value)
                ?.roles.includes(curr.value)
                ? true
                : false,
              roleId: curr.value,
            },
          ];
        }, []);
        return { roles: rolesObj, sponsors: sp.name, id: sp.value };
      })
    );
  }, [selectedRoles, selectedSponsors]);

  const userMatrixColumn = selectedRoles?.map((roles) => {
    return {
      title: roles.name,
      dataIndex: roles.name,
      render: (text: any, record: any, index: any) => {
        return (
          <input
            type="checkbox"
            onChange={() => handelMatrixChange(record, roles.name)}
            checked={tempUser.roleMatrices
              .find((item) => item.tenant === record.id)
              ?.roles.includes(roles.value)}
          />
        );
      },
    };
  });

  const handelMatrixChange = (record: any, roleName: string) => {
    const tempTableData = tableData.map((data) =>
      data.id === record.id
        ? {
            ...data,
            roles: data.roles.map((role) =>
              role.roleName === roleName
                ? { ...role, isAssigned: !role.isAssigned }
                : role
            ),
          }
        : data
    );
    setTableData(tempTableData);

    setTempUser((prevData) => {
      return {
        ...prevData,
        roleMatrices: tempTableData.map((data) => ({
          tenant: data.id,
          roles: data.roles
            .filter((role) => role.isAssigned)
            .map((role) => role.roleId),
        })),
      };
    });
  };

  const finalUserMatrix: ColumnsType<UserRoleMatrixTypes[]> = [
    {
      title: "Sponsor/MAH",
      dataIndex: "sponsors",
    },
    ...userMatrixColumn,
  ];

  return (
    <div>
      <ReTable
        name="User Role Matrix"
        columns={finalUserMatrix}
        data={tableData}
        // currentLoggedInUser={1}
      />
    </div>
  );
};

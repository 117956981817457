import styled from "styled-components";

export const Container = styled.div`
  .ck-editor__editable_inline {
    min-height: 200px;
    padding: 0 1.5rem;
  }
  .ck.ck-editor {
  }
`;

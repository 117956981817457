import TimerManager from "../../API/TimerManager";
import ReModal from "../../unity-ui-common/Components/ReModal";
import { useEffect, useState } from "react";
import { auth } from "../../API/Auth";
import { ClockCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";

import * as Styles from "./ExpiryModalStyles";

function ExpiryModal({
  onHandleLogout,
  showLogoutWarningModal,
  setShowLogoutWarningModal,
  timerInstance,
}: {
  onHandleLogout: () => void;
  showLogoutWarningModal: boolean;
  setShowLogoutWarningModal: React.Dispatch<React.SetStateAction<boolean>>;
  timerInstance: TimerManager | null;
}) {
  const [time, setTime] = useState<number>(60);

  function countDown() {
    setTime(timerInstance?.getSecondsLeft() || 60);
    const timer = setInterval(() => {
      if (timerInstance?.getModalVisible() === false)
        setShowLogoutWarningModal(false);
      setTime((prevTime: number) => {
        if (prevTime === 0) {
          clearInterval(timer);
          return 0;
        } else {
          return timerInstance?.getSecondsLeft() || 0;
        }
      });
    }, 1000);
  }
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes ? String(minutes) + " min " : ""}${String(
      remainingSeconds
    )} secs`;
  };
  useEffect(() => {
    showLogoutWarningModal && countDown();
  }, [showLogoutWarningModal]);

  return (
    <ReModal
      title="Session Alert"
      onCancel={async () => {
        await auth.getAccessTokenValidity();
        setShowLogoutWarningModal(false);
        timerInstance?.resetTimer();
      }}
      centered
      width={370}
      visibility={showLogoutWarningModal}
      footer={
        <>
          <Button
            type="primary"
            size="middle"
            htmlType="button"
            onClick={async () => {
              await auth.getAccessTokenValidity();
              setShowLogoutWarningModal(false);
              timerInstance?.resetTimer();
            }}
          >
            Continue
          </Button>
          <Button size="middle" type="default" onClick={onHandleLogout}>
            Logout
          </Button>
        </>
      }
    >
      <Styles.ModalBody>
        <div className="first-message">
          <div className="icon">
            <ClockCircleOutlined />
          </div>
          <h3>You will be logged out soon</h3>
        </div>
        {time && <div className="timer">{formatTime(time)}</div>}
        <div className="second-message">
          Please click Continue to keep working or Logout to logout now.
        </div>
      </Styles.ModalBody>
    </ReModal>
  );
}

export default ExpiryModal;

import { Button, Space, Timeline } from "antd";
import * as styles from "./SideDrawerStyles";
import { useEffect, useState } from "react";
import { FilePdfTwoTone } from "@ant-design/icons";
import ReDrawer from "../../unity-ui-common/Components/ReDrawer";
import { convertDateInReadableFormat } from "../../Helper/Methods";
import { file } from "../../API/File";

enum operationType {
  NOTIFY = "Send Notification",
  NOTIFY_FAILED = "Send Notification Failed",
  ACKNOWLEDGE = "Acknowledge",
  INFORM_EC_IRB = "Inform EC/IRB",
}

function SideDrawer(props: {
  sideDrawerVisible: boolean;
  setSideDrawerVisible: Function;
  data: any;
}) {
  const { sideDrawerVisible, setSideDrawerVisible, data } = props;
  const { details, timeLine } = data;
  const id = details ? details.id : undefined;

  const [attachedFiles, setAttachedFiles] = useState<Array<any>>([]);
  const [evidence, setEvidence] = useState<Array<any>>([]);

  const getFileUrls = async (type: string) => {
    if (type === "attachments") {
      const fileUrls = await file.getSignedUrls(details.attachments);
      setAttachedFiles(fileUrls);
    } else if (type === "evidence") {
      const fileUrls = await file.getSignedUrls([details.evidence]);
      setEvidence(fileUrls);
    }
  };

  

  const getMessage = (item: any) => {
    let msg = "";
    if (item.type === operationType.NOTIFY) msg = "Sent to";
    else if (item.type === operationType.NOTIFY_FAILED)
      msg = "Failed sending notification to";
    else if (item.type === operationType.ACKNOWLEDGE) msg = "Acknowledged by";
    else msg = "Informed to";

    msg = `${msg} ${item.distributionName}`;

    return (
      <>
        {msg} on{" "}
        <styles.BoldText>
          {convertDateInReadableFormat(item?.createdAt, true)}
        </styles.BoldText>
        {item.valueAfter.code === "MessageRejected"
          ? ` (${item.valueAfter.message.split(".")[0]})`
          : ""}
      </>
    );
  };
  useEffect(() => {
    setEvidence([]);
    setAttachedFiles([]);

    if (details && details.attachments && details.attachments.length) {
      getFileUrls("attachments");
    }
    if (details && details.evidence && details.evidence.length) {
      getFileUrls("evidence");
    }
  }, [id]);

  return (
    <div>
      <ReDrawer
        visibility={sideDrawerVisible}
        onCancel={() => {
          setSideDrawerVisible(false);
        }}
        placement="right"
        width={400}
      >
        <Timeline>
          {timeLine?.map((item: any) => (
            <Timeline.Item key={item.id}>{getMessage(item)}</Timeline.Item>
          ))}
        </Timeline>
        {details && (
          <styles.Details>
            <styles.Detail>
              <h4>Site Contact Name</h4>
              <p>{details.site_contact_name}</p>
            </styles.Detail>
            <styles.Detail>
              <h4>Email</h4>
              <p>{details.email}</p>
            </styles.Detail>
            <styles.Detail>
              <h4>Site Number</h4>
              <p>{details.site_number}</p>
            </styles.Detail>
            <styles.Detail>
              <h4>Site Name</h4>
              <p>{details.site_name}</p>
            </styles.Detail>
          </styles.Details>
        )}
        {
          evidence?.length > 0 && (
            <div>
              <br />
              <h3>Record of Notification to EC/IRB</h3>
              <Space>
                {evidence?.map((ev) => (
                  <Button
                    key={ev?.fileRecord?.id}
                    type="link"
                    href={ev.url}
                    icon={<FilePdfTwoTone />}
                    style={{ padding: "0" }}
                  >
                    {ev?.fileRecord?.filename}
                  </Button>
                ))}
              </Space>
            </div>
          )
          // <SimpleTable tableName="Evidence" pagination={false} columns={columns} data={evidence} />
        }
        {
          attachedFiles.length > 0 && (
            <div>
              <br />
              <h3>Attachments sent to EC/IRB</h3>
              <Space>
                {attachedFiles.map((ev) => (
                  <Button
                    key={ev.fileRecord.id}
                    type="link"
                    href={ev.url}
                    icon={<FilePdfTwoTone />}
                    style={{ padding: "0" }}
                  >
                    {ev.fileRecord.filename}
                  </Button>
                ))}
              </Space>
            </div>
          )
          // <SimpleTable tableName="Attachments" pagination={false} columns={columns} data={attachedFiles} />
        }
      </ReDrawer>
    </div>
  );
}

export default SideDrawer;

import { Collapse, Tag } from "antd";
import { DisplayMsg, LoadingSpinner } from "..";
import * as styles from "./RemindersDueTodayItemsStyles";
import {
  DownOutlined,
  UpOutlined,
  BellOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";
import { auth } from "../../API/Auth";
import { columns } from "./Constant";

function RemindersDueTodayItems(props: { items: any; loading: boolean }) {
  const { Panel } = Collapse;
  const { items, loading } = props;
  let navigate = useNavigate();

  const handleSendReminder = (reprtId: string) => {
    navigate(`/reports/view/${reprtId}`);
  };

  const countAccurance = (items: any[]) => {
    let NotAcknowledgedCount = 0;
    let NotEcIrbNotifiedCount = 0;

    items.forEach((item) => {
      if (!item.isIrbNotified) {
        NotEcIrbNotifiedCount++;
      }
      if (!item.isAcknowledged) {
        NotAcknowledgedCount++;
      }
    });

    return [NotAcknowledgedCount, NotEcIrbNotifiedCount];
  };

  return (
    <styles.Container>
      {Object.keys(items).length !== 0 ? (
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => {
            const Icon = isActive ? UpOutlined : DownOutlined;
            return <Icon />;
          }}
          expandIconPosition="end"
        >
          {Object.keys(items).map((ele, idx) => {
            const heading = `${ele.split("_")[1]} (${ele.split("_")[0]})`;
            const counts = countAccurance(items[ele]);
            return (
              <Panel
                header={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                      padding: "3px 0",
                    }}
                  >
                    <h3 style={{ margin: "0" }}>{heading}</h3>
                    <Tag color="red">{counts[0] + counts[1]}</Tag>
                  </div>
                }
                key={ele}
              >
                <ReTable
                  name="Reminder Due Today"
                  columns={columns}
                  data={items[ele]}
                  headerTags={[
                    {
                      title: `Pending Acknowledgment ${counts[0]}`,
                      color: "",
                    },
                    {
                      title: `Pending EC/IRB Notification ${counts[1]}`,
                      color: "",
                    },
                  ]}
                  headerButtons={[
                    {
                      title: "Send Reminders",
                      icon: <BellOutlined />,
                      type: "primary",
                      onClick: () => {
                        handleSendReminder(ele.split("_")[0]);
                      },
                      visibility: auth.isPermissionExists(["0"]),
                    },
                    {
                      title: "View",
                      icon: <EyeOutlined />,
                      type: "primary",
                      onClick: () => {
                        navigate(`/reports/view/${ele.split("_")[0]}`);
                      },
                      visibility: !auth.isPermissionExists(["0"]),
                    },
                  ]}
                />
              </Panel>
            );
          })}
        </Collapse>
      ) : (
        <>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <DisplayMsg message="You dont have any Reminders" />
          )}
        </>
      )}
    </styles.Container>
  );
}

export default RemindersDueTodayItems;

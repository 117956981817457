import { useEffect, useState } from "react";
import ReDrawer from "../../unity-ui-common/Components/ReDrawer";
import CreateSafetyDocument from "../CreateSafetyDocument/CreateSafetyDocument";
import moment from "moment";
import { report } from "../../API/Report";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import { useNavigate } from "react-router-dom";

interface IProps {
  visibility: boolean;
  setVisibility: Function;
  existingSafetyDocumentData: any;
}

function CreateVersionOfSafetyDocument(props: IProps) {
  const { visibility, setVisibility, existingSafetyDocumentData } = props;
  const navigate = useNavigate();

  const [initialFormData, setInitialFormData] = useState<any>({});

  const handleSubmit = async (values: any) => {
    delete values?.severity;
    const payload = {
      ...values,
      serialNumber: existingSafetyDocumentData.serialNumber,
    };
    const machingRules = existingSafetyDocumentData.matchingRules.map(
      (rul: any) => rul.id
    );
    const res = await report.create(payload, machingRules);
    if (res) {
      navigate(`/reports/view/${res.id}`);
      ReNotification({
        description: "Version Created Successfully",
        type: "success",
      });
    }
    setVisibility(false);
  };

  const createFormInitialData = async (data: any) => {
    const newSeverityValue = Array.isArray(data?.meta?.severity)
      ? data?.meta?.severity?.map((ele: any, idx: number) => {
          return {
            key: `${ele}_${idx}`,
            label: ele,
          };
        })
      : [];
    setInitialFormData({
      type: data?.type,
      typeValue: data?.typeValue,
      studyID: data?.studyID,
      product: data?.product,
      sourceSiteID: data?.sourceSiteID,
      lrd: data?.lrd ? moment(data?.lrd) : "",
      safetyDatabaseReportNumber: data?.safetyDatabaseReportNumber,
      investigatorCausality: data?.meta?.investigatorCausality,
      sponsorCausality: data?.meta?.sponsorCausality,
      seriousnessCriteria: data?.meta?.seriousnessCriteria,
      country: data?.country,
      saeReportedTerm: data?.meta?.saeReportedTerms,
      studyTitle: data?.studyTitle,
      attachment: data?.attachments,
      sourceSiteName: data?.sourceSiteName,
      severity: newSeverityValue,
    });
  };

  useEffect(() => {
    createFormInitialData(existingSafetyDocumentData);
  }, [existingSafetyDocumentData]);

  return (
    <div>
      <ReDrawer
        visibility={visibility}
        width={750}
        title="Create Version"
        onCancel={() => {
          setVisibility(false);
        }}
      >
        <CreateSafetyDocument
          submitButtonText="Create"
          initialData={initialFormData}
          onFormSubmit={handleSubmit}
          type="version"
        />
      </ReDrawer>
    </div>
  );
}

export default CreateVersionOfSafetyDocument;

import { Tag } from "antd";
import { AcknowledgedStatus, DistributionStatus, permissionsEnum } from "../../Helper/Mappings";
import { subtractDate } from "../../Helper/Util";
import { convertDateInReadableFormat } from "../../Helper/Methods";

export const columns = [
    {
      title: "Site Name ",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
    {
      title: "Study Number",
      dataIndex: "studyNumber",
      key: "studyNumber",
      width: 130,
    },
    { title: "Name", dataIndex: "name", key: "name", width: 250 },
    {
      title: "Email",
      dataIndex: "primaryEmailID",
      key: "primaryEmailID",
      width: 250,
    },
    { title: "Role", dataIndex: "roleTitle", key: "roleTitle", width: 230 },
    {
      title: "Status",
      dataIndex: "distributionStatus",
      key: "distributionStatus",
      width: 100,
      render: (status: any) => (
        <Tag
          color={
            (DistributionStatus[status] && DistributionStatus[status].color) ||
            "pink"
          }
          key={status}
        >
          {(DistributionStatus[status] && DistributionStatus[status].label) ||
            status}
        </Tag>
      ),
    },
    {
      title: "Date of Site Notification (CD)",
      dataIndex: "sentOn",
      key: "sentOn",
      width: 220,
      render: (sentOn: string, record: any) => {
        return (
          (sentOn &&
            convertDateInReadableFormat(sentOn) +
              " (" +
              subtractDate(new Date(sentOn), new Date(record.lrd)) +
              ")") ||
          "-"
        );
      },
    },
    {
      title: "Acknowledged",
      dataIndex: "isAcknowledged",
      key: "isAcknowledged",
      width: 130,
      render: (isAcknowledged: any) => {
        isAcknowledged = isAcknowledged ? 1 : 0;
        return (
          <Tag
            color={
              (AcknowledgedStatus[isAcknowledged] &&
                AcknowledgedStatus[isAcknowledged].color) ||
              "pink"
            }
          >
            {(AcknowledgedStatus[isAcknowledged] &&
              AcknowledgedStatus[isAcknowledged].label) ||
              isAcknowledged}
          </Tag>
        );
      },
    },
    {
      title: "EC/IRB Notified",
      dataIndex: "isIrbNotified",
      key: "isIrbNotified",
      width: 150,
      render: (isIrbNotified: any, record: any) => {
        if (parseInt(record?.role) === permissionsEnum.PARTNER){
          return  <Tag >N/A</Tag>
        
        } 
        isIrbNotified = isIrbNotified ? 1 : 0;
        return (
          <Tag
            color={
              (AcknowledgedStatus[isIrbNotified] &&
                AcknowledgedStatus[isIrbNotified].color) ||
              "pink"
            }
          >
            {(AcknowledgedStatus[isIrbNotified] &&
              AcknowledgedStatus[isIrbNotified].label) ||
              isIrbNotified}
          </Tag>
        );
      },
    },
  ];
import { useEffect, useState } from 'react';
import ReusableForm from "../../unity-ui-common/Components/ReForm";
import ReInput from "../../unity-ui-common/Components/ReFormFields/ReInput";
import ReSelect from "../../unity-ui-common/Components/ReFormFields/ReSelect";
import * as Styles from "./CreateDocumentStyles";
import { Button, Form, Tag } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { template } from '../../API/Template';
import ReUpload from '../../unity-ui-common/Components/ReFormFields/ReUpload';

function CreateDocument() {

    const [form] = Form.useForm();
    const { id: templateId } = useParams();
    const navigate = useNavigate();

    const [templateData, setTemplateData]: any = useState();

    useEffect(() => {
        if (!templateId) {
            navigate('/template-management');
            return;
        }
        onPageLoad();
    }, [templateId]);

    async function onPageLoad() {
        let id = templateId || "";
        if (!id || id === "new-template") {
            setTemplateData({ status: true });
            return;
        }
        else if (id.includes("||")) {
            const splitArray = id.split("||");
            id = splitArray?.[1];
        }
        const templateRes = await template.getOne(id || "");
        templateRes.status = templateRes.status === 1 ? true : false;
        setTemplateData(templateRes);
    }

    return (
        <Styles.Container>
            <div className="mainBody">
                <div className="headingContainer">
                    <h2 className="name">Create New Document</h2>
                    <Button type="primary" onClick={() => { form.submit() }}>Save</Button>
                </div>
                <ReusableForm
                    formInstance={form}
                    onSubmit={() => { }}
                    onChange={() => { }}
                    layout="horizontal"
                    className="templateForm"
                >
                    <div className="formSection1">
                        <ReInput
                            type="simple"
                            name="name"
                            label="Name"
                            size="small"
                        />
                        <ReInput
                            type="simple"
                            name="type"
                            label="Type"
                            size="small"
                        />
                        <ReSelect
                            name="workflow"
                            label="Workflow"
                            items={[
                                {
                                    value: 'wf1',
                                    title: 'Workflow Type 1',
                                },
                                {
                                    value: 'wf2',
                                    title: 'Workflow Type 2',
                                },
                                {
                                    value: 'wf3',
                                    title: 'Workflow Type 3',
                                }
                            ]}
                        />
                        <div className="selectedTemplate">
                            {templateData ? <Link to={`/template-management/${templateData?.id}`} target='blank'>
                                <div className="templateCard">
                                    {templateData?.status === 0 && <div className="inactive"></div>}
                                    <div className="nameContainer">
                                        <div className="name">{templateData?.name}</div>
                                        <Tag>{templateData?.mode}</Tag>
                                        <Tag>{templateData?.type}</Tag>
                                    </div>
                                    <div className="description">{templateData?.description?.length > 180 ? (templateData?.description).substring(0, 180).trim() + "......" : templateData?.description}</div>
                                </div>
                            </Link> : <div></div>}
                        </div>
                    </div>
                    <ReInput
                        name="description"
                        type="textArea"
                        label="Description"
                    />
                    <ReUpload
                        errorMessage="Please enter your file"
                        label="Attach Source File"
                        BtnTitle="Click to Upload Data File"
                        name="attachments"
                        onBeforeUpload={() => { }}
                        onRemove={() => { }}
                        fileListMaxCount={10}
                        fileList={[]}
                    />
                </ReusableForm>
            </div>
        </Styles.Container>
    )
}

export default CreateDocument
import * as Styles from "./WorkFlowConfigurationStyles";
import ReTab from "../../unity-ui-common/Components/ReTab";
import { useState } from "react";
import { Button } from "antd";
import {
    PlusOutlined,
} from "@ant-design/icons";
import {Collapse} from "antd";
import { columns,Draft,Completed,ToReview } from "./Constant";
import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";

function WorkFlowConfig (){
    const { Panel } = Collapse;
    const [selectedGlobalRulesRowKeys, setSelectedGlobalRowKeys] = useState<any>(
        []
      );
    const [selectedGlobalRulesRow, setSelectedGlobalRow] = useState<any>([]);
    const [selectedGlobalRulesRowKeys1, setSelectedGlobalRowKeys1] = useState<any>(
        []
      );
    const [selectedGlobalRulesRow1, setSelectedGlobalRow1] = useState<any>([]);
    const [selectedGlobalRulesRowKeys2, setSelectedGlobalRowKeys2] = useState<any>(
        []
      );
    const [selectedGlobalRulesRow2, setSelectedGlobalRow2] = useState<any>([]);
    const [activeTabValue, setActiveTabValue] = useState<string>("0");
    const [refreshReTable, setRefreshReTable] = useState<boolean>(false);
return (
    <Styles.Container className="subContainer">
<ReTab
        activeKey={activeTabValue}
        onChange={(id: string) => {
          setActiveTabValue(id);
        }}
        items={[
          {
            title: "WorkFlow Configuration",
            key: "0",
            children: (
                
                <>
                <div className="addNewBtn" style={{paddingBottom:"15px"}}>
          <Button
            type='primary'
            // onClick={() => { navigate('/template-management/new-template') }}
          >
            <PlusOutlined /> Add New WorkFlow
          </Button>
        </div>
        {/* <Collapse bordered={false} className="dropDown">
        <Panel header="Detailed Information" key="1">
        </Panel>
        </Collapse> */}
              <Collapse bordered={false} className="dropDown">
              <Panel header="Draft" key="1">
                <>
               <ReTable
                refresh={refreshReTable}
                data={Draft}
                name="Reporting Rules"
                // title="Reporting Rules"
                columns={columns}
              
                scroll={{
                  x: 800,
                  y: 500,
                }}
                
                rowSelection={{
                  rowKey: "id",
                  selectedRowKeys: selectedGlobalRulesRowKeys,
                  setSelectedRowKeys: setSelectedGlobalRowKeys,
                  type: "checkbox",
                  selectRowtype: "allData",
                  selectedRowValues: selectedGlobalRulesRow,
                  setSelectedRowValues: setSelectedGlobalRow,
                }}
              />
                </>
                </Panel>
              </Collapse>
              <Collapse bordered={false} className="dropDown">
              <Panel header="To Review" key="1">
                <>
                <ReTable
                refresh={refreshReTable}
                data={ToReview}
                name="Reporting Rules"
                // title="Reporting Rules"
                columns={columns}
              
                scroll={{
                  x: 800,
                  y: 500,
                }}
                
                rowSelection={{
                  rowKey: "id",
                  selectedRowKeys: selectedGlobalRulesRowKeys1,
                  setSelectedRowKeys: setSelectedGlobalRowKeys1,
                  type: "checkbox",
                  selectRowtype: "allData",
                  selectedRowValues: selectedGlobalRulesRow1,
                  setSelectedRowValues: setSelectedGlobalRow1,
                }}
              />
                </>
                </Panel>
              </Collapse>
              <Collapse bordered={false} className="dropDown">
              <Panel header="Completed" key="1">
                <>
                <ReTable
                refresh={refreshReTable}
                data={Completed}
                name="Reporting Rules"
                // title="Reporting Rules"
                columns={columns}
              
                scroll={{
                  x: 800,
                  y: 500,
                }}
                
                rowSelection={{
                  rowKey: "id",
                  selectedRowKeys: selectedGlobalRulesRowKeys2,
                  setSelectedRowKeys: setSelectedGlobalRowKeys2,
                  type: "checkbox",
                  selectRowtype: "allData",
                  selectedRowValues: selectedGlobalRulesRow2,
                  setSelectedRowValues: setSelectedGlobalRow2,
                }}
              />
                </>
                </Panel>
              </Collapse>
         </>
            //   <ReTable
            //     refresh={refreshReTable}
            //     name="Reporting Rules"
            //     title="Reporting Rules"
            //     columns={columns}
            //     uniView={UniViewConfig}
            //     scroll={{
            //       x: 800,
            //       y: 500,
            //     }}
            //     exportOption={{
            //       csv: {},
            //     }}
            //     rowSelection={{
            //       rowKey: "id",
            //       selectedRowKeys: selectedGlobalRulesRowKeys,
            //       setSelectedRowKeys: setSelectedGlobalRowKeys,
            //       type: "checkbox",
            //       selectRowtype: "allData",
            //       selectedRowValues: selectedGlobalRulesRow,
            //       setSelectedRowValues: setSelectedGlobalRow,
            //     }}
            //     headerButtons={[
            //       {
            //         title: "Add New",
            //         icon: <PlusOutlined />,
            //         onClick: () => {
            //           handleTableActionClick("add");
            //         },
            //       },
            //       {
            //         title: "Edit",
            //         icon: <EditOutlined />,
            //         onClick: () => {
            //           handleTableActionClick(
            //             "edit",
            //             selectedGlobalRulesRowKeys[0]
            //           );
            //         },
            //         disabled: handleTableActionVisibility(),
            //       },
            //       {
            //         title: "Copy",
            //         icon: <CopyOutlined />,
            //         onClick: () => {
            //           handleTableActionClick(
            //             "copy",
            //             selectedGlobalRulesRowKeys[0]
            //           );
            //         },
            //         disabled: handleTableActionVisibility(),
            //       },
            //     ]}
            //   />
            ),
          },
         
        ]}
      />
    </Styles.Container>
)
}

export default WorkFlowConfig
import { Form, Drawer } from "antd";
import { useEffect, useState } from "react";
import { permission } from "../../API/UserManagement/Permission";
import { role } from "../../API/UserManagement/Role";
import { getAllRoles } from "../../slices/Roles/Helpers/GetAllRoles";
import { useAppDispatch } from "../../app/hooks";
import ReusableForm from "../../unity-ui-common/Components/ReForm";
import ReInput from "../../unity-ui-common/Components/ReFormFields/ReInput";
import ReCheckBox from "../../unity-ui-common/Components/ReFormFields/ReCheckbox";
import "../Management/ManageUsers/styles.css";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";

export const RoleCreationForm = (props: {
  drawerType: string;
  visibility: boolean;

  setRoleDrawerType: Function;
  setRoleDrawerVisibility: Function;
  currEditingRole: any;
}) => {
  const [form] = Form.useForm();
  const {
    drawerType,
    visibility,
    setRoleDrawerType,
    setRoleDrawerVisibility,
    currEditingRole,
  } = props;
  const dispatch = useAppDispatch();
  const [allPermissions, setAllPermissions] = useState([]);

  const onClose = () => {
    setRoleDrawerType("create");
    setRoleDrawerVisibility(false);
    form.resetFields();
  };

  const getAllPermissions = async () => {
    const data = await permission.getAll();
    setAllPermissions(data);
  };

  async function handleFormSubmitButtonClicks(values: any) {
    let permissions = [""];

    allPermissions.forEach((mainPermissionsData: any) => {
      if (values[mainPermissionsData.id]) {
        permissions.push(mainPermissionsData.id);
      }
    });
    const payload = {
      name: values.name,
      description: values.description,
      permissions: permissions,
    };
    if (drawerType === "create") {
      await role.create(payload);
    } else {
      await role.update(currEditingRole.id, payload);
    }
    dispatch(getAllRoles());
    ReNotification({
      description: `User Role ${
        drawerType === "create" ? "Created" : "Updated"
      } Successfully`,
      type: "success",
    });
    onClose();
  }

  useEffect(() => {
    getAllPermissions();
  }, [visibility]);

  useEffect(() => {
    if (drawerType === "edit" && allPermissions) {
      let values = {
        name: currEditingRole.name,
        description: currEditingRole.description,
      };
      allPermissions?.map((mainPermissionData: any) => {
        const isPermission = currEditingRole?.permissions?.some(
          (userPermissionData: any) =>
            userPermissionData.id === mainPermissionData.id
        );
        values = { ...values, [mainPermissionData.id]: isPermission };
      });
      form.setFieldsValue(values);
    }
  }, [drawerType, allPermissions]);

  return (
    <div>
      <Drawer
        placement="right"
        onClose={onClose}
        open={visibility}
        width={"650"}
        title={drawerType === "edit" ? "Edit User Roles" : "Create Roles"}
      >
        <ReusableForm
          formInstance={form}
          onSubmit={handleFormSubmitButtonClicks}
          submitBtn
          submitBtnText={drawerType === "edit" ? "Update" : "Create"}
        >
          <ReInput label="Name" name="name" required type="simple" />
          <ReInput
            label="Description"
            name="description"
            required
            type="simple"
          />
          <p>Privileges</p>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
            {allPermissions?.map((data: any, idx) => (
              <ReCheckBox key={idx} label={data.name} name={data.id} />
            ))}
          </div>
        </ReusableForm>
      </Drawer>
    </div>
  );
};

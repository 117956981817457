import { ICreateUser, IUpdateUser } from "../../Interfaces/Apis/User.interface";
import { BaseAPI } from "../Common";

class User extends BaseAPI {
  create(payload: ICreateUser): Promise<any> {
    return this.makeRequest(`create`, "post", payload);
  }

  update(userId: string, payload: IUpdateUser): Promise<any> {
    return this.makeRequest(`${userId}`, "patch", payload);
  }

  getAll(): Promise<any> {
    return this.makeRequest(`all`);
  }

  getUser(userId: string): Promise<any> {
    return this.makeRequest(`${userId}`);
  }
}

export const user = new User("user");

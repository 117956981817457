import { ICreatePermission } from "../../Interfaces/Apis/Permission.interface";
import { BaseAPI } from "../Common";

class Permission extends BaseAPI {
  create(payload: ICreatePermission): Promise<any> {
    return this.makeRequest(`create`, "post", payload);
  }

  getAll(): Promise<any> {
    return this.makeRequest(`all`);
  }
}

export const permission = new Permission("permission");

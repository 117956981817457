import { Routes, Route, useLocation } from "react-router-dom";
import { auth } from "./API/Auth";
import { store } from "./app/store";
import { useAppDispatch } from "./app/hooks";
import { useEffect, useState } from "react";
import { initializeState, loadState } from "./sessionstorage";
import { addUserPermissions } from "./slices/User/UserSlices";
import routes from "./Configs/Routes";
import TimerManager from "./API/TimerManager";
import { ExpiryModal, Header, SideMenu } from "./Components";
import { RoutesDataType } from "./Interfaces/Config/Routes.interface";
import * as Styles from "./AppStyles";

function App() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const currentPath = location.pathname;

  const [filteredRoutes, setFilteredRoutes] = useState<RoutesDataType[]>([]);
  const [timerInstance, setTimerInstance] = useState<TimerManager | null>(null);

  const [showLogoutWarningModal, setShowLogoutWarningModal] =
    useState<boolean>(false);
  const onHandleShowModal = () => {
    setShowLogoutWarningModal(true);
  };
  const onHandleLogout = async () => {
    await auth.logout();
    sessionStorage.setItem("unauth", "true");
    window.location.href = "/";
  };

  const onHandleLogoutClick = async () => {
    await auth.logout();
    window.location.href = "/";
  };

  useEffect(() => {
    const unsubscribe = !loadState()?.session
      ? store.subscribe(() => {
          initializeState({
            session: store.getState().session,
          });
        })
      : () => {};
    setTimerInstance(
      TimerManager.getInstance(onHandleShowModal, onHandleLogout)
    );
    return () => {
      unsubscribe();
      timerInstance?.clearTimer();
    };
  }, []);
  const getFilteredRoutes = (permissions: any) => {
    const filtered = routes.filter((route: any) => {
      if (route.hasOwnProperty("privileges")) {
        return route.privileges.some((privilege: any) =>
          permissions?.includes(privilege)
        );
      } else {
        return route;
      }
    });
    return filtered;
  };
  useEffect(() => {
    const session = loadState()?.session;
    const privileges = session?.userPermissions || [];
    const accessToken = session?.userAccessToken;

    const timerInstance = TimerManager.getInstance();
    if (
      ["/login", "/workspace"].some((path) => currentPath.includes(path)) ||
      currentPath === "/"
    ) {
      timerInstance?.clearTimer();
    } else {
      timerInstance?.resetTimer();
    }

    if (
      !accessToken &&
      !["/login", "/workspace"].some((path) => currentPath.includes(path)) &&
      currentPath !== "/"
    ) {
      auth.logoutMethods();
      window.location.href = "/";
    }

    setFilteredRoutes(
      getFilteredRoutes(privileges.map((ele: any) => ele?.type))
    );

    dispatch(addUserPermissions(privileges.map((ele: any) => ele?.type)));
  }, [currentPath]);
  return (
    <div>
      <ExpiryModal
        setShowLogoutWarningModal={setShowLogoutWarningModal}
        onHandleLogout={onHandleLogoutClick}
        showLogoutWarningModal={showLogoutWarningModal}
        timerInstance={timerInstance}
      />
      <Routes>
        {filteredRoutes.map((ele: RoutesDataType) => {
          const { element, path, header, sideMenu } = ele;
          return (
            <Route
              key={path}
              element={
                <Styles.Container>
                  {header && <Header />}
                  <Styles.SubContainer>
                    {sideMenu && <SideMenu />}
                    {element}
                  </Styles.SubContainer>
                </Styles.Container>
              }
              path={path}
            />
          );
        })}
      </Routes>
    </div>
  );
}
export default App;

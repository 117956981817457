import Styled from "styled-components";

export const Container = Styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .table-chart-container{
        /* position: relative; */
        .table-chart{
        /* position: sticky; */
        /* top: 0; */
        /* left: 15px; */
        /* width: 78vw; */
    }
    }
`;

export const Stats = Styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
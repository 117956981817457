import { Link } from "react-router-dom";

const getEmailId = (data: string) => {
    if (!data) return
    const parts = data.split("_");
    return parts[0]
}

export const actionTextGenerator = (allData: any) => {
    const actionType = allData.operationType;
    if (actionType === "EC/IRB Informed") {
        const emailId = getEmailId(allData.ref.distributionId);
        const allAttachmentData = allData?.valueAfter?.allAttachmentData;
        return (
            <>
                <div>EC/IRB Notification sent to {emailId}</div>
                {allAttachmentData?.length ?
                    <>
                        <div>File Attached To EC/IRB:</div>
                        {allAttachmentData.map((data: any) => {
                            return (
                                <ol style={{ marginLeft: "21px" }}>
                                    <li>
                                        <Link key={data?.id} to={data?.url}>
                                            {data?.name}.{data.type}
                                        </Link>
                                    </li>
                                </ol>
                            )
                        })}
                    </> : <></>
                }
            </>
        );
    }
    else if (actionType === "Notification Acknowledged") {
        const emailId = getEmailId(allData.ref.distributionId);
        return `Notification Acknowledged by ${emailId}`;
    }
    else if (actionType === "Notification Sent") {
        const emailId = getEmailId(allData.ref.distributionId);
        return `Notification Sent to ${emailId}`;
    }
    else if (actionType === "Distribution Created") {
        const emailId = getEmailId(allData?.valueAfter?.id);
        const name = allData?.valueAfter?.name;
        const siteName = allData?.valueAfter?.siteName;
        const role = allData?.valueAfter?.roleTitle;
        const studyNumber = allData?.valueAfter?.studyNumber;
        return (
            <>
                <div>Distribution Details:</div>
                <ul style={{ marginLeft: "21px" }}>
                    <li>Name: {name}</li>
                    <li>Email: {emailId}</li>
                    <li>Role: {role}</li>
                    <li>Site Name: {siteName}</li>
                    <li>Study Number: {studyNumber}</li>
                </ul>
            </>
        );
    }
    else if (actionType === "Report Updated") {
        return "Report Data Updated"
    }
    else if (actionType === "Report Created") {
        return "New Report Created via Manual XML Upload"
    }
    else if (actionType === "Distribution Imported") {
        return (
            <>
                <div>Distribution Imported using this file:</div>
                {allData?.valueAfter?.allAttachmentData.map((data: any) => {
                    return (
                        <div>
                            <Link key={data?.id} to={data?.url}>
                                {data?.name}.{data.type}
                            </Link>
                        </div>
                    )
                })}
            </>
        )

    }
    return "-";
}


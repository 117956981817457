import Styled from 'styled-components';

export const Container = Styled.div`
    width: 100%;
    padding: 1rem 1rem;
    padding-bottom: 3rem;
    display: flex;
    gap: 1rem;
    height: 95vh;
    overflow-y: scroll;

    .mainBody {
      width: 100%;
    }

    .ant-form-item{
        width: 100%;
        margin-bottom: 10px;

        .ant-form-item-row{
            display: block;

            .ant-col-12{
                text-align: start;
            }
        }
    }

    .headingContainer{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .formSection1 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 10px;
    }

    .templateCard{
        color: black;
        width: 100%;
        padding: 15px 20px;
        padding-bottom: 33px;
        background-color: white;
        position: relative;
        transition: 0.5s ease-in-out;

        .nameContainer{
            display: flex;
            align-items: center;
            gap: 5px;
            margin-bottom: 5px;

            .ant-tag{
                margin: 0;
            }
            .name{
                font-size: 1.2rem;
                font-weight: 500;
                margin-right: 5px;
            }
        }
        

        .description{
            word-wrap: break-word;
            hyphens: auto;
            white-space: normal
            overflow-wrap: break-word;
        }
    }

`;

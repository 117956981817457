import { loadState, saveState } from "../sessionstorage";
import { BaseAPI } from "./Common";

class Auth extends BaseAPI {
  refreshToken = () => {
    const session = loadState()?.session;
    let payload = {
      refreshToken: session?.refreshToken,
    };
    return this.makeRequest("refresh", "post", payload).then(
      async (response) => {
        if (response?.status === 401) {
          await this.logout();
          window.location.reload();
        }

        await saveState({
          userAccessToken: response.accessToken,
        });
      }
    );
  };

  getOTP(payload: any) {
    return this.makeRequest("generateOTP", "post", payload);
  }

  login(payload: any) {
    return this.makeRequest("login", "post", payload).then(async (response) => {
      if (response?.status > 300) {
        this.logoutMethods();
        window.location.href = "/";
      }

      await saveState({
        userData: response?.user || {},
      });

      return response;
    });
  }

  loginWithTenant(payload: any) {
    return this.makeRequest("loginWithTenant", "post", payload).then(
      async (response) => {
        if (response?.status > 300) {
          // auto logout if 401 response returned from api
          this.logoutMethods();
          window.location.href = "/";
          return;
        }
        sessionStorage.setItem("userAccessToken", response?.accessToken || "");

        return response;
      }
    );
  }
  getAccessTokenValidity() {
    return this.makeRequest("getAccessTokenValidity", "post", {}).then(
      async (res) => {
        if (res?.status > 300) {
          this.logoutMethods();
          window.location.href = "/";
        }
      }
    );
  }
  logoutMethods() {
    sessionStorage.removeItem("appState");
    sessionStorage.clear();
  }

  async logout() {
    return this.makeRequest("logout", "post", {})
      .then(async (response) => {
        this.logoutMethods();
      })
      .catch((error) => {
        this.logoutMethods();
      });
  }
  isAuthenticated() {
    const session = sessionStorage.getItem("appState");

    if (session) {
      const sessionData = JSON.parse(session)?.session;
      const user = sessionData?.userData;
      const userAccessToken = sessionData?.userAccessToken;
      const selectedTenantId = sessionData?.selectedTenant?.id;
      if (user && userAccessToken && selectedTenantId) {
        return user;
      }
    }
    return false;
  }

  getCurrentWorkspace() {
    const appState = sessionStorage.getItem("appState") ?? "{}";
    const data = JSON.parse(appState)?.session;
    if (data?.selectedTenant) {
      return data.selectedTenant;
    }
    return false;
  }

  getCurrentRole() {
    const appState = sessionStorage.getItem("appState") ?? "{}";
    const data = JSON.parse(appState)?.session?.roles;

    if (data) {
      return data;
    }
    return null;
  }

  getBackToLoginPage() {
    if (!this.isAuthenticated()) {
      window.location.href = "/";
    }
  }

  getUsersPrivileges() {
    const appState = sessionStorage.getItem("appState") ?? "{}";
    let userPermissions = JSON.parse(appState)?.session?.userPermissions?.map(
      (each: any) => each?.type
    );
    if (!userPermissions || !userPermissions.length) {
      const data = sessionStorage.getItem("userPermissions") ?? "{}";
      userPermissions = JSON?.parse(data)?.map((each: any) => each?.type) || [];
    }

    return userPermissions || [];
  }

  isPermissionExists(permissions: string[]) {
    return permissions.some((value) =>
      this.getUsersPrivileges()?.includes(value)
    );
  }
}

export const auth = new Auth("auth");

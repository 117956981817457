export const containerStyles: any = {
  border: "1px solid #d9d9d9",
  padding: "5px",
  borderRadius: "2px",
  backgroundColor: "white",
  maxHeight: "4rem",
  overflowY: "scroll",
  display: "flex",
  flexWrap: "wrap",
  rowGap: "4px",
  alignItems: "center",
  position: "relative"
};

export const closeButton: any = {
  position: "absolute",
  top: "8px",
  right: "8px",
  zIndex: "1",
  color: "#bfbfbf",
  cursor: "pointer"
}
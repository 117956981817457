import styled from "styled-components";

export const BoldText = styled.span`
  font-weight: 700;
`;
export const Details = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
`;
export const Detail = styled.div`
    h4{
        color: gray;
    }
    p{
        font-weight: 600;
    }
`;
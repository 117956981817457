import * as styles from "./AllNotifiactionsStyles";
import { useEffect, useState } from "react";
import { Space, Tag, Tooltip } from "antd";
import { Link } from "react-router-dom";
import ReTab from "../../unity-ui-common/Components/ReTab";
import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";
import { boolFilterItems } from "./Constant";
import { PathIndicator } from "../../Components";
import { IUniView } from "../../unity-ui-common/Components/ReTable/Interface";
import { convertDateInReadableFormat, textAndColor } from "../../Helper/Methods";
import { InfoCircleOutlined } from "@ant-design/icons";
import { auth } from "../../API/Auth";
import { API_URL } from "../../API/Config";

const sortingColumnKeys = [
  "irb_notified_on",
  "sentOn",
  "irb_notified_on",
  "site_number",
  "safetyDatabaseReportNumber",
  "reportID",
  "studyNumber",
  "is_acknowledged",
  "acknowledgedOn",
  "is_irb_notified",
];
const filterColumnKeys = [
  "safetyDatabaseReportNumber",
  // "sentOn",
  {
    key: "sentOn",
    action: (date: any) =>
      date ? convertDateInReadableFormat(date) : "-",
  },
  {
    key: "irb_notified_on",
    action: (date: any) =>
      date ? convertDateInReadableFormat(date) : "-",
  },
 
  "site_number",
  "studyNumber",
  {
    key: "is_acknowledged",
    items: [
      {
        text: "Yes",
        value: true,
      },
      {
        text: "No",
        value: false,
      },
    ],
  },
  "reportID",
  "acknowledgedOn",
  {
    key: "is_irb_notified",
    items: [
      {
        text: "Yes",
        value: true,
      },
      {
        text: "No",
        value: false,
      },
    ],
  },
];

function AllNotifiactions() {
  const [allNotificationsData, setAllNotificationsData] = useState([]);
  const [pendingActions, setPendingActions] = useState<Array<Object>>([]);
  const [activeTabId, setActiveTabId] = useState<string>("1");
  const [selectedAllNotiRowKeys, setSelectedAllNotiRowKeys] = useState<
    string[]
  >([]);
  const [selectedPendingActionRowKeys, setSelectedPendingActionRowKeys] =
    useState<string[]>([]);

  const columns = [
    {
      title: "Safety Document ID",
      dataIndex: "safetyDatabaseReportNumber",
      key: "safetyDatabaseReportNumber",
      render: (data: any) => {
        return data || "-";
      },
    },
    {
      title: "Notification Date",
      dataIndex: "sentOn",
      key: "sentOn",
      render: (date: any) => {
        return date ? date : "-";
      },
    },
    {
      title: "Day 0",
      dataIndex: "irb_notified_on",
      key: "irb_notified_on",
      width: 200,
      render: (irb_notified_on: string) => {
        return irb_notified_on ? convertDateInReadableFormat(irb_notified_on) : "-";
      },
    },
    {
      title: "Site Number",
      dataIndex: "site_number",
      key: "site_number",
      width: 200,
      render: (data: any) => {
        return data || "-";
      },
    },
    {
      title: "Study Number",
      dataIndex: "studyNumber",
      key: "studyNumber",
      render: (data: any) => {
        return data || "-";
      },
    },
    {
      title: "Acknowledged",
      dataIndex: "is_acknowledged",
      key: "is_acknowledged",
      render: (isAcknowledged: boolean) => {
        return (
          <Tag color={textAndColor[isAcknowledged ? 1 : 0].color}>
            {isAcknowledged ? "Yes" : "No"}
          </Tag>
        );
      },
    },
    
    {
      title: "Acknowledgement Date",
      dataIndex: "acknowledgedOn",
      key: "acknowledgedOn",
      render: (date: string) => {
        return date ? date : "-";
      },
    },
    {
      title: "EC/IRB Notified",
      dataIndex: "is_irb_notified",
      key: "is_irb_notified",
      render: (is_irb_notified: boolean, record: any) => {
        if (auth.getUsersPrivileges().includes("19")) {
          return (
            <Space>
              <Tag>N/A</Tag>
            </Space>
          );
        }
        let { reason, irb_notified_on } = record;
        const reasonText =
          irb_notified_on !== null && is_irb_notified === false ? reason : null;
        return (
          <Space>
            <Tag color={textAndColor[is_irb_notified ? 1 : 0].color}>
              {is_irb_notified ? "Yes" : "No"}
            </Tag>
            {reasonText !== null && (
              <Tooltip title={record?.reason}>
                <InfoCircleOutlined />
              </Tooltip>
            )}
          </Space>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "token",
      key: "action",
      render: (token: string) => {
        const text = activeTabId === "1" ? "Acknowledge/Notify" : "View";
        return <Link to={`/notification/${token}`}>{text}</Link>;
      },
    },
    {
      title: "USDE ID",
      dataIndex: "reportID",
      key: "reportID",
      render: (data: any) => {
        return data || "-";
      },
    },
    
   
    
    
    
    
  ];

  const filterColumnsForUser = (columns: any) => {
    //   SAFETY_TEAM_ADMIN, //+12
    //   SAFETY_TEAM,
    //   SPONSOR,
    //   PRINCIPLE_INVESTIGATOR 15,
    //   OTHER_SITE_PERSONNEL  16,
    //   INSTITUTIONAL_REVIEW_BOARD 17,
    //   REGULATORY_AUTHORITY 18,
    //   PARTNER 19,

    let newColumns = [];
    if (auth.isPermissionExists(["15", "16"])) {
      return columns;
    } else if (
      auth.isPermissionExists(["17"]) ||
      auth.isPermissionExists(["18"]) ||
      auth.isPermissionExists(["19"])
    ) {
      newColumns = columns.filter(
        (item: any) => item.key !== "is_irb_notified"
      );
    }
    return newColumns;
  };

  const getUniviewConfig = (
    forValue: "all" | "pending",
    isCsv: boolean
  ): IUniView => {
    let obj: IUniView = {
      uniView: "distributions",
      viewName: "notifications",
      apiUrl: API_URL,
      option: {
        orderBy: {
          // key: "updated_at",
          key:"sentOn",
          order: "DESC",
          
        },
        limit: isCsv ? 100 : 10,
      },
      filter: {},

      extra: {
        notificationType: "all",
      },
    };

    if (forValue === "pending") {
      obj.extra.notificationType = "pending";
    }

    return obj;
  };

  const table = (
    <ReTable
      
      refresh={activeTabId === "1" ? true : false}
      name={activeTabId === "1" ? "Pending Actions" : "All Notifications"}
      columns={filterColumnsForUser(columns)}
      scroll={{
        x: 2000,
        y: 800,
      }}
      uniView={
        activeTabId === "1"
          ? getUniviewConfig("pending", false)
          : getUniviewConfig("all", false)
      }
      rowSelection={{
        selectedRowKeys:
          activeTabId === "1"
            ? selectedPendingActionRowKeys
            : selectedAllNotiRowKeys,
        setSelectedRowKeys:
          activeTabId === "1"
            ? setSelectedPendingActionRowKeys
            : setSelectedAllNotiRowKeys,
        rowKey: "id",
        type: "checkbox",
      }}
      exportOption={{
        csv: {
          params: getUniviewConfig("all", true),
          // disabled:
          //   activeTabId === "1"
          //     ? !pendingActions.length
          //     : !allNotificationsData.length,
          columns: filterColumnsForUser(columns).map((each: any) => each.key),
        },
      }}
      columnOptions={{
        sorting: {
          columns: sortingColumnKeys,
          sortingDirections: ["ascend", "descend"],
        },
        filter: {
          columns: filterColumnKeys,
        },
      }}
    />
  );

  useEffect(() => {
    // fetchData();
    const id = sessionStorage.getItem("notificationsTabActiveId");
    if (id) {
      setActiveTabId(id);
    }
  }, []);

  return (
    <styles.Container className="subContainer">
      <PathIndicator
        items={[
          {
            title: "Notifications",
            url: "/notifications",
          },
        ]}
      />
      <ReTab
        onChange={(id: any) => {
          setActiveTabId(id);
          sessionStorage.setItem("notificationsTabActiveId", id);
        }}
        activeKey={activeTabId}
        items={[
          {
            title: "Pending Actions",
            key: "1",
            children: table,
          },
          {
            title: "All Notifications",
            key: "2",
            children: table,
          },
        ]}
      />
    </styles.Container>
  );
}

export default AllNotifiactions;

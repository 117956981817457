import styled from "styled-components";
import { Header, SideMenu } from "../../Components";
import { Empty } from "antd";

function UnAuthorized(props: { msg: string }) {
  const { msg } = props;
  return (
    <div className="container">
      <Header />
      <div className="page-wrapper">
        <SideMenu />
        <SubContainer className="subContainer">
          <Empty description={msg} />
        </SubContainer>
      </div>
    </div>
  );
}
export default UnAuthorized;

const SubContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

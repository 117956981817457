import { useEffect, useState } from "react";
import * as styles from "../ContactCompliance/ContactComplianceStyles";
import SendNotificationModal from "../SendNotificationModal/SendNotificationModal";
import { distributions } from "../../API/Distribution";
import { auth } from "../../API/Auth";
import { report as reportAPI } from "../../API/Report";
import ReminderTemplate from "../../Configs/Templates/ReminderTemplate";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import ReModal from "../../unity-ui-common/Components/ReModal";
import { Button, Empty, Space, Tag, Popconfirm, Tooltip } from "antd";
import {
  UploadOutlined,
  BellOutlined,
  CloseOutlined,
  PlusOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  colorFilterItems,
  convertDateInReadableFormat,
  getFilteredByColor,
  getTableColumnFilterItems,
  makeFirstLetterCapital,
  statusColorFilterItems,
  statusTextAndColor,
  textAndColor,
} from "../../Helper/Methods";
import ReTable from "../../unity-ui-common/Components/ReTable/ReTable";
import ImportContactListModal from "../ImportContactListModal/ImportContactListModal";
import { API_URL } from "../../API/Config";
import { DisplayMsg, LoadingSpinner } from "..";
import { IUniView } from "../../unity-ui-common/Components/ReTable/Interface";
import { IComponentProp } from "../../Interfaces/Component/SiteCompliance.interface";
import { IResponse } from "../../Interfaces/Apis/Common.api.interface";
import { distributionsStatusMapping } from "../../Helper/Mappings";

function SiteCompliance(props: IComponentProp) {
  const { safetyDocumentData, safetyDocumentID } = props;

  const [refreshReTable, setRefreshReTable] = useState<boolean>(false);
  const [importModal, setImportModal] = useState(false);
  const [notiModal, setNotiModal] = useState(false);
  const [isFileUploded, setIsFileUploded] = useState(false);
  const [selectedReportData, setSelectedReportData] = useState(null);
  const [isImported, setIsImported] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [additionalText, setAdditionalText] = useState("");
  const [templateType, setTemplateType] = useState("");
  const [simpleTableLoading, setSimpleTableLoading] = useState<boolean>(true);
  const [alldistributions, setAllDistributions] = useState<any[]>([]);
  const [
    isSendReminderIndividualModalOpen,
    setIsSendReminderIndividualModalOpen,
  ] = useState(false);
  const [sendReminderToSitesIds, setSendReminderToSitesIds] = useState<
    string[]
  >([]);

  const uniViewConfig: IUniView = {
    uniView: "distributions",
    viewName: "siteCompliance",
    apiUrl: API_URL,
    option: {
      orderBy: {
        key: "createdAt",
        order: "ASC",
      },
      limit: 10,
    },
    filter: {
      reportID: { "=": safetyDocumentID },
    },
    returnResponse: (res: IResponse) => {
      if (res?.status) {
        setTableData(res?.data);
      }
    },
  };

  async function handleSendReminderBtnClick(
    siteName: string,
    templateType: string
  ) {
    const ids = alldistributions
      .filter((record) => {
        return record.siteName === siteName;
      })
      .map((record) => {
        return record.id;
      });
    setSendReminderToSitesIds(ids);
    setTemplateType(templateType);
    setIsSendReminderIndividualModalOpen(true);
  }

  const handleSendReminderToIndividual = async () => {
    let res = await distributions.sendNotifications(
      sendReminderToSitesIds,
      additionalText,
      templateType,
      true
    );
    if (res && !res.notSent?.length) {
      ReNotification({
        description: "Notifications sent",
        duration: 1000,
        placement: "bottomLeft",
        type: "success",
      });
      // getTableData();
      setIsSendReminderIndividualModalOpen(false);
      setRefreshReTable(!refreshReTable);
    } else {
      ReNotification({
        description:
          "Notifications not sent for Emails - " + res.notSent.join(", "),
        duration: 1000,
        placement: "bottomLeft",
        type: "success",
      });
    }
  };

  async function getTableData() {
    if (!safetyDocumentID) return [];
    setSelectedReportData(null);

    // setSimpleTableLoading(true);
    if (Object.keys(safetyDocumentData).length) {
      const selectedReportData = await reportAPI.findOne(safetyDocumentID);
      setSelectedReportData(selectedReportData);

      // await distributions
      //   .getDistributionsBySites(safetyDocumentID)
      //   .then((data) => {
      //     setTableData(
      //       data
      //       // processData(data, {
      //       //   type: safetyDocumentData.type,
      //       //   lrd: safetyDocumentData.lrd,
      //       // })
      //     );
      //   });
    }
    // setSimpleTableLoading(false);
  }

  async function getAllDistribution(id: any) {
    let res = safetyDocumentID && (await distributions.findAll(id));
    if (res && !res?.statusCode) {
      setAllDistributions(res);
    }
  }

  // const processData = (data: any, reportData: any) => {
  // return data?.map((item: any) => {
  // const lrdDate = new Date(reportData.lrd);
  // const notifiedDate = new Date(item.sentOn);
  // const acknowledgedDate = new Date(item.acknowledgedOn);
  // const irbNotifiedDate = new Date(item.irbNotifiedOn);

  // const calenderDay = item.sentOn
  //   ? subtractDate(notifiedDate, lrdDate)
  //   : "-";
  // const complianceNotification = !item.sentOn
  //   ? "-"
  //   : calenderDay <= reportData.type
  //   ? "Yes"
  //   : "No";

  // const ackDay = item.acknowledgedOn
  //   ? subtractDate(acknowledgedDate, lrdDate)
  //   : -1;
  // const complianceAcknowledgement =
  //   ackDay == -1 ? "-" : ackDay <= reportData.type ? "Yes" : "No";

  // const irbNotifiedDay = item.irbNotifiedOn
  //   ? subtractDate(irbNotifiedDate, lrdDate)
  //   : -1;
  // const complianceIrbNotified =
  //   irbNotifiedDay == -1
  //     ? "-"
  //     : irbNotifiedDay <= reportData.type
  //     ? "Yes"
  //     : "No";

  // return {
  //   ...item,
  //   type: `${item.type}-day SUSAR`,
  //   calenderDay,
  //   ackDay,
  //   irbNotifiedDay,
  //   complianceNotification,
  //   complianceAcknowledgement,
  //   complianceIrbNotified,
  // };
  // });
  // };

  useEffect(() => {
    getTableData();
    getAllDistribution(safetyDocumentID);
    setRefreshReTable(!refreshReTable);
  }, [safetyDocumentData]);

  const columns = [
    {
      title: "Site Name",
      dataIndex: "siteName",
      key: "siteName",
      // width: 180,
      // sorter: (a: any, b: any) => a.siteName.localeCompare(b.siteName),
      // filters: getTableColumnFilterItems(tableData, "siteName"),
      // filterSearch: true,
      // onFilter: (value: any, record: any) => record.siteName.startsWith(value),
    },
    {
      title: "Site Number",
      dataIndex: "siteNumber",
      key: "siteNumber",
      // width: 220,
      // sorter: (a: any, b: any) => a.siteNumber.localeCompare(b.siteNumber),
      // filters: getTableColumnFilterItems(tableData, "siteNumber"),
      // filterSearch: true,
      // onFilter: (value: any, record: any) =>
      //   record.siteNumber.startsWith(value),
    },
    {
      title: "Study Number",
      dataIndex: "studyNumber",
      key: "studyNumber",
      // width: 220,
      // sorter: (a: any, b: any) => a.studyNumber.localeCompare(b.studyNumber),
      // filters: getTableColumnFilterItems(tableData, "studyNumber"),
      // filterSearch: true,
      // onFilter: (value: string, record: any) =>
      //   record.studyNumber.startsWith(value),
    },
    {
      title: "Report Delivery Status",
      dataIndex: "distributionStatus",
      key: "distributionStatus",
      // width: 200,
      // filters: statusColorFilterItems,
      // onFilter: (value: string, record: any) => {
      //   const { distributionStatus } = record;
      //   return (
      //     makeFirstLetterCapital(
      //       statusTextAndColor[distributionStatus].color
      //     ) === statusColorFilterItems[distributionStatus].text &&
      //     statusColorFilterItems[distributionStatus].value === value
      //   );
      // },
      render: (distributionStatus: string) => {
        return (
          <Tag
            color={statusTextAndColor[distributionStatus].color}
            key={distributionStatus}
          >
            {statusTextAndColor[distributionStatus].label}
          </Tag>
        );
      },
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      // width: 250,
      // sorter: (a: any, b: any) => a.dueDays - b.dueDays,
      // filters: getTableColumnFilterItems(tableData, "dueDate"),
      // filterSearch: true,
      // onFilter: (value: any, record: any) => {
      //   const { dueDate } = record;
      //   return convertDateInReadableFormat(dueDate).startsWith(value);
      // },
      render: (value: any, record: any) => {
        const text = record.dueDate
          ? `${convertDateInReadableFormat(record.dueDate)} (${record.dueDays})`
          : "-";
        return text;
      },
    },
    {
      title: "Date of Notification (CD)",
      dataIndex: "sentOn",
      key: "sentOn",
      // width: 250,
      // sorter: (a: any, b: any) => a.sentOn.localeCompare(b.sentOn),
      // filters: getTableColumnFilterItems(tableData, "sentOn"),
      // filterSearch: true,
      // onFilter: (value: any, record: any) => {
      //   const { sentOn } = record;
      //   return convertDateInReadableFormat(sentOn).startsWith(value);
      // },
      render: (sentOn: string, record: any) => {
        return (
          (sentOn &&
            convertDateInReadableFormat(sentOn) +
            " (" +
            record.calenderDay +
            ")") ||
          "-"
        );
      },
    },
    {
      title: "Notified within Due Days",
      dataIndex: "complianceNotification",
      key: "complianceNotification",
      // width: 240,
      // filters: colorFilterItems,
      // onFilter: (value: string, record: any) => {
      //   const colorName =
      //     record.complianceNotification === "Yes" ? "Green" : "Red";
      //   return getFilteredByColor(colorName, value);
      // },
      render: (complianceNotification: string) => {
        return complianceNotification !== "-" ? (
          <Tag
            color={
              complianceNotification && complianceNotification === "Yes"
                ? "green"
                : "orange"
            }
          >
            {complianceNotification}
          </Tag>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Acknowledgement Received",
      dataIndex: "isAcknowledged",
      key: "isAcknowledged",
      // width: 260,
      // filters: colorFilterItems,
      // onFilter: (value: string, record: any) => {
      //   const { isAcknowledged } = record;
      //   return getFilteredByColor(
      //     makeFirstLetterCapital(textAndColor[isAcknowledged ? 1 : 0].color),
      //     value
      //   );
      // },
      render: (r: any, data: any) => {
        let { isAcknowledged, siteName, pendingAck } = data;
        isAcknowledged = isAcknowledged ? 1 : 0;
        return auth.isPermissionExists(["13"]) ? (
          <Space>
            <Tag color={textAndColor[isAcknowledged].color}>
              {textAndColor[isAcknowledged].label}
            </Tag>
            {
              <Button
                size="small"
                type="default"
                disabled={!pendingAck}
                onClick={() => {
                  handleSendReminderBtnClick(siteName, "Acknowledgement");
                }}
              >
                Send Reminder
              </Button>
            }
          </Space>
        ) : (
          <Tag color={textAndColor[isAcknowledged].color}>
            {textAndColor[isAcknowledged].label}
          </Tag>
        );
      },
    },
    {
      title: "Date of Acknowledgement (CD)",
      dataIndex: "acknowledgedOn",
      key: "acknowledgedOn",
      // width: 300,
      // sorter: (a: any, b: any) =>
      //   a.acknowledgedOn.localeCompare(b.acknowledgedOn),
      // filters: getTableColumnFilterItems(tableData, "acknowledgedOn"),
      // filterSearch: true,
      // onFilter: (value: any, record: any) => {
      //   const { acknowledgedOn } = record;
      //   return convertDateInReadableFormat(acknowledgedOn).startsWith(value);
      // },
      render: (acknowledgedOn: string, record: any) => {
        return (
          (acknowledgedOn &&
            convertDateInReadableFormat(acknowledgedOn) +
            " (" +
            record.ackDay +
            ")") ||
          "-"
        );
      },
    },
    {
      title: "EC/IRB Notified",
      dataIndex: "isIrbNotified",
      key: "isIrbNotified",
      // width: 240,
      // filters: colorFilterItems,
      // onFilter: (value: string, record: any) => {
      //   const { isIrbNotified } = record;
      //   return getFilteredByColor(
      //     makeFirstLetterCapital(textAndColor[isIrbNotified ? 1 : 0].color),
      //     value
      //   );
      // },
      render: (isIrbNotified: boolean, data: any) => {
        let { siteName, pendingIrb, reason, irbNotifiedOn } = data;
        const isIrbNotifiedNewValue = isIrbNotified ? 1 : 0;
        const reasonText =
          irbNotifiedOn !== null && isIrbNotified === false ? reason : null;
        const tag = (
          <Tag color={textAndColor[isIrbNotifiedNewValue].color}>
            {textAndColor[isIrbNotifiedNewValue].label}
          </Tag>
        );
        return (
          <Space>
            {tag}
            {auth.isPermissionExists(["13"]) ? <Button
              size="small"
              type="default"
              disabled={!pendingIrb}
              onClick={() => {
                handleSendReminderBtnClick(siteName, "EC/IRB");
              }}
            >
              Send Reminder
            </Button> : <></>}
            {reasonText !== null && (
              <Tooltip title={data?.reason}>
                <InfoCircleOutlined />
              </Tooltip>
            )}
          </Space>
        )
      }
    },
    {
      title: "Date of EC/IRB Notification (CD)",
      dataIndex: "irbNotifiedOn",
      key: "irbNotifiedOn",
      // width: 270,
      // sorter: (a: any, b: any) =>
      //   a.irbNotifiedOn.localeCompare(b.irbNotifiedOn),
      // filters: getTableColumnFilterItems(tableData, "irbNotifiedOn"),
      // filterSearch: true,
      // onFilter: (value: any, record: any) => {
      //   const { irbNotifiedOn } = record;
      //   return convertDateInReadableFormat(irbNotifiedOn).startsWith(value);
      // },
      render: (irbNotifiedOn: string, record: any) => {
        return (
          (irbNotifiedOn &&
            convertDateInReadableFormat(irbNotifiedOn) +
            " (" +
            record.irbNotifiedDay +
            ")") ||
          "-"
        );
      },
    },
  ];

  return (
    <styles.Container>
      <styles.Body>
        <SendNotificationModal
          reportId={safetyDocumentID}
          visibility={notiModal}
          setVisibility={setNotiModal}
          flag={refreshReTable}
          setFlag={setRefreshReTable}
        />
        {/* {tableData.length !== 0 ? (
          <ReTable
            name="Site Compliance"
            columns={columns}
            data={tableData}
            // columnOptions={{
            //   sorting: {
            //     columns: [
            //       "siteName",
            //       "siteNumber",
            //       "studyNumber",
            //       "distributionStatus",
            //       "dueDate",
            //       "sentOn",
            //       "acknowledgedOn",
            //       "irbNotifiedOn"
            //     ],
            //     sortingDirections: ["ascend", "descend"],
            //   },
            //   filter: {
            //     columns: [
            //       "siteName",
            //       "siteNumber",
            //       "studyNumber",
            //       "distributionStatus",
            //       "dueDate",
            //       "sentOn",
            //       "complianceNotification",
            //       "isAcknowledged",
            //       "acknowledgedOn",
            //       "isIrbNotified",
            //       'irbNotifiedOn'
            //     ],
            //   },
            // }}

            exportOption={{
              csv: {
                disabled: !tableData.length,
                params: uniViewConfig,
              },
            }}
            uniView={uniViewConfig}
            scroll={{
              x: 2700,
              y: 800,
            }}
            // loading={simpleTableLoading}
            headerButtons={[
              {
                title: "Import",
                icon: <PlusOutlined />,
                onClick: () => {
                  setImportModal(!importModal);
                },
                visibility: auth.isPermissionExists(["0"]),
              },
              {
                title: "Send Notifications",
                icon: <BellOutlined />,
                onClick: () => {
                  setNotiModal(!notiModal);
                },
                visibility: auth.isPermissionExists(["0"]),
              },
            ]}
          />
        ) : (
          <>
            {!simpleTableLoading ? (
              auth.isPermissionExists(["13"]) ? (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  imageStyle={{
                    height: 60,
                  }}
                  description={<span>Import Contact List</span>}
                >
                  <Button
                    type="primary"
                    icon={<UploadOutlined />}
                    onClick={() => {
                      setImportModal(true);
                    }}
                  >
                    Import
                  </Button>
                </Empty>
              ) : (
                <DisplayMsg message="No site contacts imported" />
              )
            ) : (
              <LoadingSpinner />
            )}
          </>
        )} */}
        <ReTable
          name="Site Compliance"
          columns={columns}
          data={tableData}
          refresh={refreshReTable}
          columnOptions={{
            sorting: {
              columns: [
                "siteName",
                "siteNumber",
                "studyNumber",
                "distributionStatus",
                "dueDate",
                "sentOn",
                "acknowledgedOn",
                "irbNotifiedOn",
              ],
              sortingDirections: ["ascend", "descend"],
            },
            filter: {
              columns: [
                "siteName",
                "siteNumber",
                "studyNumber",
                // "distributionStatus",
                {
                  key: "distributionStatus",
                  items: distributionsStatusMapping,
                },
                {
                  key: "dueDate",
                  action: (date: any) =>
                    date ? convertDateInReadableFormat(date) : "-",
                },
                {
                  key: "sentOn",
                  action: (date: any) =>
                    date ? convertDateInReadableFormat(date) : "-",
                },
                {
                  key: "complianceNotification",
                  items: [
                    {
                      text: "Yes",
                      value: "Yes",
                    },
                    {
                      text: "No",
                      value: "No",
                    },
                  ],
                },

                {
                  key: "isAcknowledged",
                  items: [
                    {
                      text: "Yes",
                      value: true,
                    },
                    {
                      text: "No",
                      value: false,
                    },
                  ],
                },
                {
                  key: "acknowledgedOn",
                  action: (date: any) =>
                    date ? convertDateInReadableFormat(date) : "-",
                },
                {
                  key: "isIrbNotified",
                  items: [
                    {
                      text: "Yes",
                      value: true,
                    },
                    {
                      text: "No",
                      value: false,
                    },
                  ],
                },
                {
                  key: "irbNotifiedOn",
                  action: (date: any) =>
                    date ? convertDateInReadableFormat(date) : "-",
                },
              ],
            },
          }}
          exportOption={{
            csv: {
              disabled: !tableData?.length,
              params: uniViewConfig,
            },
          }}
          uniView={uniViewConfig}
          scroll={{
            x: 3000,
            y: 800,
          }}
          // loading={simpleTableLoading}
          headerButtons={[
            {
              title: "Import",
              icon: <PlusOutlined />,
              onClick: () => {
                setImportModal(!importModal);
              },
              visibility: auth.isPermissionExists(["0"]),
            },
            {
              title: "Send Notifications",
              icon: <BellOutlined />,
              onClick: () => {
                setNotiModal(!notiModal);
              },
              visibility: auth.isPermissionExists(["0"]),
            },
          ]}
        />

        <ImportContactListModal
          visibility={importModal}
          setVisibility={setImportModal}
          isImported={isImported}
          setIsImported={setIsImported}
          setIsFileUploded={setIsFileUploded}
          isFileUploded={isFileUploded}
          safetyDocumentID={safetyDocumentID}
          // getTableData={getTableData}
          flag={refreshReTable}
          setFlag={setRefreshReTable}
        />

        <ReModal
          closable={false}
          width={1000}
          title={
            <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <CloseOutlined
                onClick={() => {
                  setIsSendReminderIndividualModalOpen(false);
                }}
              />
              <h3
                style={{ marginBottom: "0" }}
              >{`Send Notification for ${safetyDocumentData?.safetyDatabaseReportNumber}`}</h3>
            </div>
          }
          visibility={isSendReminderIndividualModalOpen}
          onCancel={() => {
            setIsSendReminderIndividualModalOpen(false);
          }}
          footer={
            <div style={{ textAlign: "left" }}>
              <Popconfirm
                title={
                  <p style={{ margin: "0" }}>
                    Are you sure you want to send the reminder?
                  </p>
                }
                okText="Yes"
                cancelText="No"
                onConfirm={handleSendReminderToIndividual}
              >
                <Button size="large" type="primary">
                  Send
                </Button>
              </Popconfirm>
            </div>
          }
        >
          {templateType === "Acknowledgement" ? (
            <ReminderTemplate
              templateType="Acknowledgement"
              report={selectedReportData}
              additionalText={additionalText}
              onAdditionalTextChange={setAdditionalText}
            />
          ) : (
            <ReminderTemplate
              templateType="IRB/EC"
              report={selectedReportData}
              additionalText={additionalText}
              onAdditionalTextChange={setAdditionalText}
            />
          )}
        </ReModal>
      </styles.Body>
    </styles.Container>
  );
}

export default SiteCompliance;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { user } from "../../../API/UserManagement/User";
const getUsers = createAsyncThunk(
  "user/getAllUsers",
  async () => {
    try {
      const data = await user.getAll();
      return data;
    } catch (err) {
      console.log("error");
      ;
    }
  }
);

export { getUsers };

import styled from "styled-components";

export const Container = styled.div`
    .manual{
        border: 1px solid lightgrey;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        font-size: 1rem;
        margin-bottom: 10px;
        cursor: pointer;
        transition: 0.25s ease-in-out;
        padding: 10px;
        :hover{
            scale: 1.005;
            border-color: #DD2515;
            color: #DD2515;
        }
        svg{
            font-size: 1.1rem;
        }
    }
`;
import ReInput from "../../unity-ui-common/Components/ReFormFields/ReInput";
import ReModal from "../../unity-ui-common/Components/ReModal";
import ReusableForm from "../../unity-ui-common/Components/ReForm";
import LogoWithText from "../../Assets/Icons/LogoWithText.svg";
import { ReactComponent as SessionExpireIcon } from "../../Assets/Icons/SessionExpireIcon.svg";
import { Form, Button, Alert, Checkbox, Space } from "antd";
import { useEffect, useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import { auth } from "../../API/Auth";
import { Link, useNavigate } from "react-router-dom";
import { mask } from "../../Helper/Methods";
import { useLocation } from "react-router-dom";
import { PPAndTCModal } from "..";
import { ReNotification } from "../../unity-ui-common/Components/ReNotification";
import { handleSessionUpdate } from "../../slices/Session/Session";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { IType } from "../PPAndTCModal/Constant";
import { loadState, saveState } from "../../sessionstorage";
import * as styles from "./LoginFormStyles";
import ReOTP from "../../unity-ui-common/Components/ReOTP";

interface ITCAndPPModal {
  visibility: boolean;
  type: IType;
}

function Login(props: { noLogout?: boolean }) {
  const [form] = Form.useForm();
  const [isCodeSent, setIsCodeSent] = useState<boolean>();
  const handleKeyDown = (event: any) => {
      
    if (event.key === "Enter") {
      isCodeSent && form.submit();
    }
  };
  // useEffect(() => {
    

  //   document.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, [form]); // Empty dependency array ensures that this effect runs only once when the component mounts

  const sessionData = useAppSelector((state) => state.session);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const queryParams: any = new URLSearchParams(location.search);
  const initialTime = 15 * 60;

  const [email, setEmail] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [isUnauth, setIsUnauth] = useState<boolean>(false);
  const [redirectTo, setRedirectTo] = useState("");

  const [accessCode, setAccessCode] = useState<string>("");
  const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] =
    useState<boolean>(false);
  const [time, setTime] = useState<number>(initialTime);
  const [TCAndPPModalProps, setTCAndPPModalProps] = useState<ITCAndPPModal>({
    visibility: false,
    type: "PP",
  });
  
  useEffect(() => {
    if (props?.noLogout) {
      return;
    }
    if (sessionStorage.getItem("unauth")) {
      setIsUnauth(true);
    }
    sessionStorage.removeItem("unauth");

    auth.logout();
  }, []);

  useEffect(() => {
    isCodeSent && countDown();
  }, [isCodeSent]);

  useEffect(() => {
    email && form.setFieldValue("email", email);
  }, [email]);

  useEffect(() => {
    setEmail(queryParams.get("email") || "");
    setTenantId(queryParams.get("tenantId") || "");
    setRedirectTo(queryParams.get("redirectTo") || "");
  }, [location.search]);

  const handleLogin = async () => {
    if (!isPrivacyPolicyChecked) return;
    if (accessCode.length !== 6) {
      ReNotification({
        description: "Please fill all the fields",
        type: "error",
      });
      return;
    }
    const payload = {
      privacyPolicy: isPrivacyPolicyChecked,
      username: form.getFieldValue("email")?.trim()?.toLowerCase() || "",
      password: accessCode,
    };
    let res = await auth.login(payload);
    dispatch(
      handleSessionUpdate({
        userData: res?.user,
      })
    );

    if (res && res?.statusCode > 300) {
      ReNotification({
        description: "Access Code is Incorrect",
        type: "error",
      });
      return;
    }
    if (res) {
      if (redirectTo && tenantId) {
        const hasNoWorkspace: any = await handleWorkspaceSelect(tenantId);
        if (!hasNoWorkspace) {
          return;
        }
      }
      navigate(`/workspace`, { replace: true });
    } else if (res?.statusCode === 404) {
      ReNotification({
        description: "User not registered",
        type: "error",
      });
    } else {
      ReNotification({
        description: "Access Code is Incorrect",
        type: "error",
      });
    }
  };

  const handleSendOTP = async (values: any, type?: string) => {
    if (!isPrivacyPolicyChecked) return;
    if (type === "otpReset") {
      countDown("restart");
      ReNotification({
        description: "Access Code has been re-sent",
        type: "info",
      });
    }
    const email = values?.email?.trim().toLowerCase() || "";
    if (!email) return;
    setEmail(email);
    const payload = {
      email: email,
      privacyPolicy: isPrivacyPolicyChecked,
    };
    const res = await auth.getOTP(payload);
    if (res?.status) {
      setIsCodeSent(true);
    } else {
      ReNotification({
        description: res?.message || "Email ID Does Not Exist",
        type: "error",
      });
    }
  };
  const handleWorkspaceSelect = async (id: string) => {
    try {
      const data = await loadState();
      const userData = data?.session?.userData;
      const userId = userData.id;
      const chosenTenantId = id;
      const workspace = userData?.tenants?.find(
        (each: Record<string, any>) => each?.id === chosenTenantId
      );

      if (!workspace) return;
      const resp = await auth.loginWithTenant({
        user: userId,
        tenantId: chosenTenantId,
      });

      const permArr = resp.userPermissions.map((each: any) => each?.type);
      if (!permArr || permArr.length === 0)
        ReNotification({
          description: "There is no role attached to this user",
          type: "error",
        });

      await saveState({
        selectedTenantId: chosenTenantId,
        selectedTenant: workspace,
        userAccessToken: resp?.accessToken || "",
        userPermissions: resp?.userPermissions || [],
      });

      let endpoint;
      permArr.includes("5") || permArr.includes("6")
        ? (endpoint = "/notifications")
        : (endpoint = "/home");

      if (redirectTo) endpoint = redirectTo;
      navigate(endpoint, { replace: true });
    } catch (err) {
      console.log({ err });
      ReNotification({
        description: "There is no role attached to this user",
        type: "error",
      });
      return true;
    }
  };

  function countDown(action?: string) {
    if (action === "restart") setTime(initialTime);
    const timer = setInterval(() => {
      setTime((prevTime: number) => {
        if (prevTime === 0) {
          clearInterval(timer);
          return 0;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);
  }

  const handleFormSubmit = (values: any) => {
    if (isCodeSent) {
      handleLogin();
    } else {
      handleSendOTP(values);
    }
  };

  const handleRequestAccessCodeBtnHits = (e: any) => {
    e.preventDefault();
    form.submit();
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  const handleTCAndPPClicks = (type: "TC" | "PP") => {
    setTCAndPPModalProps((pre: ITCAndPPModal) => {
      return {
        ...pre,
        type: type,
        visibility: true,
      };
    });
  };

  return (
    <styles.Container>
      <div>
        <PPAndTCModal
          visibility={TCAndPPModalProps.visibility}
          setVisibility={setTCAndPPModalProps}
          type={TCAndPPModalProps.type}
        />
        <ReModal
          visibility={isUnauth}
          centered
          width={500}
          title="Session Timeout"
          onCancel={() => {
            setIsUnauth(false);
          }}
          footer={
            <Button
              size="middle"
              type="primary"
              onClick={() => {
                setIsUnauth(false);
              }}
            >
              Login
            </Button>
          }
        >
          <styles.ModalBody>
            <div className="icon">
              <SessionExpireIcon />
            </div>
            <div>
              <h3>You have been logged out.</h3>
              <p>You are logged out after 15 minutes of inactivity.</p>
              <p>Please login again.</p>
            </div>
          </styles.ModalBody>
        </ReModal>
        <img src={LogoWithText} alt="logo with text" />
        <ReusableForm
          formInstance={form}
          onSubmit={handleFormSubmit}
          className="loginForm"
          fieldsStyles={{
            display: "grid",
            gap: "1rem",
          }}
        >
          <h2>Login</h2>
          {!isCodeSent && (
            <>
              <h4>
                You will receive a Secure Access Code on your email address
              </h4>
              <ReInput
                noStyle
                label={""}
                placeholder="Enter your email address"
                name="email"
                type="email"
                prefix={<UserOutlined />}
                size="middle"
              />
              <Checkbox
                onChange={(e) => {
                  setIsPrivacyPolicyChecked(e.target.checked);
                }}
              >
                <span style={{ fontSize: "12px" }}>
                  By clicking Request Secure Access Code, you confirm that you
                  have read and are in agreement with the{" "}
                  {
                    <Link
                      to=""
                      onClick={() => {
                        handleTCAndPPClicks("TC");
                      }}
                    >
                      Terms & Conditions
                    </Link>
                  }{" "}
                  and{" "}
                  {
                    <Link
                      to=""
                      onClick={() => {
                        handleTCAndPPClicks("PP");
                      }}
                    >
                      Privacy Policy
                    </Link>
                  }
                  .
                </span>
              </Checkbox>
              <Button
                size="middle"
                type="primary"
                block
                htmlType="submit"
                autoFocus
                onClick={handleRequestAccessCodeBtnHits}
                disabled={!isPrivacyPolicyChecked}
              >
                Request Secure Access Code
              </Button>
            </>
          )}

          {isCodeSent && (
            <>
              <Alert
                message={`A Secure Access Code has been sent to ${mask(
                  form.getFieldValue("email")
                )}`}
                type="info"
                banner
              />
              <ReOTP
                onChange={(otpValue) => {
                  setAccessCode(otpValue);
                }}
              />
              <Button
                type="primary"
                size="middle"
                block
                htmlType="submit"
                onClick={handleRequestAccessCodeBtnHits}
                onKeyDown={handleKeyDown}
              >
                Login
              </Button>
              <div className="timer-container">
                {time ? (
                  <p style={{ color: "gray", margin: "0" }}>
                    Your Secure Access Code is valid for the next{" "}
                    <span style={{ fontWeight: "800" }}>
                      {formatTime(time)}
                    </span>
                  </p>
                ) : (
                  <Button
                    type="text"
                    size="small"
                    onClick={() => handleSendOTP({ email }, "otpReset")}
                    style={{ padding: "0" }}
                    disabled={time === 0 ? undefined : true}
                    className="resend-btn"
                  >
                    Resend Secure Access Code
                  </Button>
                )}
              </div>
            </>
          )}
        </ReusableForm>
      </div>
      <styles.ExtraInfo>
        <Space direction="vertical">
          <span>v3.0.0</span>
          <span>© Soterius All Rights Reserved</span>
        </Space>
      </styles.ExtraInfo>
    </styles.Container>
  );
}

export default Login;

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  width: 100%;
  h3 {
    margin: 0;
  }
`;
export const subContainers = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 2rem;
  .select {
    display: flex;
    flex-direction: column;
    .heading {
      padding: 0 11px;
    }
  }
  .box {
    span {
      color: gray;
    }
    p {
      margin: 0;
    }
  }
`;

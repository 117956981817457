import { createSlice } from "@reduxjs/toolkit";
import { getAllSponsors } from "./Helpers/GetAllSponsors";

interface SponsorState {
  allSponsors: [];
  loading: boolean;
  error: string;
}

const initialState: SponsorState = {
  allSponsors: [],
  loading: true,
  error: "",
};

const SponsorSlice = createSlice({
  name: "sponsor",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getAllSponsors.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllSponsors.fulfilled, (state, action) => {
      state.allSponsors = action.payload;
      state.loading = false;
    });
    builder.addCase(getAllSponsors.rejected, (state, action) => {
      state.error = "Something went wrong";
      state.loading = false;
    });
  },
});

export default SponsorSlice.reducer;

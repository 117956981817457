import { Select } from "antd";
import * as styles from "./ReportDetailHeaderStyles";

function CaseDetailHeader(props: {
  reportId: string;
  lrd: string;
  dueDate: string;
  allVersions: any;
  selectedReport: any;
  setSelectedReport: React.Dispatch<any>;
}) {
  const { reportId, lrd, dueDate } = props;
  return (
    <styles.Container>
      <h3>{reportId}</h3>
      <styles.subContainers>
        <div className="box select">
          <span className="heading">USDE ID</span>
          <Select
            value={props.selectedReport?.id}
            defaultValue="Select Version"
            bordered={false}
          >
            {props.allVersions.map((ele: any) => (
              <Select.Option value={ele.id} key={ele.id}>
                <h4
                  onClick={() =>
                    props.setSelectedReport(
                      props.allVersions.find((item: any) => item.id === ele.id)
                    )
                  }
                >
                  {ele.id}
                </h4>
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="box">
          <span>Day 0</span>
          <p>{lrd}</p>
        </div>
        <div className="box">
          <span>Notification Due Date</span>
          <p>{dueDate}</p>
        </div>
      </styles.subContainers>
    </styles.Container>
  );
}

export default CaseDetailHeader;

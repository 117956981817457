import { Collapse } from "antd";
import * as styles from "./FAQStyles";

const faqData = [
    {
        key: '1',
        heading: "What is UNITYdx?",
        content: `Safety Document Distribution System for seamless sharing of safety documents (e.g., 7/15-day
        SUSARs, DSURs, etc.) with Site Contacts, including Investigators, Study Coordinators,
        CRAs, and CRO personnel.`,
    },
    {
        key: '2',
        heading: "What does UNITYdx do?",
        content: `Facilitates efficient sharing of Safety Documents (e.g., 7/15-day SUSARs, DSURs, etc.) with
        Site Contacts, Tracks delivery, acknowledgments, and ensuring EC/IRB notification
        compliance. UNITYdx also sends timely reminders to Site Contacts for any missed
        acknowledgments or EC/IRB notifications.`,
    },
    {
        key: '3',
        heading: "How will the site/investigator receive safety documents via UNITYdx?",
        content: `Site Contacts will receive a Safety Document notification via email containing a link for
        accessing the document within the software. Clicking the link will direct users to the UNITYdx
        interface where they can view the Safety Document notification and its attachments, such as the
        MedWatch report and CIOMS.`,
    },
    {
        key: '4',
        heading: "How is data security ensured on UNITYdx?",
        content: `UNITYdx sends an email notification for a Safety Document, providing a secure link for users
        to access the information. No Safety Documents or attachments, including case information,
        are transmitted via email. To access the information, users need a Secure Access Code (SAC),
        which is received on their email ID.`,
    },
    {
        key: '5',
        heading: "How will the site/investigator access safety documents via UNITYdx?",
        content: `Site Contacts receive an email link for a Safety Document. Clicking it auto-populates their
        email in the login tab. After requesting a Secure Access Code, they can login to UNITYdx to
        access the document.`,
    },
    {
        key: '6',
        heading: "What if I do not receive the Secure Access Code in my inbox?",
        content: `Please check your junk/spam folder to see if you have received the email there.
        Please also add usde@soterius.net to your safe sender's list so that emails are directed to your
        inbox.`,
    },
    {
        key: '7',
        heading: `What activities will the Site/Investigator be required to perform with regards to Safety Documents
        received from a Sponsor?`,
        content: <>
        •	Review the Safety Document and associated attachments received via UNITYdx <br/>
        •	Acknowledge receipt of the Safety Document <br/>
        •	If required, notify their local EC/IRB about the Safety information received. <br/></>,
    },
    {
        key: '8',
        heading: "How will the site/investigator acknowledge safety documents via UNITYdx?",
        content: `After reviewing the Safety Document and attachments, the Investigator acknowledges by
        checking a box and clicking the 'I Acknowledge' button, completing the process and recording
        the date of acknowledgement.`,
    },
    {
        key: '9',
        heading: `Can the site/investigator notify their local EC/IRB about a safety documents using UNITYdx?
        How will it be done?`,
        content: <>Yes, Anyone with access to the software and the Safety Document link can click the 'Inform
        EC/IRB' button. This action provides three options: <br/><br/>
        •	I will inform have informed EC/IRB (non-software notification).<br/>
        •	I want to inform EC/IRB (software-based notification)<br/>
        •	I don't want to inform EC/IRB (requires providing a reason for not informing)</>,
    },
    {
        key: '10',
        heading: `What if my site has blocked delivery/read receipts of emails from being sent out to an external
        party? Will UNITYdx still be able to record acknowledgement?`,
        content: `UNITYdx does not track read and delivery receipts based on your email application and its
        settings. \n
        UNITYdx automatically marks the Safety Document as 'Delivered' when an Investigator opens
        the notification. After the Investigator acknowledges the document, it is marked as
        'Acknowledged' for all site contacts. If any contact notifies the EC/IRB and records it, the
        EC/IRB Notification compliance is marked as positive for all site contacts.`,
    },
    {
        key: '11',
        heading: "What if I have queries on how to use UNITYdx or face any technical issues?",
        content: `Visit the Support page to access user manuals through the Knowledge Repository link.
        Alternatively, raise a support ticket, and we will respond promptly.`,
    }
];

function FAQ() {
    const { Panel } = Collapse;

    return (
        <styles.Container className="manual-container">
            <Collapse defaultActiveKey={['1']}>
                {faqData?.map((faq)=>(<Panel header={faq.heading} key={faq.key}>
                    {faq.content}
                </Panel>))}
            </Collapse>
        </styles.Container>
    )
}

export default FAQ
import { IRole } from "../../Interfaces/Apis/Role.interface";
import { BaseAPI } from "../Common";

class Role extends BaseAPI {
  create(payload: IRole): Promise<any> {
    return this.makeRequest(`create`, "post", payload);
  }

  update(roleId: string, payload: IRole): Promise<any> {
    return this.makeRequest(`${roleId}`, "patch", payload);
  }

  getAll(): Promise<any> {
    return this.makeRequest(`all`);
  }

  getRole(roleId: string): Promise<any> {
    return this.makeRequest(`${roleId}`);
  }

  getPermissions(roleId: string): Promise<any> {
    return this.makeRequest(`permissions`);
  }
}

export const role = new Role("role");

import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../slices/User/UserSlices";
import sponsorReducer from "../slices/Sponsors/SponsorSlices";
import permissionsReducer from "../slices/Permissions/PermissionSlice";
import sideMenuReducer from "../slices/SideMenu/SideMenuSlices";
import auditLogs from "../slices/AuditLogs/AuditLogsSlices";
import session from "../slices/Session/Session";
import { loadState } from "../sessionstorage";

const persistedState = loadState();

export const store = configureStore({
  preloadedState: persistedState,
  reducer: {
    user: userReducer,
    sponsor: sponsorReducer,
    permissions: permissionsReducer,
    sideMenu: sideMenuReducer,
    auditLogs: auditLogs,
    session: session,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;




function DocEditor() {
  const collaboraUrl = "https://52.90.205.92:9980/browser/ca2ed20/cool.html"; // Collabora editor URL
  const wopiSrc = "https://unitydx-dev.soterius.xyz/docs-service/wopi/files/testk"; // WOPI source URL

  return (
    <>
      <iframe
        src={`${collaboraUrl}?WOPISrc=${encodeURIComponent(wopiSrc)}`}
        style={{ width: '100%', height: '100vh', border: 'none' }}
        title="Collabora Online Editor"
      />
    </>
  );
}

export default DocEditor;

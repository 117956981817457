import {
  SearchOutlined,
  ArrowRightOutlined,
  PlusOutlined,
  FileSearchOutlined,
  EditOutlined
} from "@ant-design/icons";
import { Button, Tag } from 'antd';
import Search from "antd/lib/input/Search";
import { Link } from "react-router-dom";
import * as Styles from "./TemplateManagementStyles";
import { useEffect, useState } from "react";
import { template } from "../../API/Template";
import ReSelect from "../../unity-ui-common/Components/ReFormFields/ReSelect";
import { modeTemplateOptions, triggerTemplateOptions, typeTemplateOptionsData } from "../ViewTemplate/constants";
import { useNavigate } from "react-router-dom";
import SafetyNarrative from "./Images/SafetyNarrative.png";
import PSUR from "./Images/PSUR.png";
import DSUR from "./Images/DSUR.png";
import CSR from "./Images/CSR.png";

function TemplateManagement() {

  const navigate = useNavigate();
  const [allTemplateData, setAllTemplateData] = useState<any>([]);
  const [viewTemplateData, setViewTemplateData] = useState<any>([]);
  const [onlineTemplateData, setOnlineTemplateData] = useState<any>([]);
  const [searchTerm, setsearchTerm] = useState('');
  const [typeTemplateOptions, setTypeTemplateOptions] = useState<any>([]);

  useEffect(() => {
    onPageLoad();
  }, [])

  async function onPageLoad() {
    // const allTemplateRes = await template.getAllTemplates();
    // setAllTemplateData(allTemplateRes);
    // setViewTemplateData(allTemplateRes?.configuredTemplate);
    // setOnlineTemplateData(allTemplateRes?.onlineTemplate);
    setViewTemplateData([
      {
        templateImg: CSR,
        name: "CSR Narrative",
      },
      {
        templateImg: PSUR,
        name: "PSUR",
      },
      {
        templateImg: DSUR,
        name: "DSUR",
      },
      {
        templateImg: SafetyNarrative,
        name: "SAE Narrative",
      },
      {
        templateImg: CSR,
        name: "CSR Narrative",
      },
      {
        templateImg: PSUR,
        name: "PSUR",
      },
      {
        templateImg: DSUR,
        name: "DSUR",
      },
      {
        templateImg: SafetyNarrative,
        name: "SAE Narrative",
      },
    ]);
  }
  const onSearch = (searchTerm: string) => {
    const value = searchTerm || "";
    const fieldsToSearch = ['body', 'description', 'mode', 'name', 'subject', 'trigger', 'type', 'createdAt', 'updatedAt'];
    const filterData: any = [];
    setsearchTerm(value);
    if (value === '') {
      setViewTemplateData(allTemplateData?.configuredTemplate)
      return;
    }
    allTemplateData?.configuredTemplate?.forEach((data: any) => {
      fieldsToSearch?.every((field: string) => {
        if (((data[field] || "").toLowerCase().trim()).includes(value.toLowerCase().trim())) {
          filterData.push(data);
          return false;
        }
        return true;
      });
    });
    allTemplateData?.onlineTemplate?.forEach((data: any) => {
      fieldsToSearch?.every((field: string) => {
        if (((data[field] || "").toLowerCase().trim()).includes(value.toLowerCase().trim())) {
          filterData.push(data);
          return false;
        }
        return true;
      });
    });
    setViewTemplateData(filterData);
  }

  const handleModeSelect = (value: any) => {
    setTypeTemplateOptions(typeTemplateOptionsData[value] || [])
    onSearch(value);
  }

  const handleTriggerSelect = (value: any) => {
    onSearch(value);
  }

  const handleTypeSelect = (value: any) => {
    onSearch(value);
  }
  const templateCategories = ["CSR Narrative", "PSUR", "DSUR", "SAE Narrative",];
  return (
    <Styles.Container>
      <div className="heading">
        <h2>Template Manager</h2>
      </div>
      <div className="searchBar">
        <Search
          placeholder="Search Templates"
          allowClear
          enterButton={<SearchOutlined />}
          size="large"
          onSearch={onSearch}
        />
      </div>
      {/* <div className="filter">
        <ReSelect
          name="mode"
          label=""
          placeholder="Template Category"
          items={modeTemplateOptions}
          onChange={handleModeSelect}
        />
        <ReSelect
          name="type"
          label=""
          placeholder="Template Name"
          items={typeTemplateOptions}
          onChange={handleTriggerSelect}
        />
        <ReSelect
          name="trigger"
          label=""
          placeholder="Template Prompt"
          items={triggerTemplateOptions}
          onChange={handleTypeSelect}
        />
      </div> */}
      <div className="headingSection">
        <h2>{searchTerm?.length > 0 ? "Search" : "Available Templates"}</h2>
        <div className="addNewBtn">
          <Button
            type='primary'
            onClick={() => { navigate('/template-management/new-template') }}
          >
            <PlusOutlined /> Add New
          </Button>
        </div>
      </div>
      {viewTemplateData?.length ?
        <div className="allNewTemplate">
          {templateCategories.map(category => (
            <div className="category" key={category}>
              <h3>{category}</h3>
              {viewTemplateData.filter((data: any) => data.name === category).map((data: any, index: number) => (
                <div className={`newTemplateDesign ${index > 0 ? 'blur' : ''}`} key={data.name}>
                  <img src={data.templateImg} alt={data.name} />
                  {/* <div className="name">{data.name}</div> */}
                </div>
              ))}
            </div>
          ))}
        </div> : <></>
      }
      {/* {viewTemplateData?.length ?
        <div className="allTemplate">
          {viewTemplateData?.map((data: any) => (
            <div className="templateContainer">
              <Link to={`/create-document/${data.id}`} key={data?.id}>
                <div className="templateCard">
                  {data?.status === 0 && <div className="inactive"></div>}
                  <div className="nameContainer">
                    <div className="name">{data?.name}</div>
                    <Tag>{data?.mode}</Tag>
                    <Tag>{data?.type}</Tag>
                    {data?.status === 0 && <Tag color="red">Inactive</Tag>}
                  </div>
                  <div className="description">{data?.description?.length > 180 ? (data?.description).substring(0, 180).trim() + "......" : data?.description}</div>
                  <div className="edit">Create Document <ArrowRightOutlined /></div>
                </div>
              </Link>
              <Link to={`/template-management/${data?.id}`} className="action"><EditOutlined /></Link>
            </div>
          ))}
        </div> :
        <div className="templatesNotFound">
          <FileSearchOutlined />
          <div className="text">{allTemplateData?.length > 0 && viewTemplateData?.length === 0 ? "Couldn't Find The Template" : "No Template Configured"}</div>
        </div>
      } */}
      {/* {
        searchTerm?.length === 0 && <>
          <div className="headingSection"><h2>Online Template</h2></div>
          {onlineTemplateData?.length ?
            <div className="allTemplate">
              {onlineTemplateData?.map((data: any) => (
                <div className="templateContainer">
                  <Link to={`/template-management/${data?.id}`} key={data?.id}>
                    <div className="templateCard">
                      {data?.status === 0 && <div className="inactive"></div>}
                      <div className="nameContainer">
                        <div className="name">{data?.name}</div>
                        <Tag>{data?.mode}</Tag>
                        <Tag>{data?.type}</Tag>
                        {data?.status === 0 && <Tag color="red">Inactive</Tag>}
                      </div>
                      <div className="description">{data?.description?.length > 180 ? (data?.description).substring(0, 180).trim() + "......" : data?.description}</div>
                      <div className="edit">Create Document <ArrowRightOutlined /></div>
                    </div>
                  </Link>
                  <Link to={`/template-management/new-template||${data?.id}`} className="action"><PlusOutlined /></Link>
                </div>
              ))}
            </div> :
            <div className="templatesNotFound">
              <FileSearchOutlined />
              <div className="text">{allTemplateData?.length > 0 && viewTemplateData?.length === 0 ? "Couldn't Find The Template" : "No Template Configured"}</div>
            </div>
          }
        </>
      }
      <Link to={`/template-management/new-template`}>
        <div className="addNewTemplateBtn">
          <div className="icon"><PlusOutlined /></div>
          <div className="text">Add New Template</div>
        </div>
      </Link> */}
    </Styles.Container>
  )
}

export default TemplateManagement;
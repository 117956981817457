import * as styles from "./DisplayMsgStyles";

function DisplayMsg(props: { message: string, width?: string }) {
  return (
    <styles.Container style={{width: props.width}}>
      <h3>{props.message}</h3>
    </styles.Container>
  );
}

export default DisplayMsg;

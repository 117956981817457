import { UserAddOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import * as Styles from "./PrivilegesManagementStyles";
import ManagePrivileges from "../../../Components/Management/ManagePrivileges/ManagePrivileges";
import ReTable from "../../../unity-ui-common/Components/ReTable/ReTable";
import { permission } from "../../../API/UserManagement/Permission";

function PrivilegesManagement() {
  const [tableData, setTableData] = useState<any[]>([]);

  const [permissionsDrawer, setPermissionsDrawer] = useState({
    visibility: false,
  });

  const PermissionsColumn: ColumnsType<[]> = [
    {
      title: "Name",
      dataIndex: "name",
    },
    { title: "Description", dataIndex: "description" },
  ];

  const getPrivileges = async () => {
    const res = await permission.getAll();
    setTableData(res);
  };

  useEffect(() => {
    getPrivileges();
  }, []);
  return (
    <Styles.Container className="subContainer">
      <ManagePrivileges
        visibility={permissionsDrawer.visibility}
        setVisibility={setPermissionsDrawer}
      />
      <ReTable
        columns={PermissionsColumn}
        data={tableData}
        name="Privileges"
        title="Privileges"
        headerButtons={[
          {
            title: "Add Previliges",
            icon: <UserAddOutlined />,
            onClick: () => {
              setPermissionsDrawer({
                visibility: true,
              });
            },
          },
        ]}
      />
    </Styles.Container>
  );
}

export default PrivilegesManagement;

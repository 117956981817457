import styled from "styled-components";

// this class is also used in sites and contact compliance page so change it carefully
export const GiantFilter = styled.div`
  .tab-container {
    padding: 1rem;
    padding-bottom: 0;
    background-color: #ffffff;
  }
  .form {
    padding: 1rem;
    background-color: #ffffff;
    .top-filter {
      display: flex;
      justify-content: space-between;
      align-items: start;
      .date-container {
        display: flex;
        column-gap: 1rem;
      }
    }
    .filterItems {
      display: flex;
      gap: 0.5rem;
      flex-wrap: wrap;
      .form-filter-component {
        width: 24%;
      }
      .ant-form-item {
        margin-bottom: 10px;
        .ant-col-12 {
          width: 100%;
          max-width: 100%;
        }
      }
    }
    .filterItems > * {
      min-width: 12rem;
    }
  }
`;

export const SeperateContainer = styled.div`
  .percentTag {
    width: 50px;
    text-align: center;
  }
`;

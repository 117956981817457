import Styled from 'styled-components';

export const Container = Styled.div`
    width: 100%;
    padding: 1rem 1rem;
    padding-bottom: 3rem;
    display: flex;
    gap: 1rem;
    height: 95vh;
    overflow-y: scroll;
    .commingSoon{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p{
            font-size: 2.5rem;
            font-weight: 500;
        }
    }
`;
import Styled from "styled-components";

export const Body = Styled.div`
    display: grid;
    gap: 1rem;
    .transAndAi{
        display: grid;
        grid-template-columns: 1fr 1fr; 
        margin-bottom: 1rem;
    }
    .qualityCheck{
        .checkBoxes{
            display: grid;
            gap: 1rem;
            .category{
                display: grid;
                grid-auto-flow: column;
                justify-content: space-between;
                align-items: center;
            }
        }
        p {
            margin: 0;
        }
    }
    .notification{
        .checkBoxes{
            display: grid;
            gap: 1rem;
            /* margin-top: 1rem; */
            .ant-checkbox-wrapper + .ant-checkbox-wrapper{
                margin: 0;
            }
            .category{
                input{
                    margin-top: 10px
                }
            }
        }
        .addSectionBtn{
            margin-top: 0.5rem;
        }
        .removeSectionBtn{
            margin-bottom: 0.5rem;
        }
    }
    .days{
        margin-bottom: 1rem;
        p{
            margin: 10px 0;
        }
        .workflow{
            margin-bottom: 0.5rem;
            span{
                margin-right: 5px;
                margin-left: 5px;
            }
        }
    }
`;
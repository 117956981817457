import { Tag } from "antd";
import { getPercentTagColor, statusTextAndColor, statusTextAndColorForWorkFlow } from "../../Helper/Methods";
import { remap } from "../../Helper/Util";
import { Link } from "react-router-dom";
import {UserOutlined, CommentOutlined } from "@ant-design/icons"

export const columns = [
  {
    title: "Task",
    dataIndex: "task",
    key: "task",
    width: 140,
    render: (task: string) => {
        return (<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            {task}
            <CommentOutlined style={{ paddingLeft: "5px" }} />
          </div>);
      },
    // render: (safetyDatabaseReportNumber: string, data: any) => {
    //   const { id } = data;
    //   return (
    //     <Link to={"/reports/view/" + id}>{safetyDatabaseReportNumber}</Link>
    //   );
    // },
  },
  {
    title: "Owner",
    dataIndex: "owner",
    key: "owner",
    width: 150,
    render: (owner: string) => {
      return <UserOutlined />;
    },
  },
  {
    title: "status",
    dataIndex: "status",
    key: "status",
    width: 150,
    render: (status: string) => {
        return (
          <Tag
            color={statusTextAndColorForWorkFlow[status].color}
            key={status}
          >
            {statusTextAndColorForWorkFlow[status].label}
          </Tag>
        );
      },
  },
  {
    title: "Due date",
    dataIndex: "duedate",
    key: "duedate",
    width: 150,
    render: (status: string) => {
        return status;
      },
  },
  {
    title: "Add",
    dataIndex: "add",
    key: "add",
    width: 200,
    render: (add: string) => {
      return add;
    },
  },
  
];


export const Draft = [
    {
     "id":1,
      "task": "Document 9",
      "owner": "Owner 1",
      "status": 1,
      "duedate": "2023-10-01",
      "add": ""
    },
    {
        "id":2,
      "task": "Document 8",
      "owner": "Owner 2",
      "status": 2,
      "duedate": "2023-09-15",
      "add": ""
    },
    {
      "id":3,
      "task": "Document 7",
      "owner": "Owner 3",
      "status": 0,
      "duedate": "2023-11-20",
      "add": ""
    }
  ]


 export const ToReview = [
    {
        "id":1,
      "task": "Document 2",
      "owner": "Owner 1",
      "status": 1,
      "duedate": "2023-10-01",
      "add": ""
    },
    {
        "id":2,
      "task": "Document 1",
      "owner": "Owner 2",
      "status": 2,
      "duedate": "2023-09-15",
      "add": ""
    },
    {
        "id":3,
      "task": "Document 6",
      "owner": "Owner 3",
      "status": 3,
      "duedate": "2023-11-20",
      "add": ""
    }
  ]


 export const Completed = [
    {
        "id":1,
      "task": "Document 3",
      "owner": "Owner 1",
      "status": 3,
      "duedate": "2023-10-01",
      "add": ""
    },
    {
        "id":2,
      "task": "Document 5",
      "owner": "Owner 2",
      "status": 2,
      "duedate": "2023-09-15",
      "add": ""
    },
    {
        "id":3,
      "task": "Document 4",
      "owner": "Owner 3",
      "status": 0,
      "duedate": "2023-11-20",
      "add": ""
    }
  ]

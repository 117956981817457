export const config = {
    toolbar: [
      "Heading",
      "bold",
      "italic",
      "bulletedList",
      "numberedList",
      "link",
      "insertTable",
    ],
  };

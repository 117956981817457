import type { Field, RuleType } from "react-querybuilder";
import { defaultOperators } from "react-querybuilder";
import { seriousCriteria, contactTypescausalty, contactTypes } from "./Items";

export const validator = (r: RuleType) => !!r.value;

export const caseRuleFields: Field[] = [
  {
    name: "investigatorCausality",
    label: "Reporter Causality",
    operators: defaultOperators.filter((op) => op.name === "="),
    values: contactTypescausalty,
    valueEditorType: "select",
    validator,
  },
  {
    name: "sponsorCausality",
    label: "Company Causality",
    operators: defaultOperators.filter((op) => op.name === "="),
    valueEditorType: "select",
    values: contactTypescausalty,
    validator,
  },
  {
    name: "country",
    label: "Source Country",
    operators: defaultOperators.filter((op) => op.name === "="),
    valueEditorType: "select",
  },
  {
    name: "seriousCriteria",
    label: "Seriousness Criteria",
    operators: defaultOperators.filter((op) => op.name === "="),
    values: seriousCriteria,
    valueEditorType: "select",
  },
];

export const contactCriteriaFields: Field[] = [
  {
    name: "role",
    label: "Contact Type",
    operators: defaultOperators.filter((op) => op.name === "="),
    values: contactTypes,
    valueEditorType: "select",
    validator,
  },
  {
    name: "country",
    label: "Contact Country",
    valueEditorType: "select",
    operators: defaultOperators.filter((op) => op.name === "="),
  },
];

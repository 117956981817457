import { QueryBuilder, formatQuery } from "react-querybuilder";
import * as Styles from "./RuleBuilderFormStyles";
import { Button, Space, Form } from "antd";
import { useEffect, useState } from "react";
import {
  caseRuleFields,
  contactCriteriaFields,
} from "../../Configs/RuleEngine/fields";
import ReusableForm from "../../unity-ui-common/Components/ReForm";
import ReInput from "../../unity-ui-common/Components/ReFormFields/ReInput";
import ReTab from "../../unity-ui-common/Components/ReTab";
import { replaceRoleAndCountryNames } from "../../Helper/Methods";
import { ruleEngine } from "../../API/RuleEngine";

function RuleBuilderForm(props: {
  type: "contactBuilder" | "ruleBuilder";
  ruleId?: string | undefined;
  onSubmit: Function;
  view?: boolean;
}) {
  const [form] = Form.useForm(); // form instance
  const { type, ruleId, onSubmit, view } = props;

  const [caseRuleQuery, setCaseRuleQuery] = useState<any>();
  const [contactCriteriaQuery, setContactCriteriaQuery] = useState<any>();
  const [caseRuleDes, setCaseRuleDes] = useState<any>();
  const [contactCriteriaDes, setContactCriteriaDes] = useState<any>();
  const [submitting, setSubmitting] = useState(false);
  const [activeTabValue, setActiveTabValue] = useState<string>(type === "contactBuilder" ? "1" : "0");
  const [initialValue, setInitialValue] = useState<any>({});
  const [allCountries, setAllCountries] = useState<any>([]);
  const [newCaseRuleFields, setNewCaseRuleFields] = useState<any>([]);
  const [allCountriesMapper, setAllCountriesMapper] = useState<any>([]);
  const [newContactCriteriaFields, setNewContactCriteriaFields] = useState<any>([]);

  const setFormValuesEmpty = () => {
    form.resetFields();
    setCaseRuleQuery(null);
    setCaseRuleDes("");
    setContactCriteriaQuery(null);
    setContactCriteriaDes("");
  };

  const handleFormSubmit = async (values: any) => {
    setSubmitting(true);
    values["caseRuleDes"] = caseRuleDes;
    values["caseRuleQuery"] = caseRuleQuery;
    values["contactCriteriaDes"] = contactCriteriaDes;
    values["contactCriteriaQuery"] = contactCriteriaQuery;
    await onSubmit(values);
    setFormValuesEmpty();
    setSubmitting(false);
  };

  const setFormInitialValues = async () => {
    if (ruleId) {
      const data: any = await ruleEngine.getRuleById(ruleId);
      if (data) {
        const {
          name,
          dueDays,
          query,
          contactRule,
          caseRuleDesc,
          contactRuleDesc,
        } = data;
        setInitialValue({
          name: name,
          dueDays: dueDays,
        });
        setCaseRuleQuery(query);
        setCaseRuleDes(caseRuleDesc);
        setContactCriteriaQuery(contactRule);
        setContactCriteriaDes(contactRuleDesc);
      }
    } else {
      setFormValuesEmpty();
    }
  };

  const getCountries = async () => {
    const res = await ruleEngine.getCountries();
    setAllCountries(res);
    const mapper: any = {};
    res?.forEach((country: any) => {
      mapper[country.alpha2] = country.name;
    });
    setAllCountriesMapper(mapper);
  };

  const setNewRuleBuilderFields = () => {
    setNewCaseRuleFields(
      caseRuleFields.map((fi) => {
        if (fi.name === "country") {
          return {
            ...fi,
            values: [
              {
                label: "Field Values",
                options: allCountries.map((cou: any) => {
                  return {
                    name: cou.alpha2,
                    label: cou.name,
                  };
                }),
              },
            ],
          };
        }
        return fi;
      })
    );

    setNewContactCriteriaFields(
      contactCriteriaFields.map((fi) => {
        if (fi.name === "country") {
          return {
            ...fi,
            values: [
              {
                label: "Field Values",
                options: allCountries.map((cou: any) => {
                  return {
                    name: cou.alpha2,
                    label: cou.name,
                  };
                }),
              },
            ],
          };
        }
        return fi;
      })
    );
  };

  useEffect(() => {
    setFormInitialValues();
  }, [ruleId]);

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    setNewRuleBuilderFields();
  }, [allCountries]);
  return (
    <Styles.Container>
      <div className="ruleForm">
        <h3 style={{ marginBottom: "1rem" }}>Rule Builder</h3>
        <ReusableForm
          disable={view ? true : false}
          formInstance={form}
          onSubmit={handleFormSubmit}
          formStyles={{ marginBottom: "0.5rem" }}
          initialFormValues={initialValue}
          resetFieldsAfterSubmit
        >
          <ReInput
            name="name"
            label="Rule Title"
            required
            type="simple"
            max={200}
          />
          <ReTab
            activeKey={activeTabValue}
            onChange={(id: string) => {
              setActiveTabValue(id);
            }}
            styles={{ padding: "0" }}
            items={[
              {
                title: "Case Criteria",
                key: "0",
                disable: type === "contactBuilder" ? true : false,
                children: (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: "1rem",
                    }}
                  >
                    <Styles.QueryBuilderContainer>
                      <QueryBuilder
                        disabled={view ? true : false}
                        controlClassnames={{
                          queryBuilder: "queryBuilder-branches",
                        }}
                        fields={newCaseRuleFields}
                        query={caseRuleQuery}
                        onQueryChange={(q: any) => {
                          setCaseRuleQuery(q);
                          setCaseRuleDes(replaceRoleAndCountryNames(formatQuery(q, "sql"), allCountriesMapper));
                        }}
                      />
                    </Styles.QueryBuilderContainer>
                    {/* <div>
                      <p>Boolean Query</p>
                      <p
                        style={{
                          backgroundColor: "#f5f5f5",
                          fontFamily: "monospace",
                          padding: "0.5rem",
                        }}
                      >
                        {caseRuleDes}
                      </p>
                    </div> */}
                  </div>
                ),
              },
              {
                title: "Contact Criteria",
                key: "1",
                children: (
                  <div className="contactCriteria">
                    <div style={{ marginBottom: "1.5rem" }}>
                      <p>Reporting Due Date</p>
                      <Space>
                        <span>Day 0 + </span>
                        <ReInput
                          min={0}
                          noStyle
                          name="dueDays"
                          label="Reporting Due Date"
                          type="number"
                        />
                        <span>CD</span>
                      </Space>
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "1rem",
                      }}
                    >
                      <Styles.QueryBuilderContainer>
                        <QueryBuilder
                          disabled={view ? true : false}
                          controlClassnames={{
                            queryBuilder: "queryBuilder-branches",
                          }}
                          fields={newContactCriteriaFields}
                          query={contactCriteriaQuery}
                          onQueryChange={(q) => {
                            setContactCriteriaQuery(q);
                            setContactCriteriaDes(replaceRoleAndCountryNames(formatQuery(q, "sql"), allCountriesMapper));
                          }}
                        />
                      </Styles.QueryBuilderContainer>
                      {/* <div>
                        <p>Boolean Query</p>
                        <p
                          style={{
                            backgroundColor: "#f5f5f5",
                            fontFamily: "monospace",
                            padding: "0.5rem",
                          }}
                        >
                          {contactCriteriaDes}
                        </p>
                      </div> */}
                    </div>
                  </div>
                ),
              },
            ]}
            tabBarExtraContent={
              <Space>
                {/* <Button disabled={canReplace}>Replace</Button> */}
                {!view && (
                  <Button htmlType="submit" type="primary" loading={submitting}>
                    {ruleId ? "Update" : "Submit"}
                  </Button>
                )}
              </Space>
            }
          />
        </ReusableForm>
      </div>
    </Styles.Container>
  );
}

export default RuleBuilderForm;

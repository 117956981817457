import { EditOutlined, UserAddOutlined } from "@ant-design/icons";
import { Tag, Tooltip } from "antd";
import { useState } from "react";
import { UserCreationForm } from "../../Drawers/UserCreationForm";
import * as Styles from "./ManageUsersStyles";
import { API_URL } from "../../../API/Config";
import ReTable from "../../../unity-ui-common/Components/ReTable/ReTable";
import { user } from "../../../API/UserManagement/User";

function ManageUser() {
  const [selectedTableRow, setSelectedTableRow] = useState<string[]>([]);
  const [currEditingUser, setCurrEditingUser] = useState({});
  const [userDrawerVisibility, setUserDrawerVisibility] =
    useState<boolean>(false);
  const [userDrawerType, setUserDrawerType] = useState<string>("");

  const handleEditUser = async (id: string) => {
    const userData = await user.getUser(id);
    setCurrEditingUser(userData);
    setUserDrawerType("edit");
    setUserDrawerVisibility(true);
  };

  const columns = [
    {
      title: "",
      width: 50,
      render: (record: any) => {
        return (
          <>
            {selectedTableRow.length !== 0 ? (
              ""
            ) : (
              <Tooltip title="Edit">
                <EditOutlined
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleEditUser(record.id);
                  }}
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email ID",
      dataIndex: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (record: any) => {
        const text = record === 1 ? "Active" : "Inactive";
        const color = record === 1 ? "green" : "red";
        return (
          <Tag color={color} title="status">
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
    },
    {
      title: "Sponsor/MAH with roles",
      dataIndex: "roleMatrices",
      render: (roleMatrices: any) => {
        return (
          <div>
            {roleMatrices?.map((mt: any) => {
              return <p style={{margin: 0}}>{`${mt?.tenant}: ${mt?.roles.join(", ")}`}</p>;
            })}
          </div>
        );
      },
    },
  ];

  return (
    <Styles.Container>
      <UserCreationForm
        drawerType={userDrawerType}
        visibility={userDrawerVisibility}
        setUserDrawerType={setUserDrawerType}
        setUserDrawerVisibility={setUserDrawerVisibility}
        currEditingUser={currEditingUser}
      />
      <ReTable
        name="All Users"
        title="All Users"
        columns={columns}
        refresh={userDrawerVisibility}
        scroll={{
          x: 800,
          y: 500,
        }}
        rowSelection={{
          selectedRowKeys: selectedTableRow,
          type: "checkbox",
          setSelectedRowKeys: setSelectedTableRow,
          rowKey: "id",
        }}
        uniView={{
          uniView: "user",
          viewName: "allUsers",
          apiUrl: API_URL,
          option: {
            orderBy: {
              key: "name",
              order: "ASC",
            },
            limit: 10,
          },
        }}
        exportOption={{
          csv: {},
        }}
        headerButtons={[
          {
            title: "Add New",
            icon: <UserAddOutlined />,
            onClick: () => {
              setUserDrawerType("create");
              setUserDrawerVisibility(true);
            },
          },
        ]}
      />
    </Styles.Container>
  );
}

export default ManageUser;

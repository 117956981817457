import styled from "styled-components";

// export const Container = styled.div`
//   .percentTag {
//     width: 50px;
//     text-align: center;
//   }
// `;
export const Container = styled.div`
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 95vh;
  overflow-y: scroll;
  padding-bottom: 3rem;

  .top-container {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr 2fr;
    gap: 1rem;

    @media only screen and (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
  }
`;

export const ReportIdicatorSmallCardItems = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
`;
export const ReportIdicatorBigCardItems = styled.div`
  background-color: #ffffff;
  display: grid;
  gap: 10px;
  text-align: center;
  align-content: center;
  align-items: flex-start;
  grid-template-columns: repeat(4, 1fr);
  padding: 1rem;
  div > h1 {
    font-size: 50px;
    margin-bottom: 0;
  }
  div {
    display: flex;
    flex-direction: column;
  }
`;
export const NotiStatusGraph = styled.div`
  background-color: #ffffff;
  padding: 1rem;
  display: grid;
`;

export const DashboardBlock = styled.div`
  background-color: white;
  padding: 10px;
`;

export const DashboardBlockHeading = styled.div`
  font-size: 16px;
`;

export const DashboardBlockStat = styled.div`
  margin-top: 20px;
  font-weight: bold;
  font-size: 48px;
`;

import styled from "styled-components";

export const Container = styled.div``;
export const Workflow = styled.div`
            margin-bottom: 0.5rem;
            span{
                margin-right: 5px;
                margin-left: 5px;
            }
`;


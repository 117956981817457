import { BaseAPI } from "./Common";

class Dashboard extends BaseAPI {
  getElementData(): Promise<any> {
    return this.makeRequest("element", "get");
  }

  getCROData(payload: Record<string, any>): Promise<any> {
    return this.makeRequest("data", "post", payload);
  }

  getCROStats(): Promise<any> {
    return this.makeRequest("stats", "get");
  }
}

export const dashboard = new Dashboard("dashboard");
